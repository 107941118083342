import {
  SET_REFERRALS_LIST,
  UPDATE_REFERALS_LIST,
} from "../action/setReferralsList";

export default function ReferralsReducer(state = "", action) {
  switch (action.type) {
    case SET_REFERRALS_LIST:
      const data = action.payload;
      state = data;
      return { ...state };

    case UPDATE_REFERALS_LIST:
      const referrals = action.payload;
      state.referrals = referrals;
      return { ...state };
    default:
      return state;
  }
}
