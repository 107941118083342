// src/Countdown.js
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";

const Countdown = () => {
  const estimateDays = useFeatureFlag("Event_count_down_days");
  const dateStr = estimateDays;
  const [day, month, year] = dateStr.split("/");
  const endDate = new Date(`${year}-${month}-${day}`); // 20 days from now
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = endDate - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box textAlign="center" color="white">
      <Typography variant="h3" component="h2" gutterBottom>
          Free usage ends in
      </Typography>
      <Box display="flex" justifyContent="center" fontSize="h3.fontSize">
        <Box mx={2}>
          <Typography variant="h3" component="span">
            {String(timeLeft.days).padStart(2, "0")}
          </Typography>
          <Typography variant="subtitle1" component="div">
            Days
          </Typography>
        </Box>
        <Box>:</Box>
        <Box mx={2}>
          <Typography variant="h3" component="span">
            {String(timeLeft.hours).padStart(2, "0")}
          </Typography>
          <Typography variant="subtitle1" component="div">
            Hours
          </Typography>
        </Box>
        <Box>:</Box>

        <Box mx={2}>
          <Typography variant="h3" component="span">
            {String(timeLeft.minutes).padStart(2, "0")}
          </Typography>
          <Typography variant="subtitle1" component="div">
            Minutes
          </Typography>
        </Box>
        <Box>:</Box>

        <Box mx={2}>
          <Typography variant="h3" component="span">
            {String(timeLeft.seconds).padStart(2, "0")}
          </Typography>
          <Typography variant="subtitle1" component="div">
            Seconds
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Countdown;
