import React, { useEffect, useState, useRef } from "react";
import { ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  ThemeProvider,
  TextField,
  FormLabel,
  Stack,
  LinearProgress,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Alert,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { createTheme, createFilterOptions, Autocomplete } from "@mui/material";
import { KeyboardArrowRight, RocketLaunch, Whatshot } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useForm, FormProvider, Controller, useFormContext } from "react-hook-form";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import LoadingButton from "@mui/lab/LoadingButton";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import PopularityChart from "../components/techRelevence/PopularityChart";
import IndustrialUsageChart from "../components/techRelevence/IndustrialUsageChart";
import EaseOfLearningChart from "../components/techRelevence/EaseOfLearningChart";
import PotentialSalaryIncraeseChart from "../components/techRelevence/PotentialSalaryIncraeseChart";
import SouthIcon from "@mui/icons-material/South";

function getFirstWord(inputString) {
  const words = inputString.split(" ");
  if (words.length > 0) {
    return words[0];
  } else {
    return ""; // Return an empty string if there are no words in the input
  }
}

function ScrollDown() {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
  });
}

function TechRelevanceV2() {
  const theme = useTheme();

  const relevenceTheme = createTheme({
    typography: {
      // fontFamily: [" Montserrat", "sans-serif"].join(","),
      fontFamily: `"Montserrat", 'sans-serif'`,
    },
    palette: {
      primary: {
        main: "#000088",
      },
      // secondary: {
      //   main: green[500],
      // },
    },
  });
  const [openTooltip, setOpenToolTip] = React.useState({});
  const methods = useForm({ criteriaMode: "all" });
  const [UserName, setUserName] = useState("");
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [relevanceResult, setRelevanceResult] = useState("");
  const [formdata, setFormdata] = useState("");
  const [chatGptButton, setChatGptButton] = useState(false);
  const [checkSkill, setCheckSkill] = useState("");
  let initialCount = 1;
  const [chatGPTData, setChatGPTData] = useState("");
  const [chatgptError, setChatgptError] = useState(false);
  const staticRandomEncodeDecodeString = useFeatureFlag("staticRandomEncodeDecodeString");
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const poweredByChatGPT = useFeatureFlag("Chat_GPT_Boost");
  const pageTitle = "Professionals Tech Innovation Software India | TrueSelfy Tech Relevance";
  const pageDescription =
    "Unlock the Future with TrueSelfy's Tech Innovation in India! Explore Top Tech Software for Professionals. Dive into Tech Relevance Today!";
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const textFieldRef = useRef(null);

  function checkSkillType(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForCurrentSkill = 1;
    const minYearForOutdated = 4;
    if (year > currentYear) {
      return `Skill trending and in demand until ${year}`;
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return `Skill current and in demand until ${year}`;
    } else if (year >= currentYear - minYearForOutdated) {
      return `Skill getting outdated fast ${year}`;
    } else if (year <= currentYear - minYearDiff) {
      return `Skill obselete ${year}`;
    }
  }

  // const openai = new OpenAI({
  //   apiKey: "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", // defaults to process.env["OPENAI_API_KEY"]
  //   dangerouslyAllowBrowser: true,
  // });

  const messages = [
    "Hello, how can I assist you?",
    "I can provide information and answer questions.",
    "Just type your query, and I will do my best to help!",
  ];

  function checkYear(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (year > currentYear) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-serif",
            // fontSize: "20px",
            color: "#232323",

            textAlign: "center",
          }}
          mb={{ xs: 2, md: 2, lg: 3, xl: 3 }}
          variant="h6"
        >
          <span
            style={{
              // fontSize: "20px",
              fontWeight: 700,
              color: "#26801E",
            }}
          >
            Trending{" "}
          </span>{" "}
          : You are in demand, but keep upgrading
        </Typography>
      );
      return {
        label: `Technical skill trending and in demand until ${year}`,
        barPrimaryColor: "green",
        barSecondaryColor: "#A5D6A7",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearForCurrentSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontSize: "20px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "darkorange",
            }}
          >
            • Current
          </span>{" "}
          <spna>: Your tech is current, but it is getting outdated fast</spna>
        </Typography>
      );
      return {
        label: `Skill in demand until ${year}`,
        barPrimaryColor: "darkorange",
        barSecondaryColor: "#ffcc80",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontSize: "20px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "#ffc107",
            }}
          >
            • Getting outdated
          </span>{" "}
          <spna>: Upskill soon to stay ahead in the tech industry</spna>
        </Typography>
      );
      return {
        label: `Technical skill getting outdated fast ${year}`,
        barPrimaryColor: "#ffc107",
        barSecondaryColor: "#fff59d",
        labelSuggestion,
      };
    } else if (year <= currentYear - minYearDiff) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontSize: "24px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "red",
            }}
          >
            • Obsolete
          </span>{" "}
          : Please learn new age skills now
        </Typography>
      );
      return {
        label: `Technical skill obselete ${year}`,
        barPrimaryColor: "red",
        barSecondaryColor: "#FFCDD2",
        labelSuggestion,
      };
    }
  }

  function getTopSkill(skillArray) {
    let maxExpiryYear = -Infinity;
    let maxExpiryYearObject = null;

    // Iterate through the array
    for (const obj of skillArray) {
      if (obj.expiryYear > maxExpiryYear) {
        maxExpiryYear = obj.expiryYear;
        maxExpiryYearObject = obj;
      }
    }
    const topSkillSuggestion = checkYear(maxExpiryYearObject?.expiryYear);

    return {
      maxExpiryYear,
      labelSuggestion: topSkillSuggestion?.labelSuggestion,
      maxExpiryYearObject,
    };
  }

  // Data Encryption function
  function DataEncryption(json) {
    const jsonString = JSON.stringify(json);
    const customStringJson = secretEncodeDecodeString + jsonString + secretEncodeDecodeString;
    const newCustomStringJson = customStringJson.replace(/"/g, staticRandomEncodeDecodeString);
    const encodedString = btoa(newCustomStringJson);
    return encodedString;
  }
  function DataDecryption(jsonString) {
    const decodedString = atob(jsonString);
    const getOldStringOne = decodedString.replace(new RegExp(secretEncodeDecodeString, "g"), "");
    const str = staticRandomEncodeDecodeString;
    const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedString, "g");
    const getOldStringTwo = getOldStringOne.replace(regex, '"');
    const json = JSON.parse(getOldStringTwo);
    return json;
  }

  // tech relevance handler
  const onSubmit = (data) => {
    setLoadingButton(true);
    setUserName(data?.name);
    setFormdata(data);
    setCheckSkill("");
    // console.log("formdata", data);
    try {
      axios
        .post(process.env.REACT_APP_API_BASE_URL + "/get-relevanceResult", DataEncryption(data), {
          options: {
            type: "json",
          },
        })
        .then((object) => {
          const data = DataDecryption(object.data);
          // console.log("Data", data);
          setRelevanceResult(data);
          // console.log(data, "data");
          setLoadingButton(false);
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
          });
          // methods.reset("");
          // setValue("skills", "");
        })
        .catch((error) => {
          if (
            error?.response?.status === 500 &&
            error?.response?.data?.message === "Email verification failed"
          ) {
            methods.setError("email", {
              type: "validation",
              message: "Email not valid !!",
            });
            setRelevanceResult("");
          }

          setLoadingButton(false);
        });
    } catch (error) {
      // console.log(error);
      setLoadingButton(false);
    }
  };

  // chatgpt response handler
  const handleSendClick = async (name) => {
    setChatgptError(false);
    setCheckSkill(name);
    const skill = getTopSkill(relevanceResult?.topPrimarySkill);
    const content = ` make a response for ${name} in jSON format and  how popular is it now (assign it a populairty rating on scale of 1 to 10 based on worldwide usage) in the world?,  how many percentage uses in current industry, What latest / futuristic technologies are for ${name}
    in  composite value of easy, medium or hard of ease of learning and descending order of payscales?
    Make it in a json format with popularity rating scale of 1-10 and potential increase in salary in % terms ,make sure all content in json format and remove explanation and make sure object have percentageUsage, popularityRating, latestTechnologies key for object and latestTechnologies object have name, easeOfLearning,potentialSalaryIncreasePercentage keys
    `;
    try {
      setChatGptButton(true);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/get-chatgpt-response",
          DataEncryption({ skillName: name }),
          {
            options: {
              type: "json",
            },
          }
        )
        .then((object) => {
          setChatGptButton(false);
          const data = DataDecryption(object.data);
          let gptdata = JSON.parse(data?.result);
          ScrollDown();

          // console.log(gptdata, "gptdata1");
          if (
            gptdata.length === 0 ||
            gptdata === null ||
            gptdata === undefined ||
            Object.keys(gptdata).length === 0
          ) {
            setChatgptError(true);
            setChatGPTData("");
            // console.log(gptdata, "gptdata");
            toast.error("Something went wrong try again");
          } else {
            // setChatGPTData(gptdata);
            setChatGPTData(gptdata);
            // console.log(gptdata, "gptdata");
          }
        })
        .catch((error) => {
          setChatgptError(true);
          setChatGptButton(false);
          toast.error("Something went wrong try again");
        });
    } catch (error) {
      setChatgptError(true);
      setChatGptButton(false);
      toast.error("Something went wrong try again");
    }
  };

  const RetryGPT = (name) => {
    if (initialCount <= 3) {
      initialCount += 1;
      handleSendClick(name);
    } else {
      return;
    }
  };

  // scroll down hook
  useEffect(() => {
    if (chatGptButton === true) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
    if (relevanceResult?.length !== 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatGptButton, relevanceResult]);

  // Tech Relevance form
  function TechRelevanceForm() {
    const {
      control,
      setValue,
      getValues,
      formState: { errors },
    } = useFormContext();
    const [open, setOpen] = useState(false); // if dropdown open?
    const [autoValue, setAutoValue] = useState(getValues("primarySkill") || []); // if dropdown open?
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noOptions, setNoOptions] = useState(false);

    const filter = createFilterOptions({
      stringify: (option) => option.name,
    });
    const isMobile = useMediaQuery("(max-width:600px)");

    // useEffect(() => {
    //   setError("email", {
    //     type: "validation",
    //     message: "Email not valid !!",
    //   });
    // }, [setEmailError]);

    useEffect(() => {
      setValue("primarySkill", autoValue);
    }, [autoValue]);
    return (
      <React.Fragment>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={4}>
            <FormLabel
              sx={{
                fontFamily: "Zen Dots, cursive",
                fontSize: "24px",
                lineHeight: "42px",
                color: "#262655",
              }}
            >
              Name
            </FormLabel>
            <Controller
              control={control}
              name={"name"}
              // sx={{
              //   "& .Mui-focused": {
              //     color: "#0391E8 !important",
              //   },
              // }}

              render={({ field }) => (
                <TextField
                  id="name"
                  {...field}
                  fullWidth
                  required
                  label={"Enter your Name"}
                  variant="outlined"
                  inputRef={textFieldRef}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel
              sx={{
                fontFamily: "Zen Dots, cursive",
                fontSize: "24px",
                lineHeight: "42px",
                color: "#262655",
              }}
            >
              Email
            </FormLabel>
            <Controller
              control={control}
              name={"email"}
              rules={{
                required: "Email Address is required",
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message:
                    "Oh no, that doesn't look like a valid email! Please double-check and try again.",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors?.email ? true : false}
                  id="email"
                  label={"Enter your Email"}
                  fullWidth
                  required
                  type="email"
                  helperText={
                    <>
                      {errors?.email && errors?.email.message}
                      {errors?.email && errors.type === "validation" ? errors?.email?.message : ""}
                    </>
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel
              sx={{
                fontFamily: "Zen Dots, cursive",
                fontSize: "24px",
                lineHeight: "42px",
                color: "#262655",
              }}
            >
              Skills
            </FormLabel>
            <Controller
              name={"primarySkill"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  noOptionsText={noOptions ? "No options found" : "Start typing to get options"}
                  {...field}
                  open={open}
                  onOpen={() => {
                    setNoOptions(false);
                    setOpen(true);
                    setData([]);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  loading={loading}
                  multiple
                  autoComplete={true}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  value={autoValue}
                  onChange={(event, newValue) => {
                    if (newValue.slice(-1)[0] && newValue.slice(-1)[0].inputValue != undefined) {
                      setAutoValue([
                        ...autoValue,
                        {
                          name: newValue.slice(-1)[0].inputValue,
                          other: true,
                        },
                      ]);
                    } else {
                      setAutoValue(newValue);
                    }
                    setData([]);
                  }}
                  filterSelectedOptions={true}
                  onInputChange={(event, value) => {
                    setNoOptions(true);
                    // setLoading(true);
                    const trimmedValue = value?.trim();
                    if (trimmedValue) {
                      const encodedSkillName = Array.from(trimmedValue)
                        .map((char) => {
                          const isSpecialSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(char);
                          return isSpecialSymbol ? encodeURIComponent(char) : char;
                        })
                        .join("");
                      const endpoint = `/get-relevanceSkill?skills=${encodedSkillName}`;
                      // console.log("data", data);
                      getSkillOptions(setData, endpoint, setLoading);
                    } else {
                      setLoading(false);
                    }
                  }}
                  id="free-solo-dialog-demo"
                  options={data}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    params.inputValue = params.inputValue.trim();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.name, inputValue.trim());
                    const parts = parse(option.name, matches);

                    return (
                      <li {...props} key={option.name}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                color: part.highlight ? "red" : "inherit",
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // autoFocus={question?.autoFocus}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={errors?.primarySkill ? true : false}
                      helperText={<>{errors?.primarySkill && "Skills cannot be empty"}</>}
                      label={"Select multiple options"}
                    />
                  )}

                  // sx={{
                  //   "& .MuiOutlinedInput-root": {
                  //     borderRadius: "50px !important",
                  //   },
                  // }}
                  // />
                />
              )}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  // get top skill details handler
  var cancelToken = null;
  function getSkillOptions(setData, endpoint, setLoading) {
    setData([]);
    setLoading(true);
    if (cancelToken) {
      // console.log("cancelToken", cancelToken);
      cancelToken.cancel("Previous request cancelled");
    }
    // console.log("");
    try {
      cancelToken = axios.CancelToken.source();
      axios
        .get(process.env.REACT_APP_API_BASE_URL + endpoint, {
          cancelToken: cancelToken.token,
        })
        .then((object) => {
          if (object?.status === 201) {
            var data = _.remove(DataDecryption(object.data), function (n) {
              return n.name !== "Other";
            });
            setData(data);
          } else {
            setData([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // Handle other errors here
            console.error("Error:", error);
          }
        });
    } catch (error) {
      // Handle any unexpected errors here
      console.error("Error:", error);
      setLoading(false);
    }
  }

  const handleTooltipClose = (key) => {
    // setOpenToolTip((prevState) => ({ ...prevState, [key]: false }));
    setOpenToolTip({});
  };

  const handleTooltipOpen = (key) => {
    if (openTooltip[key] === true) {
      setOpenToolTip((prevState) => ({ ...prevState, [key]: false }));
    } else {
      setOpenToolTip({});
      setOpenToolTip((prevState) => ({ ...prevState, [key]: true }));
    }
  };

  // const CustomTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: "#D6EFFF",
  //     color: "rgba(0, 0, 0, 0.87)",
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: "1px solid #dadde9",
  //   },
  // }));

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(`
      // border: 1px solid #008;
      // color: black;
      // background-color: #D6EFFF;
      // background-color: #FFF;
      // border-radius: 22px;
      // padding:"11px 34px !important";
      // filter: drop-shadow(0px 0px 12px rgba(0, 0, 136, 0.55));
  `);

  // custom tooltip component
  const CustomTooltipTitle = (data, index) => (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        {" "}
        <IconButton
          onClick={() => {
            handleTooltipClose(index);
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            maxWidth: 164,
            minWidth: 120,
            width: "100%",
            flex: 1,
          }}
        >
          <Typography
            color="primary"
            textAlign="center"
            sx={{
              fontFamily: "Zen Dots, cursive",
              fontSize: { xs: "14px", md: "16px" },
              mb: "20px",
              textAlign: "center",
            }}
          >
            Ease of learning
          </Typography>
          {/* <Typography
            color="primary"
            sx={{
              fontFamily: "Zen Dots, cursive",
              textAlign: "center",
              fontSize: "24px",
              // mb: "48px",
              // mt: "41px",
            }}
          >
            {data?.data?.easeOfLearning}/10
          </Typography> */}
          {/* <EaseOfLearningChart value={data?.data?.easeOfLearning} /> */}
          <Box>
            <Typography
              color="primary"
              textAlign="center"
              sx={{
                fontFamily: "Zen Dots, cursive",
                fontSize: { xs: "24px", md: "24px" },
                textAlign: "center",
                pt: "25px",
              }}
              pr={{ xs: 1, md: 0, lg: 0, xl: 0 }}
            >
              {data?.data?.easeOfLearning}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            flex: 0.1,
            borderColor: "#008",
          }}
        />
        <Box sx={{ p: "0px 0px", flex: 1 }}>
          <Typography
            color="primary"
            textAlign="center"
            sx={{
              fontFamily: "Zen Dots, cursive",
              fontSize: { xs: "14px", md: "16px" },

              // mb: "15px",
              textAlign: "center",
            }}
          >
            Potential Salary Increase
          </Typography>
          {/* <Typography
            color="primary"
            sx={{
              fontFamily: "Zen Dots, cursive",
              textAlign: "center",
              fontSize: "24px",
              // mb: "48px",
            }}
          >
            {data?.data?.potentialSalaryIncreasePercentage}%
          </Typography> */}
          <PotentialSalaryIncraeseChart value={data?.data?.potentialSalaryIncreasePercentage} />
        </Box>
      </Box>
    </React.Fragment>
  );

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <ThemeProvider theme={relevenceTheme}>
      <React.Fragment>
        {/* <Helmet>
          <title>{pageTitle}</title>
          <meta property="og:title" content={pageTitle} data-react-helmet="true" />
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content="https://techworth.trueselfy.com/og-img.png" />
        </Helmet>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={{
            xs: "center",
            sm: "center",
            md: "center",
            lg: "left",
          }}
        >
          {/* Header Section */}
        <Grid item>
          <Box p={3}>
            <a href="/">
              <img
                src={"../../assets/images/TrueSelfy-logo.svg"}
                alt="true-selfy"
                style={{
                  maxWidth: `${isMobile ? "80px" : ""}`,
                }}
              />
            </a>
          </Box>
        </Grid>
        <Grid
          container
          alignItems={"flex-start"}
          p={{
            xs: "15px",
            sm: "30px 50px",
            md: "30px 50px",
            lg: "30px 70px 30px 70px",
          }}
          spacing={{ xs: 4, md: 5, lg: 5, xl: 7 }}
          justifyContent={"center"}
        >
          {/* Techrelevance first Section */}
          <Grid item xs={12}>
            <Box
              component="div"
              sx={{
                backgroundImage: {
                  xs: `url('/assets/images/tech-relevance-backimg.png')`,
                  md: `url('/assets/images/tech-relevance-backimg.png')`,
                },
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                p: { xs: "20px", md: "46px 75px" },
                borderRadius: { xs: "20px", md: "66px" },
                // color: "red",
                overflow: "hidden",
              }}
            >
              <Grid>
                <Typography
                  sx={{
                    fontFamily: "Zen Dots, cursive",
                    fontSize: { xs: "24px", md: "52px" },
                    lineHeight: { xs: "40px", md: "62px" },
                    color: "#FFC218",
                    mb: "19px",
                    textAlign: { xs: "center", md: "center" },
                    // wordBreak: "break-word",
                  }}
                  variant="h1"
                >
                  Check your TechRelevance
                </Typography>
              </Grid>
              <Grid container spacing={2} display={"flex"} alignItems={"center"}>
                <Grid item xs={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: { xs: "24px", md: "38px", lg: "38px", xl: "52px" },
                      fontWeight: 600,
                      // lineHeight: "75px",
                      wordBreak: "break-word",
                      color: "#fff",
                      paddingBottom: isMobile ? "10px" : "0px",
                      textAlign: isMobile ? "center" : "left",
                    }}
                  >
                    Craft Your Career Journey {poweredByChatGPT && "with AI!"}
                  </Typography>
                  <Box py={2} display={"flex"} justifyContent={{ xs: "center", md: "left" }}>
                    <Button
                      variant="contained"
                      sx={{
                        minWidth: { xs: "150px", md: "150px", lg: "150px", xl: "218px" },
                        borderRadius: "27px",
                        background: `#FFF`,
                        color: "#008",
                        fontSize: isMobile ? "16px" : "28px",
                        backgroundColor: "#FFF !important",
                        textTransform: "capitalize",
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        window.scrollTo({
                          top: document.documentElement.scrollHeight,
                          behavior: "smooth",
                        });
                        textFieldRef.current.focus();
                      }}
                    >
                      Start Now
                    </Button>
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: 400,
                      lineHeight: "30px",
                      wordBreak: "break-word",
                      color: "#fff",
                      // color: { xs: "#000", md: "#fff" },
                      mb: "10px",
                    }}
                  >
                    Technology's significance lies in its problem-solving, life-enhancing, and
                    efficiency-boosting capabilities. It constantly evolves to meet changing needs,
                    but also faces obsolescence as newer, more efficient solutions emerge.
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: 400,
                      lineHeight: "30px",
                      wordBreak: "break-word",
                      color: "#fff",
                      // color: { xs: "#000", md: "#fff" },
                      mb: "10px",
                    }}
                  >
                    Innovations can quickly make once-vital technologies outdated, emphasizing the
                    importance of staying ahead. In this rapidly changing landscape, adaptability
                    becomes key.
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: 600,
                      lineHeight: "30px",
                      wordBreak: "break-word",
                      color: "#fff",
                      // color: { xs: "#000", md: "#fff" },
                      mb: "10px",
                    }}
                  >
                    Discover how long your skills will remain relevant in this evolving tech world.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    alignItems: "normal",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  order={{ xs: 1, sm: 1, md: 2 }}
                >
                  {/* <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: { xs: "20px", md: "28px" },
                      fontWeight: 600,
                      lineHeight: "30px",
                      wordBreak: "break-word",
                      color: "#FFC218",
                      textAlign: "center",
                    }}
                  >
                    Plan your career based on the recommendations{" "}
                    {poweredByChatGPT && "(powered by chatGPT)."}
                  </Typography> */}
                  <Box
                    component="div"
                    sx={{
                      maxWidth: 600,
                      width: "100%",
                      height: "auto",
                      m: "0px auto",
                    }}
                  >
                    <img
                      src="/assets/images/tech-relevance-sideimg.svg"
                      alt="tech-relevence-img"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* User Details Section */}
          <Grid item xs={12}>
            <Typography
              color={"primary"}
              sx={{
                fontFamily: "Zen Dots, cursive",
                fontSize: { xs: "30px", md: "45px" },
                textAlign: "center",
                // lineHeight: { xs: "40px", md: "62px" },
                // color: "#fff",
                mb: `${isMobile ? "18px" : "37px"}`,
                wordBreak: "break-word",
              }}
            >
              Enter Your Details
            </Typography>
            <React.Fragment>
              <FormProvider {...methods}>
                <form id="tech-relevance" onSubmit={methods.handleSubmit(onSubmit)}>
                  <TechRelevanceForm />
                  <Grid>
                    <Box pt={4}>
                      {loadingButton ? (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              my: 5,
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        </Grid>
                      ) : (
                        <Button
                          type="submit"
                          form="tech-relevance"
                          // variant="contained"
                          sx={{
                            maxWidth: "333px",
                            width: "100%",
                            display: "block",
                            m: "0px auto",
                            color: "#FFF",
                            P: "12px",
                            fontSize: "24px",
                            fontWeight: 600,
                            borderRadius: "55px",
                            textTransform: "capitalize",
                            // border: "1px solid transparent",
                            background:
                              "linear-gradient(103deg, #009EF7 18.51%, #661BB0 67.8%, #100C3D 115.08%)",
                            transition: "all 0.3s linear",
                            "&:hover": {
                              // color: "#008 !important",
                              background:
                                "linear-gradient(103deg, #661BB0  18.51%,#009EF7   67.8%,#100C3D  115.08%)",
                              // background: "rgba(19,133,234,0.15)",
                              // border: "1px solid #008",
                              transition: "all 0.3s linear",
                            },
                          }}
                          loading={loadingButton}
                        >
                          {" "}
                          Check TechRelevance
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </form>
              </FormProvider>
            </React.Fragment>
          </Grid>
          {/* Tech relevance Section */}
          {relevanceResult?.length !== 0 && relevanceResult !== undefined && !loadingButton ? (
            <>
              <Grid item xs={12}>
                <Typography
                  color={"primary"}
                  sx={{
                    fontFamily: "Zen Dots, cursive",
                    fontSize: { xs: "30px", md: "45px" },
                    textAlign: "center",
                    // lineHeight: { xs: "40px", md: "62px" },
                    // color: "#fff",
                    mb: "37px",
                    wordBreak: "break-word",
                  }}
                >
                  {UserName.length !== 0 ? `${getFirstWord(UserName)}'s TechRelevance` : ""}
                </Typography>
                <Grid container spacing={{ xs: 2, md: 5 }} rowSpacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="div"
                      // px={{ xs: 3, md: 10, lg: 10 }}
                      // py={{ xs: 3, md: 10, lg: 10 }}
                      sx={{
                        p: { xs: "25px", md: "60px" },
                        border: "1px solid #008",
                        borderRadius: "39px",
                        boxShadow: "0px 0px 8px 0px #D9D9FF",
                        height: "100%",
                        boxSizing: "inherit",
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: "270px",
                          width: "100%",
                          height: "auto",
                          m: "0px auto",
                          mb: "36px",
                        }}
                      >
                        <img
                          src="/assets/images/clock.png"
                          style={{ width: "100%", height: "100%" }}
                          alt="clock"
                        />
                      </Box>
                      <Typography
                        color="primary"
                        sx={{
                          fontFamily: "Zen Dots, cursive",
                          fontSize: "32px",
                          textAlign: "center",
                        }}
                      >
                        Technical validity until
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Zen Dots, cursive",
                          fontSize: "65px",
                          textAlign: "center",
                          color: "262655",
                        }}
                      >
                        {getTopSkill(relevanceResult?.topPrimarySkill).maxExpiryYear}
                      </Typography>
                      {/* 
                      <Typography
                        sx={{
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: "24px",
                          color: "#232323",
                          mb: "47px",
                          textAlign: "center",
                        }}
                      > */}{" "}
                      {getTopSkill(relevanceResult?.topPrimarySkill)?.labelSuggestion}
                      {/* </Typography> */}
                      <Button
                        sx={{
                          // maxWidth: "333px",
                          width: isMobile ? "100%" : "60%",
                          display: "block",
                          m: "0px auto",
                          color: "#FFF",
                          // P: "12px",
                          fontSize: isMobile ? "16px" : "24px",
                          fontWeight: 600,
                          borderRadius: "55px",
                          textAlign: "center",
                          textTransform: "capitalize",
                          background: "#26801E",
                          backgroundColor: "#26801E !important",
                        }}
                        component={"a"}
                        href={"https://techworth.trueselfy.com/"}
                      >
                        {CalculateButtonValue}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="div"
                      // px={{ xs: 3, md: 8, lg: 8 }}
                      // py={{ xs: 3, md: 10, lg: 10 }}
                      sx={{
                        // p: { xs: "25px", md: "43px 62px" },
                        p: { xs: "25px", md: "60px" },
                        border: "1px solid #008",
                        borderRadius: "39px",
                        boxShadow: "0px 0px 8px 0px #D9D9FF",
                        height: "100%",
                        boxSizing: "inherit",
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems={"center"}
                        sx={{ mb: isMobile ? "5px" : "0px" }}
                        justifyContent={"space-between"}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Box
                            sx={{
                              mr: "20px",
                              width: { xs: "46px", md: "58px" },
                              height: "100%",
                            }}
                          >
                            <img
                              src="/assets/images/skill-score-relevence.svg"
                              width={"100%"}
                              height="100%"
                              alt="skill-score"
                            />
                          </Box>

                          <Typography
                            sx={{
                              fontFamily: "Zen Dots, cursive",
                              fontSize: { xs: "24px", md: "32px" },
                              letterSpacing: "0.5px",
                            }}
                          >
                            Skill score
                          </Typography>
                        </Box>
                      </Stack>

                      {!isMobile && (
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Zen Dots, cursive",
                              background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                              backgroundClip: "text",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              fontSize: "14px",
                              fontWeight: 600,
                              textAlign: "left",
                              mb: "25px",
                              mt: "20px",
                            }}
                          >
                            Click 'AI Boost' for skill info from ChatGPT.
                          </Typography>
                        </Box>
                      )}
                      {isMobile && (
                        <>
                          <Typography
                            sx={{
                              fontFamily: "Zen Dots, cursive",
                              background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                              backgroundClip: "text",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "center",
                              padding: "10px",
                            }}
                          >
                            Click 'AI Boost' for skill info from ChatGPT.
                            {/* Plan your career <br />
                            with AI boost */}
                          </Typography>
                        </>
                      )}

                      {relevanceResult?.topPrimarySkill?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 5,
                            alignItems: { xs: "center", md: "flex-end" },
                            justifyContent: "space-between",
                            mb: "30px",
                          }}
                          flexDirection={{
                            xs: "column",
                            md: "column",
                            sm: "column",
                            lg: "row",
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography
                              sx={{
                                fontFamily: "Zen Dots, cursive",
                                fontSize: "22px",
                                display: "block",
                                mb: "6px",
                              }}
                            >
                              {item?.name}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography sx={{ fontSize: "18px", color: "#262655" }}>
                                {item.type === "y"
                                  ? checkYear(item?.expiryYear).label
                                  : checkSkillType(item?.expiryYear)}
                              </Typography>
                              <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
                                {item?.score <= 0 ? "20" : Math.round(item?.score)}%
                              </Typography>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress
                                sx={{
                                  height: "10px",
                                  borderRadius: "20px",
                                  "& .MuiLinearProgress-bar": {
                                    backgroundColor: checkYear(item?.expiryYear).barPrimaryColor,
                                  },
                                  backgroundColor: checkYear(item?.expiryYear).barSecondaryColor,
                                }}
                                color="error"
                                variant="determinate"
                                value={item?.score <= 0 ? 20 : Math.round(item?.score)}
                              />
                            </Box>
                          </Box>
                          {poweredByChatGPT && (
                            <Button
                              component="div"
                              sx={{
                                maxWidth: "129px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "7px 12px",
                                borderRadius: "27px",
                                background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                              }}
                              onClick={() => handleSendClick(item?.name)}
                            >
                              <RocketLaunch sx={{ color: "#FFF", fontSize: "23px" }} />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  color: "#FFF",
                                  fontWeight: 600,
                                  textTransform: "capitalize",
                                }}
                              >
                                AI Boost
                              </Typography>
                            </Button>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box textAlign={"left"} pt={0.5}>
                      <Alert severity="info" sx={{ background: "#fff" }}>
                        Recommendations and potential salary increase percentages provided are for
                        informational purposes only and should be used as suggestions, not
                        guarantees
                      </Alert>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* AI Boost powered Section */}
              {checkSkill !== "" && poweredByChatGPT && !chatgptError ? (
                <Grid item xs={12}>
                  <Typography
                    color={"primary"}
                    sx={{
                      fontFamily: "Zen Dots, cursive",
                      fontSize: { xs: "30px", md: "45px" },
                      textAlign: "center",
                      // lineHeight: { xs: "40px", md: "62px" },
                      // color: "#fff",
                      mb: "52px",
                      wordBreak: "break-word",
                    }}
                  >
                    AI Boost powered by ChatGPT
                  </Typography>
                  <Box
                    component="div"
                    sx={{
                      backgroundImage: {
                        xs: `url('/assets/images/tech-relevence-chatgpt-bg.png')`,
                        md: `url('/assets/images/tech-relevence-chatgpt-bg.png')`,
                      },
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      p: { xs: "20px", md: "46px 75px" },
                      borderRadius: { xs: "20px", md: "66px" },
                      // color: "red",
                      overflow: "hidden",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", mb: "43px" }}>
                      <Box
                        sx={{
                          mr: "20px",
                          width: { xs: "46px", md: "58px" },
                          height: "100%",
                        }}
                      >
                        <img
                          src="/assets/images/bulb-img.png"
                          width="100%"
                          height="100%"
                          alt={"bulb-img"}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontFamily: "Zen Dots, cursive",
                          fontSize: { xs: "24px", md: "32px" },
                          color: "#FFF",
                        }}
                      >
                        {" "}
                        {checkSkill}
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} lg={3}>
                        <Box
                          sx={{
                            // maxWidth: "377px",
                            // width: "100%",
                            border: "1px solid #000088",
                            // p: "30px",
                            borderRadius: "39px",
                            background: "#F3FBFF",
                            height: "100%",
                            boxSizing: "inherit",
                          }}
                          p={{ xs: 3, md: 5, lg: 3, xl: 5 }}
                        >
                          <Typography
                            color="primary"
                            sx={{
                              fontFamily: "Zen Dots, cursive",
                              textAlign: "center",
                              fontSize: { xs: "24px", lg: "21px", xl: "32px" },
                              mb: `${isMobile ? "0px" : "48px"}`,
                            }}
                          >
                            Popularity
                          </Typography>
                          {/* <ReactApexCharts
                      type="radialBar"
                      height={400}
                      options={options}
                      series={[80, 50, 35]}
                    /> */}
                          {chatGptButton ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                my: 5,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <PopularityChart
                              value={parseInt(chatGPTData?.popularityRating) * 10 || 10}
                            />
                          )}
                          {/* {console.log(
                            parseInt(chatGPTData?.popularityRating),
                            "PopularityChart"
                          )} */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <Box
                          sx={{
                            // maxWidth: "377px",
                            // width: "100%",
                            border: "1px solid #000088",
                            // p: "30px",
                            borderRadius: "39px",
                            background: "#F3FBFF",
                            height: "100%",
                            boxSizing: "inherit",
                          }}
                          p={{ xs: 3, md: 5, lg: 3, xl: 5 }}
                        >
                          <Typography
                            color="primary"
                            sx={{
                              fontFamily: "Zen Dots, cursive",
                              textAlign: "center",
                              fontSize: { xs: "24px", lg: "21px", xl: "32px" },
                              mb: `${isMobile ? "0px" : "40px"}`,
                            }}
                          >
                            Enterprise Adoption
                          </Typography>
                          {chatGptButton ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                my: 5,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <>
                              {/* <Typography
                                sx={{
                                  fontFamily: "Zen Dots, cursive",
                                  fontSize: { xs: "20px", md: "25px" },
                                  textAlign: "center",
                                  // lineHeight: { xs: "40px", md: "62px" },
                                  // color: "#fff",
                                  mb: "37px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {parseInt(chatGPTData?.percentageUsage)}%
                              </Typography> */}

                              <IndustrialUsageChart
                                value={parseInt(chatGPTData?.percentageUsage || 10)}
                              />
                            </>
                            // <IndustrialUsageChart />
                            // <PopularityChart value={parseInt(chatGPTData?.percentageUsage) * 10} />
                          )}
                          {/* {console.log(
                            parseInt(chatGPTData?.percentageUsage),
                            "use"
                          )} */}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={6}>
                        <Box
                          sx={{
                            // maxWidth: "377px",
                            // width: "100%",
                            border: "1px solid #000088",
                            // p: "30px 62px",
                            borderRadius: "39px",
                            background: "#F3FBFF",
                            height: "100%",
                            boxSizing: "inherit",
                          }}
                          p={{ xs: 2, md: 5, lg: 5 }}
                        >
                          <Typography
                            color="primary"
                            sx={{
                              fontFamily: "Zen Dots, cursive",
                              textAlign: "center",
                              fontSize: { xs: "18px", lg: "21px", xl: "32px" },
                              mb: "10px",
                            }}
                          >
                            Learn & Earn Recommendations
                          </Typography>
                          {chatGptButton ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                my: 5,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <Box pl={{ xs: 0, md: 2, xl: 5 }} mt={{ xs: 0, md: 3, lg: 3, xl: 5 }}>
                              {chatGPTData?.latestTechnologies &&
                              Array.isArray(chatGPTData?.latestTechnologies) ? (
                                <>
                                  {" "}
                                  {chatGPTData?.latestTechnologies?.map((item, index) => (
                                    <Stack direction="column" key={index} p={1}>
                                      <List sx={{ padding: 0 }}>
                                        <ListItem sx={{ padding: 0 }}>
                                          <ListItemIcon>
                                            <img
                                              style={{ maxWidth: "36px" }}
                                              src="../../assets/images/fire-icon.svg"
                                              alt="fire"
                                            />
                                          </ListItemIcon>
                                          <ListItemText>
                                            {" "}
                                            <Typography
                                              color="primary"
                                              sx={{
                                                fontFamily: "Poppins, sans-serif",
                                                fontSize: `${isMobile ? "16px" : "24px"}`,
                                              }}
                                            >
                                              {item?.name}

                                              {/* {console.log("technology", item?.name)} */}
                                            </Typography>
                                          </ListItemText>
                                          <ListItemIcon>
                                            <Box pl={1}>
                                              <StyledTooltip
                                                PopperProps={{
                                                  disablePortal: true,
                                                }}
                                                sx={{
                                                  // minWidth: 300,
                                                  border: "1px solid #008",
                                                  backgroundColor: "#FFF",
                                                  borderRadius: "20px",
                                                  p: "11px 34px !important",
                                                  filter:
                                                    "drop-shadow(0px 0px 12px rgba(0, 0, 136, 0.55))",
                                                  maxWidth: { xs: 270, sm: 400 },
                                                  p: "11px 23px !important",
                                                  "& .MuiTooltip-arrow": {
                                                    // color: "#008",
                                                    color: "rgba(0,0,136,1)",
                                                    // backgroundColor:
                                                    //   "#008",
                                                  },
                                                }}
                                                onClose={() => handleTooltipClose(index)}
                                                open={openTooltip[index] || false}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title={
                                                  <CustomTooltipTitle data={item} index={index} />
                                                }
                                                // title={"add"}
                                                arrow
                                                // arrow={<StyledArrow />}
                                                placement={isMobile ? "top" : "right"}
                                              >
                                                <Button
                                                  sx={{
                                                    minWidth: "unset",
                                                    width: 49,
                                                    display: "flex",
                                                    padding: "1px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: "#000088",
                                                    borderRadius: "23px",
                                                    border: "2px solid #000088",
                                                  }}
                                                  onClick={() => handleTooltipOpen(index)}
                                                >
                                                  <KeyboardArrowRight sx={{ fontSize: "24px" }} />
                                                </Button>
                                              </StyledTooltip>
                                            </Box>
                                          </ListItemIcon>
                                        </ListItem>
                                      </List>
                                    </Stack>
                                  ))}
                                </>
                              ) : (
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  flexDirection={"column"}
                                  textAlign={"center"}
                                >
                                  <Box>
                                    <img
                                      src="../../assets/images/empty-list.svg"
                                      alt="data not found"
                                      style={{
                                        maxWidth: `${isMobile ? "50%" : "80%"}`,
                                      }}
                                    />
                                  </Box>
                                  <Typography
                                    sx={{
                                      fontFamily: "Zen Dots, cursive",
                                      textAlign: "center",
                                      fontSize: { xs: "18px", lg: "21px", xl: "32px" },
                                    }}
                                  >
                                    Data not found
                                  </Typography>
                                  {initialCount <= 3 && (
                                    <Box
                                      display={"flex"}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                    >
                                      <Typography pr={1}>Retry with </Typography>
                                      <Button
                                        component="div"
                                        sx={{
                                          maxWidth: "129px",
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          padding: "7px 12px",
                                          borderRadius: "27px",
                                          background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                                        }}
                                        onClick={() => RetryGPT(checkSkill)}
                                        size="small"
                                      >
                                        <RocketLaunch sx={{ color: "#FFF", fontSize: "23px" }} />
                                        <Typography
                                          sx={{
                                            fontSize: "16px",
                                            color: "#FFF",
                                            fontWeight: 600,
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          AI Boost
                                        </Typography>
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Grid>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default TechRelevanceV2;
