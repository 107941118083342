export const getIconForLabel = (question) => {
  switch (question?.label) {
    case "Hands-on in any technologies you code / supervise?":
      return "../../../../assets/images/toggle-switch-icons/Hands-on-technologies.svg";
    case "Worked in any multi-cultural team?":
      return "../../../../assets/images/toggle-switch-icons/Worked-multi-cultural-team.svg";
    case "Any multi-language application exposure?":
      return "../../../../assets/images/toggle-switch-icons/Multi-language-application-exposure.svg";
    case "Any certifications awarded by independent authorities?":
      return "../../../../assets/images/toggle-switch-icons/Certifications-awarded-by-independent-authorities.svg";
    case "Done any performance tuning?":
      return "../../../../assets/images/toggle-switch-icons/Done-performance-tuning.svg";
    case "Ever been involved in providing application support in a 24/7 environment?":
      return "../../../../assets/images/toggle-switch-icons/Involved-in-providing-application-support-environment.svg";
    case "Do you use any tools to increase your productivity?":
      return "../../../../assets/images/toggle-switch-icons/Use-tools-to-increase-productivity.svg";
    case "Do you use and AI tools on a day-to-day basis?":
      return "../../../../assets/images/toggle-switch-icons/Use-aI-tools-day-to-day-basis.svg";
    case "Can you debug issues across n-tiers systems? (Please select 'Yes' if n>4)":
      return "../../../../assets/images/toggle-switch-icons/Debug-issues-across-n-tiers-systems.svg";
    case "Can you independently design an end-to-end product across n-tiers?":
      return "../../../../assets/images/toggle-switch-icons/Independently-design-end-to-end-product.svg";
    case "Have you worked in data related projects?":
      return "../../../../assets/images/toggle-switch-icons/Worked-in-data-related-projects.svg";
    default:
      return `'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>'`;
  }
};
