import { SET_USER_OLD_FORM } from "../action/setOldForm";

export default function OldFormReducer(state = "", action) {
  switch (action.type) {
    case SET_USER_OLD_FORM: {
      const data = action.payload;
      state = data;
      return { ...state };
    }

    default:
      return state;
  }
}
