// import { ErrorMessage } from "@hookform/error-message";
import { Fragment } from "react";
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import TextQuestionComponent from "./TextQuestionComponent";
import { useEffect, useState } from "react";
import ConditionalQuestions from "./ConditionalQuestions";
import TooltipComponent from "../../common/Tooltip";

function SelectQuestionComponent({ question, questionsObject }) {
  const {
    control,
    getValues,
    setValue,
    unregister,
    formState: { errors },
  } = useFormContext();

  const [fieldIsConditional, setFieldIsConditional] = useState(false);
  const [callUseEffect, setCallUseEffect] = useState(false);

  useEffect(() => {
    question?.conditional?.map((conditionalField, i) => {
      questionsObject?.map((conditionalQuestion, i) => {
        if (
          conditionalQuestion.questionId === conditionalField.questionId &&
          getValues(question.questionName) === "Other"
        ) {
          setFieldIsConditional(true);
        } else if (
          conditionalQuestion.questionId === conditionalField.questionId &&
          getValues(question.questionName) !== "Other"
        ) {
          unregister(conditionalQuestion.questionName);
          // setValue(conditionalQuestion.questionName, "");
        }
      });
    });
  }, [callUseEffect]);

  const handleConditionalQuestion = () => {
    callUseEffect ? setCallUseEffect(false) : setCallUseEffect(true);
    setFieldIsConditional(false);
  };

  return (
    <>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography
          // variant="h6"
          // component="h1"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          // sx={{ fontWeight: "bold", py: 1 }}
          className="stepper-question"
        >
          <Box>
            {question.label}
            {question.rules.required && (
              <>
                &nbsp;<span style={{ color: "red" }}>*</span>
              </>
            )}
          </Box>

          {question.question_info != null &&
          question.question_info.replace(/\s/g, "")?.length != 0 ? (
            <>
              <TooltipComponent message={question?.question_info} />
            </>
          ) : (
            <></>
          )}
        </Typography>
        <Controller
          name={question.questionName}
          control={control}
          rules={question.rules}
          render={({ field }) => (
            <Autocomplete
              {...field}
              // size="small"
              onChange={(event, newValue) => {
                field.onChange(newValue);
                newValue === "Other"
                  ? setFieldIsConditional(true)
                  : handleConditionalQuestion();
              }}
              id="size-small-standard"
              options={question.options}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus={question?.autoFocus}
                  // size="small"
                  variant="outlined"
                  label={question.placeholder}
                />
              )}
            />
          )}
        />

        {errors[question.questionName]?.type === "required" && (
          // <p className={"errorMsg"}>{question.errorMsg}</p>
          <FormHelperText error className={"errorMsg"}>
            {question.errorMsg}
          </FormHelperText>
        )}
      </Grid>
      {fieldIsConditional && (
        <ConditionalQuestions
          question={question}
          questionsObject={questionsObject}
        />
      )}
    </>
  );
}
export default SelectQuestionComponent;
