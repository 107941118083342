import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { getOptions } from "../stepper.helper";
import "../../../styles.css";
import React, { useEffect, useState } from "react";
import TooltipComponent from "../../common/Tooltip";
import _ from "lodash";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

function DynamiCertificateQuestion({ question, questionsObject }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  // maulik code added here

  const [open, setOpen] = useState(false); // if dropdown open?
  const [autoValue, setAutoValue] = useState(getValues(question?.questionName) || []); // if dropdown open?
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(question?.questionName, autoValue);
  }, [autoValue]);
  const filter = createFilterOptions({
    stringify: (option) => `${option?.name} (${option?.certificatePopularName})`,
  });

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography
        // variant="h6"
        // component="h1"
        // sx={{ fontWeight: "bold", py: 1 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="stepper-question"
      >
        <Box>
          {question.label}
          {question.rules.required && (
            <>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </>
          )}
        </Box>

        {question.question_info != null &&
        question.question_info.replace(/\s/g, "")?.length != 0 ? (
          <>
            <TooltipComponent message={question?.question_info} />
          </>
        ) : (
          <></>
        )}
      </Typography>

      <Controller
        name={question.questionName}
        control={control}
        rules={question.rules}
        render={({ field }) => (
          <Autocomplete
            noOptionsText="Start typing to get options"
            {...field}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            multiple
            isOptionEqualToValue={(option, value) =>
              `${option?.name} (${option?.certificatePopularName})` ===
              `${value?.name} (${value?.certificatePopularName})`
            }
            value={autoValue}
            onChange={(event, newValue) => {
              if (newValue.slice(-1)[0] && newValue.slice(-1)[0].inputValue != undefined) {
                setAutoValue([
                  ...autoValue,
                  {
                    name: newValue.slice(-1)[0].inputValue,
                    other: true,
                  },
                ]);
              } else {
                setAutoValue(newValue);
              }
              setData([]);
            }}
            onInputChange={(event, value) => {
              setLoading(true);
              if (value.length != 0 && value.trim()) {
                getOptions(
                  setData,
                  `/get-certificates?certificates=${encodeURIComponent(value.trim())}`,
                  setLoading
                );
              }
              if (value.length === 0) {
                setLoading(false);
              }
            }}
            id="free-solo-dialog-demo"
            options={data}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              if (option?.certificatePopularName !== undefined) {
                return `${option.name} (${option?.certificatePopularName})`;
              } else {
                return `${option.name}`;
              }
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterOptions={(options, params) => {
              params.inputValue = params.inputValue.trim();

              let filtered = filter(options, params);

              const isExisting = options.some(
                (option) =>
                  params.inputValue.toLowerCase() === option?.name.toLowerCase() ||
                  params.inputValue === option?.certificatePopularName
              );
              const isExisting2 = options.some(
                (option) => params.inputValue === option?.certificatePopularName
              );
              if (params.inputValue !== "" && !isExisting && !isExisting2) {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}" Certificate`,
                  certificatePopularName: "",
                });
              }
              return filtered;
            }}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(
                `${option?.name} (${option?.certificatePopularName})`,
                inputValue
              );

              const parts = parse(`${option?.name} (${option?.certificatePopularName})`, matches);

              return (
                <li {...props} key={question.questionId}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          color: part.highlight ? "red" : "inherit",
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                label={question.placeholder}
              />
            )}
          />
        )}
      />

      {errors[question.questionName]?.type === "required" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
    </Grid>
  );
}
export default DynamiCertificateQuestion;
