// src/NumberTicker.js

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const NumberTicker = ({ targetNumber }) => {
  const duration = 20000;
  const [currentNumber, setCurrentNumber] = useState(0);
  const [increment, setIncrement] = useState(0);

  const styles = {
    fontColor: "#FFC218",
    fontWeight: 600,
    labelColor: "#FFFFFF",
    firstLabelColor: "#FFFFFF",
  };

  useEffect(() => {
    if (targetNumber > 0 && duration > 0) {
      const incrementValue = Math.ceil(targetNumber / (duration / 50));
      setIncrement(incrementValue);

      const timer = setInterval(() => {
        setCurrentNumber((prev) => {
          if (prev + incrementValue >= targetNumber) {
            clearInterval(timer);
            return targetNumber;
          }
          return prev + incrementValue;
        });
      }, 50);

      return () => clearInterval(timer);
    }
  }, [targetNumber, duration]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography
        variant="h2"
        sx={{
          fontWeight: styles.fontWeight,
          color: styles.fontColor,
          transition: "transform 0.05s ease-in-out",
          display: "inline-block",
        }}
      >
        {currentNumber}+
      </Typography>
    </Box>
  );
};

export default NumberTicker;
