import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { FFContextProvider } from "@harnessio/ff-react-client-sdk";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import AppLoading from "./components/UI/AppLoading";

import { withFeatureFlags } from "@harnessio/ff-react-client-sdk";

function MyComponent({ flags, loading }) {
  if (loading) {
    return <AppLoading />;
  }
  return <App />;
}
const MyComponentWithFlags = withFeatureFlags(MyComponent);

const root = ReactDOM.createRoot(document.getElementById("root"));
const reactTarget = { name: "ReactClientSDK", identifier: "reactclientsdk" };
const sdkApiKey = process.env.REACT_APP_HARNESS_KEY;
const TRACKING_ID = "UA-266174552-3"; // OUR_TRACKING_ID
const Environment = process.env.REACT_APP_ENV;
if (Environment === "prod") {
  ReactGA.initialize(TRACKING_ID);
  ReactGA4.initialize("G-PXNC5S04ZS");
}

const isLocalDevelopment = process.env.REACT_APP_ENV === "dev";
// const config = {
//   url: "http://localhost:4242/api/frontend/",
//   clientKey: "default:development.unleash-insecure-frontend-api-token",
//   refreshInterval: 15,
//   appName: "Default",
//   environment: "dev",
// };
// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );
const RootComponent = isLocalDevelopment ? (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FFContextProvider async apiKey={sdkApiKey} target={reactTarget}>
          <MyComponentWithFlags />
        </FFContextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
) : (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FFContextProvider apiKey={sdkApiKey} target={reactTarget}>
        <MyComponentWithFlags />
      </FFContextProvider>
    </PersistGate>
  </Provider>
);

root.render(RootComponent);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
