import { useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Box,
  FormHelperText,
  Chip,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@mui/material/Typography";
import "../../../styles.css";
import TooltipComponent from "../../common/Tooltip";
import { useSelector } from "react-redux";

function MultiSelectQuestionComponent({ question }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const subSkillOptions = useSelector(
    (state) => state.QuestionJsonReducer.subSkills || []
  );

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  // State to manage selected values
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Function to determine background color based on easeOfLearning
  const getChipBackgroundColor = (easeOfLearning) => {
    if (easeOfLearning >= 70) {
      return "#A8E6A3"; // Light Green for Easy
    } else if (easeOfLearning >= 50 && easeOfLearning < 70) {
      return "#FFD580"; // Light Orange for Medium
    } else {
      return "#FFA6A6"; // Light Red for Hard
    }
  };

  // Sorting function based on easeOfLearning and parentSkill
  const sortByParentSkillAndEaseOfLearning = (options) => {
    return [...options].sort((a, b) => {
      if (a.parentSkill === b.parentSkill) {
        return a.easeOfLearning - b.easeOfLearning;
      }
      return a.parentSkill.localeCompare(b.parentSkill);
    });
  };

  // Watch for changes in the selected options
  const currentValues = watch(question.questionName);

  useEffect(() => {
    if (question.questionName === "subSkill") {
      const options = subSkillOptions;

      if (currentValues) {
        const filteredValues = currentValues.filter((value) =>
          options.some((option) => option._id === value._id)
        );
        setSelectedOptions(sortByParentSkillAndEaseOfLearning(filteredValues));
      } else {
        setSelectedOptions([]);
      }
    } else {
      setSelectedOptions(currentValues || []);
    }
  }, [subSkillOptions, currentValues, question.questionName]);

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="stepper-question"
      >
        <Box>
          {question.label}
          {question.rules.required && <span style={{ color: "red" }}>*</span>}
        </Box>
        {question.question_info?.trim() && (
          <TooltipComponent message={question.question_info} />
        )}
      </Typography>

      {/* Legend for ease of learning */}
      {question.questionName === "subSkill" && (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mb: 2,
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
        >
          <Box>
            <Chip
              label="Easy"
              style={{
                backgroundColor: "#A8E6A3", // Light Green for Easy
                color: "#000",
                marginRight: 8,
              }}
            />
            <Chip
              label="Medium"
              style={{
                backgroundColor: "#FFD580", // Light Orange for Medium
                color: "#000",
                marginRight: 8,
              }}
            />
            <Chip
              label="Hard"
              style={{
                backgroundColor: "#FFA6A6", // Light Red for Hard
                color: "#000",
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                mr: { md: 1 },
                ml: { xs: 1, md: 2 },
                textAlign: { xs: "center", md: "inherit" },
                pt: { xs: 1, md: 0 },
              }}
            >
              (Ease of learning)
            </Typography>
          </Box>
        </Box>
      )}

      <Controller
        name={question.questionName}
        control={control}
        rules={question.rules}
        render={({ field }) => (
          <Autocomplete
            multiple
            {...field}
            value={selectedOptions}
            onChange={(event, newValue) => {
              setSelectedOptions(sortByParentSkillAndEaseOfLearning(newValue));
              field.onChange(newValue);
            }}
            options={
              question.questionName === "subSkill"
                ? subSkillOptions.filter(
                    (option) =>
                      !selectedOptions.some(
                        (selected) => selected._id === option._id
                      )
                  )
                : question.options
            }
            disableCloseOnSelect
            getOptionLabel={(option) =>
              question.questionName === "subSkill"
                ? option.subSkill
                : option || ""
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {question.questionName === "subSkill"
                  ? option.subSkill
                  : option}
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option._id}
                  label={
                    question.questionName === "subSkill"
                      ? option.subSkill
                      : option
                  }
                  style={{
                    backgroundColor:
                      question.questionName === "subSkill"
                        ? getChipBackgroundColor(option.easeOfLearning)
                        : "#f1f1f1",
                    color: "#000", // Optional: Adjust text color based on background
                  }}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                autoFocus={question?.autoFocus}
                {...params}
                label={question.placeholder}
              />
            )}
          />
        )}
      />

      {errors[question.questionName]?.type === "required" && (
        <FormHelperText error className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
    </Grid>
  );
}
export default MultiSelectQuestionComponent;
