import React, { useState } from "react";
import { TextField, Button, Typography, Box, Grid, Container, Paper, Link } from "@mui/material";
import Header from "../components/Header";
import FooterComponent from "../components/Footer";
import toast from "react-hot-toast";
import axios from "axios";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      message: message,
    };
    // Here, you can add your email sending logic using an API or library
    sendEmail(data);
    // Reset form fields
    setName("");
    setEmail("");
    setMessage("");
  };

  const sendEmail = async (data) => {
    // Implement your email sending logic here
    // console.log("Sending email:", data);
    try {
      const response = await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: data?.name,

            email: data?.email,
          },

          to: [{ email: "support@vucaware.com" }],

          subject: "Contact Us (True Selfy)",

          htmlContent: `<p>${data?.message}</p>`,
        },

        {
          headers: {
            "api-key": process.env.REACT_APP_SENDIN_BLUE_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log(response.data);
      toast.success("message sent sucessfully");
    } catch (error) {
      console.error(error);
      toast.error("Network Error");
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Grid p={5} sx={{ backgroundColor: "#D0D0D0", marginBottom: "20px" }}>
        <Box
          sx={{
            maxWidth: 400,
            margin: "0 auto",
            marginTop: "99px",
            backgroundColor: "#ffff",
            boxShadow: "16px 16px 8px rgba(0, 0, 0, .1)",
            borderRadius: "20px",
          }}
          display={"flex"}
          flexDirection={"row"}
          p={4}
        >
          <Box sx={{ maxWidth: 400 }}>
            <Typography variant="h4" gutterBottom textAlign={"center"} sx={{ fontWeight: "bold" }}>
              Contact Us
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#ffff", // Background color
                    "& fieldset": {
                      borderColor: "#000", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#000", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000", // Border color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000", // Label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000", // Label color when focused
                  },
                  "& .MuiInputBase-input": {
                    color: "#000", // Input text color
                  },
                }}
              />
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#ffff", // Background color
                    "& fieldset": {
                      borderColor: "#000", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#000", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000", // Border color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000", // Label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000", // Label color when focused
                  },
                  "& .MuiInputBase-input": {
                    color: "#000", // Input text color
                  },
                }}
              />
              <TextField
                label="Message"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                required
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#ffff", // Background color
                    "& fieldset": {
                      borderColor: "#000", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#000", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000", // Border color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000", // Label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000", // Label color when focused
                  },
                  "& .MuiInputBase-input": {
                    color: "#000", // Input text color
                  },
                }}
              />
              <Box display="flex" justifyContent={"center"}>
                <Button textAlign="center" type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: 500,
            margin: "30px auto",
            backgroundColor: "#ffff",
            boxShadow: "16px 16px 8px rgba(0, 0, 0, .1)",
            borderRadius: "20px",
          }}
          display={"flex"}
          flexDirection={"row"}
          p={{ xs: 3, md: 4, lg: 4, xl: 4 }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                You can reach us at:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }} pb={0.5}>
                Vucaware Emerging Technologies Private Limited
              </Typography>
              <Typography variant="body1">Building E2, Flat No. 2,</Typography>
              <Typography variant="body1">ITI Road, Survey No. 138,</Typography>
              <Typography variant="body1">Shirine Garden, Aundh,</Typography>
              <Typography variant="body1">Pune, Maharashtra - 411007</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Email: <Link href="mailto:support@vucaware.com">support@vucaware.com</Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <FooterComponent />
    </React.Fragment>
  );
};

export default ContactUs;
