import React, { useState } from "react";
import {
  Typography,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Chip,
  TableRow,
  TableCell,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  TablePagination,
} from "@mui/material";
import { Add, Delete, DeleteOutline } from "@mui/icons-material";
import { deleteReferralUser } from "../stepper_form/stepper.helper";
import { useSelector, useDispatch } from "react-redux";
import CustomizedSnackbars from "./CustomizedSnackbars";

function ReferencesTable({ referralsList }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showTooltip, setShowTooltip] = useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();
  const [snackbarMsg, setSnackbarMsg] = React.useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallDeskTop = useMediaQuery("(max-width:1260px)");
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // get status according to reference status
  function getReferralsStatus(status) {
    switch (status) {
      case "success":
        return (
          <Chip size="small" sx={{ background: "#B3DCB6", color: "#64A166" }} label="Endorsed" />
        );
      case "notsignup":
        return (
          <Chip
            size="small"
            sx={{ background: "#CFD0D3", color: "#696B70" }}
            label="Not logged in"
          />
        );

      case "pending":
        return (
          <Chip size="small" sx={{ background: "#FFEAB6", color: "#DBAC3D" }} label="Pending" />
        );

      case "deleted":
        return (
          <Chip size="small" sx={{ background: "#F2CFD1", color: "#C94A51" }} label="Deleted" />
        );

      default:
        return (
          <Chip
            size="small"
            sx={{ background: "#CFD0D3", color: "#696B70" }}
            label="Not logged in"
          />
        );
        break;
    }
  }

  // get email with user status for referrals
  function getUserStatusForMobile(status, email, index) {
    switch (status) {
      case "success":
        return <Chip size="small" sx={{ background: "#B3DCB6", color: "#64A166" }} label={email} />;
      case "notsignup":
        return <Chip size="small" sx={{ background: "#CFD0D3", color: "#696B70" }} label={email} />;

      case "pending":
        return <Chip size="small" sx={{ background: "#FFEAB6", color: "#DBAC3D" }} label={email} />;

      case "deleted":
        return <Chip size="small" sx={{ background: "#F2CFD1", color: "#C94A51" }} label={email} />;

      default:
        return <Chip size="small" sx={{ background: "#CFD0D3", color: "#696B70" }} label={email} />;
        break;
    }
  }

  function deleteReferral(referralUserEmail) {
    // const copyOfReferralslist = referralslist;
    // const filteredList = referralslist?.map((item) => {
    //   if (item.email === referralUserEmail) {
    //     item.status = "deleted";
    //   }
    //   return item;
    // });

    // setOpenSnackbar(true);
    setSnackbarMsg("referral deleted");
    setSnackbarSeverity("success");
    deleteReferralUser(
      referralsList,
      referralUserEmail,
      setSnackbarSeverity,
      setSnackbarMsg,
      setOpenSnackbar,
      dispatch
    );
  }
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: { sm: "700px", md: "700px", lg: "650px", xl: "750px" },
      }}
    >
      <TableContainer
        sx={{
          display: "flex",
          justifyContent: {
            xs: "left",
            md: "center",
            sm: "center",
            lg: "center",
          },
        }}
      >
        <Table
          className="references-table"
          sx={{
            size: { xs: "small", sm: "medium" },
            minWidth: 250,
            width: "100%",
            maxWidth: {
              xs: 550,
              sm: "700px",
              md: "700px",
              lg: "650px",
              xl: "750px",
            },
            border: "1px solid #D3D3D3",
            borderCollapse: "separate",
            overflow: "hidden",
            borderRadius: "15px !important",
            "& .MuiTableCell-head": {
              fontSize: { xs: "15px", sm: "18px" },
              fontWeight: 500,
            },
            "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root": {
              borderBottom: "0px",
            },
            "& .MuiTableCell-root h5": {
              fontSize: { xs: "14px", sm: "18px" },
              fontWeight: 400,
            },
            "& .MuiTableCell-root ": {
              borderRight: "1px solid #D3D3D3",
              padding: { xs: "8px !important", sm: "13px !important" },
              textAlign: "center",
            },
            "& .MuiTableCell-root:last-child ": {
              borderRight: "0px",
              textAlign: "center",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              {!isMobile && <TableCell>Name</TableCell>}
              <TableCell>Email</TableCell>
              {!isMobile && (
                <TableCell
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "block",
                      lg: "block",
                    },
                  }}
                >
                  Status
                </TableCell>
              )}

              {/* {!isMobile && <TableCell>Action</TableCell>} */}
            </TableRow>
          </TableHead>
          <TableBody>
            {referralsList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => (
                <TableRow
                  key={index}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Typography>{page * rowsPerPage + index + 1}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      display: {
                        xs: "none",
                        sm: "block",
                        md: "block",
                        lg: "block",
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        color: "#777777",
                        textAlign: "left",
                        maxWidth: { sm: 120, lg: 130, xl: 200 },
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row?.name === null ? "Pending" : row?.name}
                    </Typography>
                  </TableCell>
                  {/* for mobile view email with user status */}
                  {isMobile && (
                    <TableCell
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // flexWrap: "wrap",
                        }}
                      >
                        <Tooltip
                          open={showTooltip === index}
                          arrow
                          placement="top"
                          title={
                            <>
                              <Box>
                                <Typography>
                                  Name : {row?.name?.length === 0 ? "Unknown" : row?.name}
                                </Typography>{" "}
                                <Typography>Email: {row?.email}</Typography>
                                <Typography>Status: {row?.status}</Typography>
                              </Box>
                            </>
                          }
                          onClose={() => setShowTooltip(null)}
                        >
                          <Typography
                            varaint="h5"
                            component="h5"
                            sx={{
                              color: "#777777",
                              textAlign: "left",
                              maxWidth: "210px",
                            }}
                            onClick={() => setShowTooltip(index)}
                          >
                            {getUserStatusForMobile(row?.status, row.email, index)}
                          </Typography>
                        </Tooltip>
                        {row?.status !== "deleted" && (
                          <Tooltip arrow placement="top" title="Delete Referral">
                            <IconButton
                              color="error"
                              size="small"
                              sx={{
                                background: "#f2cfd1",
                              }}
                              onClick={() => deleteReferral(row.email)}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  )}
                  {/* email column for desktop or tablet devices  */}
                  {!isMobile && (
                    <TableCell>
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          varaint="h5"
                          component="h5"
                          sx={{
                            color: "#777777",
                            textAlign: "left",
                            maxWidth: { sm: 290, lg: 250, xs: 300 },
                            // display: "-webkit-box",
                            // WebkitBoxOrient: "vertical",
                            // WebkitLineClamp: 1,
                            // overflow: "hidden",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {row.email}
                        </Typography>
                        {row?.status !== "deleted" && (
                          <Tooltip arrow placement="top" title="Delete Referral">
                            <IconButton
                              color="error"
                              size="small"
                              sx={{
                                background: "#f2cfd1",
                              }}
                              onClick={() => deleteReferral(row.email)}
                            >
                              <DeleteOutline sx={{ fontSize: "18px !important" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{getReferralsStatus(row?.status)}</Typography>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={referralsList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMsg={snackbarMsg}
          snackbarSeverity={snackbarSeverity}
        />
      )}
    </Box>
  );
}

export default ReferencesTable;
