import React from "react";
import DashboardLayout from "./Dashboard";
import DashboardCards from "./Dashboard/DashboardCards";


function AdminPanel() {
  return (
    <>
       <DashboardLayout >
        <DashboardCards/>
      </DashboardLayout> 
    </>
  );
}

export default AdminPanel;
