import React from 'react';
import {useSelector} from 'react-redux'
import {getEmail} from "../../utils/storage.js";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";



const ErrorReport= (errorDetails) => {
  // const supportEmails = useSelector((state)=> state.SupportReducer.emails);

  const userEmail = getEmail();  
  const Admin_Access = useFeatureFlag("Admin_Access");
  const allowedEmails = JSON.parse(Admin_Access);

  const sendErrorReport = async () => {
    try {
      await axios.post(
        'https://api.sendinblue.com/v3/smtp/email',
        {
          sender: {
            name: 'API Error Alert',
            email: "support@vucaware.com",
          },
          // to: supportEmails.map(email => ({ email })),
        to: [{email:'maulikd@jspinfotech.com'}],
          subject: 'API Failure Alert (True Selfy)',
          htmlContent: `
            <div style="font-family: Arial, sans-serif; color: #333; line-height: 1.6;">
              <h2 style="color: #d9534f;">API Failure Alert</h2>
              <p>Dear Team,</p>
              <p>An error has occurred in one of the API requests. Please find the details below:</p>
              <table style="width: 100%; border-collapse: collapse;">
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd;"><strong>Error Message:</strong></td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${errorDetails.errorMessage}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd;"><strong>API Endpoint:</strong></td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${errorDetails.apiEndpoint}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd;"><strong>Status Code:</strong></td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${errorDetails.statusCode}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd;"><strong>User Email:</strong></td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${userEmail}</td>
                </tr>
              </table>
              <p>Please take the necessary actions to resolve this issue.</p>
              <p>Thank you,</p>
              <p><em>API Monitoring System</em></p>
            </div>
          `,
        },
        {
          headers: {
            'api-key': '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',
            'Content-Type': 'application/json',
          },
        }
      );

      console.log("Error report sent successfully");
    } catch (error) {
      console.error('Failed to send error report:', error);
    }
  };

  return {
    supportEmails,
    userEmail,
    sendErrorReport,
  };
};

export default ErrorReport;
