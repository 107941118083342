import _ from "lodash";
import apis from "../../utils/apis";

export const getEvaluationUser = async (
  setEvaluationUsers,
  setFormPendingAndCompletedList,
  setEvalutionLoader,
  navigate,
  uniqueReferredUserId,
  userType
) => {
  apis
    .GET({
      endpoint: `/get-evaluation-list?uniqueReferredUserId=${uniqueReferredUserId}?type=${userType}`,
      options: {
        type: "json",
      },
    })
    .then((object) => {
      if (object != undefined) {
        setEvaluationUsers(object?.data?.evaluatedList);
        setFormPendingAndCompletedList(object?.data?.pendingAndCompletedList);
        setEvalutionLoader(false);
      } else {
        navigate("/signin");
      }
    });
};

export const getEvaluationQuestionForm = async (
  setStpeerFormLoading,
  resultId,
  evaluatedId,
  setEvaluationQuestions,
  setQuestionsSteps,
  methods,
  setActiveStep,
  setDesignation,
  setCompany
) => {
  apis
    .POST({
      endpoint: "/get-evaluation-form",
      payload: { resultId, evaluatedId },
      options: {
        type: "json",
      },
    })
    .then((object) => {
      if ((object?.data != undefined && object?.data?.status !== 400) || object !== null) {
        function sortAndFilterQuestions(questions, steps) {
          const visibleSteps = steps.filter((step) => !step.hideStep);
          setQuestionsSteps(visibleSteps);
          const visibleQuestions = questions.filter((question) => {
            const matchingStep = visibleSteps.find((step) => step.step === question.step);
            return matchingStep && !question.hideQuestion;
          });

          visibleQuestions.sort((a, b) => {
            const stepA = a.step;
            const stepB = b.step;
            const indexA = visibleSteps.findIndex((step) => step.step === stepA);
            const indexB = visibleSteps.findIndex((step) => step.step === stepB);
            return indexA - indexB || a.order - b.order;
          });

          return visibleQuestions;
        }

        // Usage example:

        const finalQuestions = sortAndFilterQuestions(object?.data?.questions, object?.data?.steps);
        // console.log(finalQuestions, "final questions");

        setEvaluationQuestions(finalQuestions);
        const userformdata = object?.data?.userFormObject;
        console.log("userform", userformdata === null);
        if (userformdata !== null) {
          const activestep = Object.keys(userformdata).length;
          // console.log(finalQuestions?.length, "length question");
          // if (
          //   activestep < finalQuestions?.length &&
          //   Object.keys(userformdata).length !== 0
          // ) {
          //   setActiveStep(activestep - 1);
          //   console.log("updated");
          // } else {
          //   setActiveStep(0);
          // }
          // console.log("designation", designation, company);
          // methods.resetField("designation", { defaultValue: designation });
          // methods.resetField("company", { defaultValue: company });
          if (userformdata?.company !== undefined) {
            setCompany(userformdata?.company);
          }
          if (userformdata?.designation !== undefined) {
            setDesignation(userformdata?.designation);
          }

          methods.reset(userformdata);
        }
        setStpeerFormLoading(false);
      } else {
        setStpeerFormLoading(false);
      }
      // setEvaluationQuestionList(object?.data);

      // console.log("questiondata", typeof object?.data);
      // console.log("questiondata", object?.data?.length);
    });
};
export const saveEvaluationFormData = (data) => {
  apis
    .POST({
      endpoint: "/save-evaluation-result",
      payload: data,
      options: {
        type: "json",
      },
    })
    .then((obj) => {});
};
export const submitEvaluationFormResult = (
  data,
  setEvaluationUsers,
  setFormPendingAndCompletedList,
  navigate,
  setEvaluationUserLoader
) => {
  apis
    .POST({
      endpoint: "/submit-evaluation-result",
      payload: data,
      options: {
        type: "json",
      },
    })
    .then((obj) => {
      if (obj?.data != undefined) {
        setEvaluationUsers(obj?.data?.evaluatedList);
        setEvaluationUserLoader(false);

        // apis
        //   .GET({
        //     endpoint: "/get-evaluation-list",
        //     options: {
        //       type: "json",
        //     },
        //   })
        //   .then((object) => {
        //     if (object != undefined) {
        //       setEvaluationUsers(object.data?.evaluatedList);
        //       setFormPendingAndCompletedList(
        //         object.data?.pendingAndCompletedList
        //       );
        //       setEvaluationUserLoader(false);

        //       // console.log(" evaluation data", object?.data?.evaluatedList);
        //     } else {
        //       navigate("/");
        //     }
        //   });
      } else {
        setEvaluationUserLoader(false);
      }
    });
};

export const deleteEvalutionUser = (
  deleteUserObject,
  evaluationUsers,
  setEvaluationUsers,
  setOpenSnackbar,
  setSnackbarMsg,
  setSnackbarSeverity
) => {
  try {
    apis
      .DELETE({
        endpoint: `/delete-userFromEvaluationList?evaluatedId=${deleteUserObject.evaluatedId}`,
      })
      .then((response) => {
        console.log("response", response);
        if (response?.status !== 201) {
          const updatedEvaluationUsers = [...evaluationUsers, deleteUserObject];
          setEvaluationUsers(updatedEvaluationUsers);
          setOpenSnackbar(true);
          setSnackbarMsg("user not deleted try after some time");
          setSnackbarSeverity("error");
        }
      });
  } catch (error) {
    console.log("error", error);
  }
};
