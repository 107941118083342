import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import {
  getEvaluationQuestionForm,
  saveEvaluationFormData,
  submitEvaluationFormResult,
} from "./evaluation.helper";
import EvaluationForm from "./EvaluationForm";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useMediaQuery } from "@mui/material";
import { UAParser } from "ua-parser-js";
import DeviceInfoAndLocation from "../common/DeviceInfoAndLocation";
import { Adjust } from "@mui/icons-material";
import { Stack, Grid } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getEvaluationUser } from "./evaluation.helper";
import { motion } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import CheckMark from "../common/CheckMark";
import { getEmail } from "../../utils/storage";
import { useTheme } from "@mui/material/styles";
import { setUserStatus } from "../../redux/action/user";
import "../../App.css";
import { useDispatch } from "react-redux";
import ReactGA4 from "react-ga4";

// const evaluationQuestions = [
//   "Select campaign settings",
//   "Create an ad group",
//   "Create an ad",
// ];

function EvaluationStepperForm({
  item,
  resultId,
  evaluatedId,
  expandedPanel,
  location,
  deviceInfo,
  index,
  setExpandedPanel,
  setEvaluationUsers,
  setFormPendingAndCompletedList,
  setEvaluationUserLoader,
  formPendingAndCompletedList,
  setDesignation,
  setCompany,
  designation,
  company,
}) {
  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }
  const isMobile = useMediaQuery("(max-width:600px)");
  const [activeStep, setActiveStep] = React.useState(0);
  const methods = useForm({ criteriaMode: "all" });
  const [evaluationQuestions, setEvaluationQuestions] = React.useState([]);
  const [questionSteps, setQuestionsSteps] = React.useState([]);
  const [stpeerFormLoading, setStpeerFormLoading] = React.useState(true);
  const [formfinish, setFormFinish] = useState(false);
  const [formdata, setFormdata] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [animate, setAnimate] = useState(false);
  const dispatch = useDispatch();
  const handleNext = async (data) => {
    // var result = { evaluatedId, resultId,  };
    methods.resetField("company", { defaultValue: company });

    if (data?.designation !== undefined && formdata?.designation !== "") {
      setDesignation(data?.designation);
    }
    if (data?.company !== undefined && formdata?.company !== "") {
      setCompany(data?.company);
    }
    if (company !== formdata?.company) {
      methods.resetField("company", { defaultValue: company });
    }
    setCompany(data?.company);
    // console.log("company", company);
    setFormdata(data);

    // Extract skill values dynamically and format them
    data.evaluatedId = evaluatedId;
    data.resultId = resultId;
    data.currentUserEmail = getEmail();
    data.deviceinfo = deviceInfo;
    data.location = location;
    if (evaluationQuestions.length + 1 === activeStep + 1) {
      setEvaluationUserLoader(true);
      item.filter(function (item) {
        if (item?.resultId === resultId) {
          return (item.evaluateuserLoader = true);
        }
      });
      formPendingAndCompletedList?.pendingResult.filter(function (item) {
        if (item?.resultId === resultId) {
          return (item.evaluateuserLoader = true);
        }
      });
      submitEvaluationFormResult(
        formdata,
        setEvaluationUsers,
        setFormPendingAndCompletedList,
        navigate,
        setEvaluationUserLoader
      );
      ReactGA4.event({
        category: "endorse",
        action: "Endorse Form Submited",
        label: `user: ${getEmail()}`,
      });
      setFormFinish(true);
      setExpandedPanel(!expandedPanel);
      setActiveStep(1);
    } else {
      saveEvaluationFormData(data);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    getEvaluationQuestionForm(
      setStpeerFormLoading,
      resultId,
      evaluatedId,
      setEvaluationQuestions,
      setQuestionsSteps,
      methods,
      setActiveStep,
      setDesignation,
      setCompany
    );
    if (formdata?.designation !== undefined && formdata?.designation !== "") {
      methods.resetField("designation", { defaultValue: designation });
      setDesignation(formdata?.designation);
    }

    if (formdata?.company !== undefined && formdata?.company !== "") {
      methods.resetField("company", { defaultValue: company });
      setCompany(formdata?.company);
      // console.log(formdata?.company, "formdata company");
    }
    // console.log(resultId, "resultId");
  }, []);

  useEffect(() => {
    // console.log("panel expanded");
    // console.log("company", company);
    // console.log("designation", designation);

    methods.resetField("designation", { defaultValue: designation });
    methods.resetField("company", { defaultValue: company });
  }, [expandedPanel === index]);

  return (
    <React.Fragment>
      {stpeerFormLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            background: `${theme.palette.primary.evalutebackground}`,
          }}
          maxWidth={{ xs: "400px", sm: "100%", md: "100%", lg: "100%" }}
        >
          {activeStep !== evaluationQuestions.length && (
            <Grid
              container
              display={"flex"}
              p={{ xs: 0, md: 1, lg: 1, xl: 1 }}
              alignItems={"center"}
            >
              <Grid item xs={5}>
                <Stack
                  alignItems={"center"}
                  direction={{ xs: "row", sm: "row" }}
                  spacing={{ xs: 1, sm: 0 }}
                >
                  <Box>
                    <img
                      width={isMobile ? "24px" : "100%"}
                      loading="lazy"
                      src={`../../../../assets/images/evaluation-question-icons/steps/${evaluationQuestions[
                        activeStep
                      ]?.step
                        .toLowerCase()
                        .replace(/\s/g, "")
                        .trim()}.svg`}
                      alt={`${evaluationQuestions[activeStep]?.step || "img"}`}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: "#727272",
                      fontWeight: "400",
                      fontSize: `${isMobile ? "12px !important" : ""}`,
                    }}
                    variant="h5"
                    pl={{ xs: 0, md: 1, lg: 1, xl: 1 }}
                    disableGutters
                  >
                    {evaluationQuestions[activeStep]?.step}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={2}>
                <Box display={"flex"} justifyContent={"center"}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "400",
                      textAlign: "center",
                      // marginBottom: "10px",
                    }}
                  >
                    {activeStep + 1}/{evaluationQuestions?.length}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={5}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <HighlightOffIcon
                    fontSize={`${isMobile ? "medium" : "large"}`}
                    sx={{ cursor: "pointer" }}
                    onClick={() => setExpandedPanel(!expandedPanel)}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {/* question steps dots */}
          <Box px={"25%"}>
            {!isMobile && activeStep !== evaluationQuestions.length && (
              <Stepper
                sx={{
                  flexWrap: "wrap",
                  "& .MuiStep-root": {
                    padding: "0px !important",
                  },
                  "& .MuiStepConnector-line": {
                    borderTop: "1.25px solid #2BB344 ",
                  },
                }}
                px={10}
                activeStep={activeStep}
              >
                {evaluationQuestions.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step
                      width={26}
                      height={26}
                      icon={<Adjust />}
                      key={index}
                      {...stepProps}
                      sx={{
                        padding: "0px 18px",
                      }}
                    >
                      <StepLabel
                        {...labelProps}
                        sx={{
                          "& .MuiStepLabel-root": {
                            p: 0,
                            outline: "1px solid red",
                            borderRadius: "12px",
                            color: "#fff !important",
                            background: "#FFF",
                          },
                          "& .MuiStepLabel-iconContainer": {
                            p: 0,
                          },

                          "& .MuiStepIcon-text": {
                            display: "none",
                          },

                          "& .MuiStepIcon-root": {
                            color: "#FFF",
                            outline: "1px solid #D1D5DB",
                            borderRadius: "12px",
                            position: "relative",
                          },
                          "& .MuiStepIcon-root::before": {
                            content: "''",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            width: "10px",
                            height: "10px",
                            borderRadius: "inherit",
                            background: "red !important",
                          },

                          "& .Mui-active": {
                            p: 0,
                            outline: `1px solid #FFC218 !important`,
                            borderRadius: "12px",
                            color: "#fff !important",
                            position: "relative",
                          },
                          "& .Mui-active::after": {
                            content: "''",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            width: "10px",
                            height: "10px",
                            borderRadius: "inherit",
                            background: `#FFC218`,
                          },

                          "& .Mui-completed": {
                            color: `${theme.palette.primary.evalute} !important`,
                          },
                        }}
                      >
                        {/* {label} */}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
          </Box>
          {activeStep === evaluationQuestions.length ? (
            <React.Fragment>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <HighlightOffIcon
                  sx={{
                    cursor: "pointer",
                  }}
                  fontSize="large"
                  onClick={() => setExpandedPanel(!expandedPanel)}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  <Box p={1}>
                    <CheckMark />
                  </Box>
                  <Typography variant="h4">Please Confirm</Typography>
                  <Typography>Are you sure to submit your answers?</Typography>
                </Box>
                <Box p={2} display={"flex"} justifyContent={"center"}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleBack}
                    sx={{
                      mx: isMobile ? 1 : 2,
                      color: "#636363",
                      fontWeight: "400",
                      border: "1px solid #636363",
                    }}
                  >
                    Go back
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleNext}
                    sx={{
                      mx: isMobile ? 1 : 2,
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* stepper form for mobile */}
              {isMobile && (
                <Box sx={{ fontSize: `${isMobile ? "12px !important" : ""}` }}>
                  <FormProvider {...methods}>
                    <form
                      id={`EvaluationValidationForm${index}`}
                      onSubmit={methods.handleSubmit(handleNext)}
                    >
                      <EvaluationForm
                        key={activeStep}
                        username={item[index]?.evaluatedUserInfo?.name}
                        // onSubmit={{ onSubmitForm }}
                        evaluationQuestion={evaluationQuestions[activeStep]}
                      />
                    </form>
                  </FormProvider>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                key={index}
              >
                {/* mobile back button */}
                {isMobile && (
                  <Button
                    sx={{
                      color: "#636363",
                      fontWeight: "400",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px",
                    }}
                    onClick={handleBack}
                    disabled={activeStep < 1}
                    disableRipple
                  >
                    <ArrowBackIosRoundedIcon
                      sx={{
                        width: "35px",
                        height: "35px",
                        border: `${activeStep < 0 ? "2px solid #c0c0c0" : "2px solid #636363"}`,
                        p: "8px",
                        borderRadius: "50px",
                        fontSize: "5px",
                        color: "inherit",
                        // marginBottom: "9px",
                      }}
                    />
                    {"Previous"}
                  </Button>
                )}
                {/* desktop back button */}
                {!isMobile && (
                  <Button
                    sx={{
                      color: "#636363",
                      fontWeight: "400",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={handleBack}
                    disabled={activeStep < 1}
                    disableRipple
                  >
                    <ArrowBackIosRoundedIcon
                      sx={{
                        width: "80px",
                        height: "80px",
                        border: "2px solid #636363",
                        p: "18px",
                        borderRadius: "50px",
                        fontSize: "45px",
                        color: "inherit",
                        marginBottom: "9px",
                      }}
                    />
                    {"Previous"}
                  </Button>
                )}
                <Box
                  // sx={{ minHeight: `${!isMobile && "230px"}` }}
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  {/* stepper form for desktop */}
                  {!isMobile && (
                    <FormProvider {...methods}>
                      <form
                        id={`EvaluationValidationForm${index}`}
                        onSubmit={methods.handleSubmit(handleNext)}
                      >
                        <EvaluationForm
                          key={activeStep}
                          username={capitalizeFirstLetter(item[index]?.evaluatedUserInfo?.name)}
                          // onSubmit={{ onSubmitForm }}
                          evaluationQuestion={evaluationQuestions[activeStep]}
                        />
                      </form>
                    </FormProvider>
                  )}
                </Box>
                {/* mobile submit button */}
                {isMobile && (
                  <Button
                    form={`EvaluationValidationForm${index}`}
                    key={index}
                    type="submit"
                    sx={{
                      color: "#2BB344",
                      fontWeight: "400",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px",
                    }}
                    disableRipple
                  >
                    <ArrowForwardIosRoundedIcon
                      sx={{
                        width: "35px",
                        height: "35px",
                        border: "2px solid #2BB344",
                        p: "8px",
                        borderRadius: "50px",
                        fontSize: "5px",
                        color: "inherit",
                        // marginBottom: "9px",
                      }}
                    />
                    {activeStep === evaluationQuestions.length - 1 ? "Submit" : "Next"}
                  </Button>
                )}
                {/* desktop submit button */}
                {!isMobile && (
                  <Button
                    type="submit"
                    key={index}
                    form={`EvaluationValidationForm${index}`}
                    sx={{
                      fontSize: "#2BB344",
                      fontWeight: "400",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    disabled={activeStep === evaluationQuestions.length}
                    disableRipple
                    onClick={() => setAnimate(!animate)}
                  >
                    <ArrowForwardIosRoundedIcon
                      sx={{
                        width: "80px",
                        height: "80px",
                        border: "2px solid #2BB344",
                        p: "18px",
                        borderRadius: "50px",
                        fontSize: "45px",
                        color: "inherit",
                        marginBottom: "9px",
                      }}
                    />
                    {activeStep === evaluationQuestions.length ? "Submit" : "Next"}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

export default EvaluationStepperForm;
