import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  FormLabel,
  Button,
  ButtonGroup,
  Grid,
  Avatar,
  Paper,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Chip,
  TableRow,
  TableCell,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  TablePagination,
  Modal,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Add, Delete, DeleteOutline } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ShareIcon from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import ShareOnSocial from "react-share-on-social";
import { useFormContext, Controller, useForm } from "react-hook-form";
import { getEmail } from "../utils/storage";
import validator from "validator";
import {
  deleteReferralUser,
  getReferralsReferralPage,
} from "../components/stepper_form/stepper.helper";
import * as levenshtein from "damerau-levenshtein";
import { useNavigate, Link } from "react-router-dom";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { useDispatch } from "react-redux";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Loading from "../components/UI/Loading";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import { WhatsappShareButton } from "react-share";
import ReferencesTable from "../components/common/ReferencesTable";
import MyFriendsTable from "../components/common/MyFriendsTable";
import Joyride from "react-joyride";
import zIndex from "@mui/material/styles/zIndex";
import Lottie from "react-lottie";
import referBy from "./referBy.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: referBy,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
import rewardCoin from "../components/common/LottieJson/rewardCoin.json";
import blankRewardCoin from "../components/common/LottieJson/blankRewardCoin.json";
import Rewards from "../components/common/LottieJson/reward-animation.json";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReferaFriend from "../components/common/ReferaFriend";
import FooterComponent from "../components/Footer";

// import { WhatsappIcon, FacebookIcon } from "react-share";

// import favicon from "../../public/assets/images/fire-icon.svg";

const MyFriends = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isMedium = useMediaQuery("(max-width:1200px)");
  const isTablet = useMediaQuery("(max-width:768px)");
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const [ReferralsField, setReferralsField] = useState([
    { name: "", email: "", emailValid: "blank" },
  ]);
  const [emailError, setEmailError] = useState("");
  const userEmail = getEmail();
  const referralsList = useSelector((state) => state.ReferralsReducer.referByUsers) || [];
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const Referral_Link_Description_write_up = useFeatureFlag("Referral_Link_Description_write_up");
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [run, setRun] = useState(false);
  const shareViaWhatsApp = useFeatureFlag("show_share_via_WhatsApp_references_tracking");

  // get referrals when page is load
  useEffect(() => {
    // const joyRide = setTimeout(() => {
    //   setRun(true);
    // }, 3000);
    getReferralsReferralPage(setLoading, dispatch);
  }, []);

  const [domains, setDomains] = useState([
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
    "jspinfotech.com",
  ]);

  // referral id generation
  const userRefId = useSelector((state) => state.userReducer.userRefId || "");
  const baseURL =
    process.env.REACT_APP_ENV === "dev"
      ? "https://master.d3hqz1cwjn0bmv.amplifyapp.com"
      : "https://techworth.trueselfy.com";
  const uniqueLink = `${baseURL}/signin?ref=${userRefId}&link=/endorse-expertise`;
  const WhatsAppUniqueLink = `${baseURL}/signin?ref=${userRefId}&link-type=whatsapp&link=/endorse-expertise`;
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(uniqueLink);
    toast.success("Copied");
  };

  // handlers for sharing links
  const handleShareWhatsApp = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(`https://wa.me/?text=${encodedLink}`);
  };

  const handleShareFacebook = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`);
  };

  const handleShareLinkedIn = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}`);
  };

  // get status according to reference status
  function getReferralsStatus(user) {
    switch (user.status) {
      case "success":
        return (
          <Chip
            size="small"
            sx={{
              fontSize: { xs: "12px", sm: "16px" },
              background: user?.successResultWithPayment > 0 ? "#B3DCB6" : "#9494eba3",
              color: user?.successResultWithPayment > 0 ? "#64A166" : "#3b3be9",
            }}
            label="Success"
          />
        );
      case "login":
        return (
          <Chip
            size="small"
            sx={{ background: "#F2CFD1", color: "#C94A51", fontSize: { xs: "12px", sm: "16px" } }}
            label="Login"
          />
        );

      case "pending":
        return (
          <Chip
            size="small"
            sx={{
              fontSize: { xs: "12px", sm: "16px" },

              background: "#FFEAB6",
              color: "#DBAC3D",
            }}
            label="Pending"
          />
        );

      default:
        return (
          <Chip
            size="small"
            sx={{
              fontSize: { xs: "12px", sm: "16px" },
              background: "#CFD0D3",
              color: "#696B70",
            }}
            label="Not logged in"
          />
        );
        break;
    }
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const usersTotal = [
    {
      count: referralsList.filter((item) => item.status === "success")?.length,
      text: "Completed Successfully",
      background: "#C3C3F4",
    },
    {
      count: referralsList.filter((item) => item.status === "pending")?.length,
      text: "Incomplete Attempts",
      background: "#FFEAB6",
    },
    {
      count: referralsList.filter((item) => item.status === "login")?.length,
      text: "Login Attempts",
      background: "#F2CFD1",
    },
  ];

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : panel === expanded ? null : panel);
  };

  const UserAccordion = (users) => {
    return (
      <Box
        sx={{
          maxWidth: "960px",
          margin: "0 auto",
        }}
      >
        {users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((user, index) => (
          <Accordion
            // expanded={false}
            key={index}
            expanded={isTablet ? expanded === index : false}
            onChange={handleChange(index)}
            sx={{
              borderRadius: "15px",
              "& .MuiAccordionSummary-expandIconWrapper": {
                display: "none",
              },
              "&:before": { display: "none" },
            }}
            elevation={0}
          >
            <AccordionSummary
              key={index}
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                borderRadius: "15px",
                boxShadow: " 0px 0px 8px 8px rgba(175, 175, 175, 0.25)",
                border: "0px",
                marginBottom: "18px",
                padding: "0px !important",
                // marginY: "-6px",
                "& .MuiAccordionSummary-content": {
                  margin: "1px 0px !important",
                },
              }}
            >
              <Grid container alignItems={"center"}>
                <Grid item xs={0.8}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "18px" } }}>
                    {page * rowsPerPage + index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={6.5}>
                  <Typography sx={{ fontSize: { xs: "14px", sm: "18px" } }} noWrap>
                    {user?.email}
                  </Typography>
                </Grid>
                <Grid item xs={2.2}>
                  <Typography>{getReferralsStatus(user)}</Typography>
                </Grid>

                <Grid item xs={2.5}>
                  {getRewardCoin(user)}
                </Grid>
              </Grid>
              {users.email}
            </AccordionSummary>
            <AccordionDetails key={index}>
              <Box
                sx={{
                  background: "#fff",
                  borderRadius: "30px",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "14px" } }}>Email</Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      width: "100%",
                      wordBreak: "break-word",
                      fontSize: { xs: "12px", sm: "16px" },
                    }}
                  >
                    {user.email}
                  </Typography>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  const totalRewards = (users) => {
    let total = 0;
    users.map((item) => {
      if (item?.successResultWithPayment > 0) {
        total = total + item?.successResultWithPayment;
      }
    });
    return total;
  };

  const getRewardCoin = (user) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: rewardCoin,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const disableCoin = {
      loop: false,
      autoplay: true,
      animationData: blankRewardCoin,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: { xs: 1, sm: 0 },
        }}
      >
        <Typography>
          {user?.successResultWithPayment > 0 ? (
            <Lottie
              options={defaultOptions}
              style={{ maxWidth: `${isMobile ? "35px" : "65px"}`, margin: "0px" }}
            />
          ) : (
            <Lottie
              options={disableCoin}
              style={{ maxWidth: `${isMobile ? "35px" : "65px"}`, margin: "0px" }}
            />
          )}
        </Typography>
        <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, pb: "6px" }}>
          ({user?.successResultWithPayment > 0 ? user?.successResultWithPayment : 0})
        </Typography>
      </Box>
    );
  };

  // reward modal components are here
  const [openReward, setOpenReward] = useState(false);
  const handleOpen = () => setOpenReward(true);
  const handleClose = () => setOpenReward(false);

  useEffect(() => {
    const totalRewardsCoin = totalRewards(referralsList) || 0;
    if (totalRewardsCoin > 0) {
      setOpenReward(true);
    }
  }, []);
  const RewardModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      background: "#fff",
      boxShadow: 24,
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      p: 4,
    };

    const reward = {
      loop: true,
      autoplay: true,
      animationData: Rewards,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Modal
        open={openReward}
        onClose={handleClose}
        // disableEscapeKeyDown
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style} gap={4}>
            <Box
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </Box>
            <Box>
              <Lottie options={reward} style={{ maxWidth: `290px`, margin: "0px" }} />
            </Box>
            <Box>
              <Typography sx={{ textAlign: "center", fontWeight: 600 }} variant={"h3"}>
                Congratulations
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ textAlign: "center" }}>
                You've earned a total of{" "}
                <span style={{ fontSize: "22px", fontWeight: 600 }}>
                  {totalRewards(referralsList)}
                </span>{" "}
                Coins! Keep share and continue collecting more rewards!
              </Typography>
            </Box>
            <Box>
              <ReferaFriend className={"refer-a-friend"} text={"Share"} />
            </Box>
          </Box>
        </>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Header />
          {/* <Joyride
            steps={JoyrideSteps}
            run={run}
            showSkipButton
            continuous
            disableCloseOnEsc
            disableOverlayClose
            disableScrolling
          /> */}
          <RewardModal />

          <Grid container justifyContent="center" sx={{ mt: 8 }}>
            <Grid item xs={12} lg={5} xl={5}>
              <Box
                sx={{
                  maxWidth: "829px",
                  height: "auto",
                  overflow: "hidden",
                  display: "block",
                  margin: "0px auto",
                  width: "100%",
                }}
              >
                <Lottie
                  options={defaultOptions}
                  style={{ maxWidth: `${isMobile ? "70%" : "100%"}` }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={7} xl={7}>
              <Box sx={{ p: { xs: 1.5, md: 3 }, textAlign: { xs: "center", md: "center" } }}>
                <Box>
                  <Box
                    className={"provide-references"}
                    sx={{
                      pb: 2,
                      pt: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "40px",
                        fontWeight: 600,
                        textAlign: { xs: "center", md: "center" },
                        mb: 2,
                      }}
                      color="primary"
                    >
                      My Friends
                    </Typography>
                  </Box>
                </Box>
                {/* <Box className={"share-link-box"}>
                  <Typography sx={{ fontSize: "18px", fontWeight: 600, mt: "25px" }}>
                    Share link
                  </Typography>
                  <Typography sx={{ color: "#888", mb: "12px" }}>
                    Share this link so they can add the necessary details to endorse your expertise.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: { xs: "wrap", md: "nowrap", lg: "nowrap", xl: "nowrap" },
                      gap: { xs: 2, xl: 2 },
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 5,
                    }}
                  >
                    <TextField
                      fullWidth
                      type="text"
                      value={uniqueLink}
                      // size="small"
                      InputProps={{
                        readOnly: "true",
                        sx: { color: "#8D8D99" },
                      }}
                    />

                    <Box sx={{ display: "flex", gap: { xs: 2, xl: 2 }, alignItems: "center" }}>
                      <Button variant="outlined" color="primary" onClick={handleCopyToClipboard}>
                        {" "}
                        Copy
                      </Button>
                      <RWebShare
                        data={{
                          text: Referral_Link_Description_write_up,
                          url: uniqueLink,
                          title:
                            "Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise",
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <Button variant="contained" color="primary">
                          Share
                        </Button>
                      </RWebShare>{" "}
                    </Box>
                  </Box>
                </Box> */}
                {referralsList?.length === 0 || undefined ? (
                  <Box
                    sx={{
                      maxWidth: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      // backgroundColor: "#DBDBDB",
                      // border: "2px solid grey",
                      // borderRadius: "15px",
                    }}
                    p={{ xs: 5, md: 10, lg: 10 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`../../../../assets/images/empty-list.svg`}
                        alt="empty list"
                        style={{
                          maxWidth: `${isMobile ? "50%" : "100%"}`,
                        }}
                        loading="lazy"
                      />
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          color: "#232323",
                          textAlign: "center",
                        }}
                        variant="h2"
                      >
                        No Friends Found
                      </Typography>
                      <Typography pt={4} textAlign={"center"}>
                        Invite Friends and Earn Coins
                      </Typography>
                    </Box>
                    <ReferaFriend className={"refer-a-friend"} text={"Share"} />

                    {/* <Box pt={3}>
                      <Button
                        // display={
                        //   location.pathname === "/skills-calculation" ? false : true
                        // }
                        sx={{ px: `${isMobile ? "10px" : "25px"}` }}
                        variant="contained"
                        onClick={() => navigate("/skills-calculation")}
                      >
                        {CalculateButtonValue}
                      </Button>
                    </Box> */}
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        overflow: "scroll",
                        scrollbarWidth: "none",
                        flexWrap: {
                          xs: "nowrap",
                          sm: "nowrap",
                          md: "wrap",
                          lg: "warp",
                          xl: "wrap",
                        },
                        justifyContent: {
                          xs: "unset",
                          sm: "unset",
                          md: "center",
                          lg: "center",
                          xl: "center",
                        },
                        py: 2,
                        // maxWidth: "960px",
                      }}
                      gap={2}
                    >
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          // alignItems: "center",
                          backgroundColor: "#B3DCB6",
                          // width: "225px",
                          minWidth: "225px",
                          borderRadius: "10px",
                          textAlign: "left",
                        }}
                        gap={1}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant={"h4"} sx={{ fontWeight: 600 }}>
                            {totalRewards(referralsList)}
                          </Typography>

                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems:"center"
                          }}
                        >
                          <Typography variant="p" sx={{ fontSize: "18px", color: "#636363" }}>
                            Coins Earned
                          </Typography>
                          <Box>
                            <img
                              src={"../../assets/images/reward-badge.svg"}
                              style={{ maxWidth: "35px" }}
                              alt={"rewards"}
                              loading="lazy"
                            />
                          </Box>
                        </Box>
                      </Box>
                      {usersTotal?.map((item) => (
                        <Box
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            backgroundColor: item?.background,
                            // width: "225px",
                            minWidth: "225px",
                            borderRadius: "10px",
                            textAlign: "left",
                          }}
                          gap={1}
                        >
                          <Box>
                            <Typography variant={"h4"} sx={{ fontWeight: 600 }}>
                              {item?.count}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="p" sx={{ fontSize: "18px", color: "#636363" }}>
                              {item?.text}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    <Box sx={{ pt: 4 }}>{UserAccordion(referralsList)}</Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={referralsList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Box>
                  </Box>
                )}

                <>
                  {/* <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      mb: { xs: 1, md: 2 },
                    }}
                  >
                    
                  </Typography> */}

                  {/* <Box
                    sx={{
                      pb: 2.5,
                    }}
                  >
                    <Chip
                      size="small"
                      sx={{ background: "#F2CFD1", color: "#C94A51" }}
                      label={`Login (${
                        referralsList.filter((item) => item?.status === "login")?.length
                      })`}
                    />
                    <Chip
                      size="small"
                      sx={{ background: "#FFEAB6", color: "#DBAC3D" }}
                      label={`Pending (${
                        referralsList.filter((item) => item?.status === "pending")?.length
                      })`}
                    />
                    <Chip
                      size="small"
                      sx={{ background: "#9494eba3", color: "#3b3be9", marginRight: "5px" }}
                      label={`Success  (${
                        referralsList.filter((item) => item?.status === "success")?.length
                      })`}
                    />
                    <Chip
                      size="small"
                      sx={{ background: "#B3DCB6", color: "#64A166", marginRight: "5px" }}
                      label={`Success  (${
                        referralsList.filter((item) => item?.status === "successpayment")?.length
                      })`}
                    />
                  </Box> */}

                  {/* {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
                      <CircularProgress />
                    </Box>
                  ) : referralsList?.length === 0 || undefined ? (
                    <Box
                      sx={{
                        maxWidth: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        // backgroundColor: "#DBDBDB",
                        // border: "2px solid grey",
                        // borderRadius: "15px",
                      }}
                      p={{ xs: 5, md: 10, lg: 10 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={`../../../../assets/images/empty-list.svg`}
                          alt="empty list"
                          style={{
                            maxWidth: `${isMobile ? "50%" : "100%"}`,
                          }}
                        />
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            color: "#232323",
                            textAlign: "center",
                          }}
                          variant="h2"
                        >
                          No references
                        </Typography>
                        <Typography pt={4} textAlign={"center"}>
                          Why don’t you calculate your TechWorth through Trueselfy?
                        </Typography>
                      </Box>
                      <Box pt={3}>
                        <Button
                          // display={
                          //   location.pathname === "/skills-calculation" ? false : true
                          // }
                          sx={{ px: `${isMobile ? "10px" : "25px"}` }}
                          variant="contained"
                          onClick={() => navigate("/skills-calculation")}
                        >
                          {CalculateButtonValue}
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <MyFriendsTable referralsList={referralsList} />
                  )} */}
                </>

                {/* </Box> */}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              position: {
                xl: "fixed",
                lg: "unset",
                sm: "unset",
                xs: "unset",
                md: "unset",
                xl: "fixed",
              },
              bottom: "0px",
              width: "100%",
            }}
          >
            <FooterComponent />
          </Box>
          {/* <Box sx={{ position: "fixed" }}>
            <Footer />
          </Box> */}
        </Box>
      )}

      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMsg={snackbarMsg}
          snackbarSeverity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
};

export default MyFriends;
