import React, { useEffect, useState } from "react";
import EvaluationTextQuestion from "./EvaluationTextQuestion";
import EvaluationRadioQuestion from "./EvaluationRadioQuestion";
import EvaluationCheckboxQuestion from "./EvaluationCheckboxQuestion";
import MultiSelectQuestionComponent from "../evaluation/EvaluationMultiSelectQuestionComponent";
import SelectQuestionComponent from "../stepper_form/question-type-components/SelectQuestionComponent";
import NumberQuestionComponent from "../stepper_form/question-type-components/NumberQuestionComponent";
import EvaluationSelectQuestion from "./EvaluationSelectQuestion";
import EvaluationMultiSelectQuestionComponent from "../evaluation/EvaluationMultiSelectQuestionComponent";
function EvaluationForm({ evaluationQuestion, username }) {
  if (!evaluationQuestion?.hideQuestion) {
    switch (evaluationQuestion?.type) {
      case "text":
        return (
          <EvaluationTextQuestion
            question={evaluationQuestion}
            username={username}
          />
        );
      case "number":
        return (
          <NumberQuestionComponent
            question={evaluationQuestion}
            username={username}
          />
        );
      case "radio":
        return (
          <EvaluationRadioQuestion
            // option={evaluationOption[0].radio}
            question={evaluationQuestion}
            username={username}
          />
        );
      case "checkbox":
        return (
          <EvaluationCheckboxQuestion
            // option={evaluationOption[0].checkbox}
            question={evaluationQuestion}
            username={username}
          />
        );
      case "select":
        return (
          <EvaluationSelectQuestion
            question={evaluationQuestion}
            username={username}
          />
        );
      case "multiSelect":
        return (
          <EvaluationMultiSelectQuestionComponent
            question={evaluationQuestion}
            username={username}
          />
        );
      default:
        break;
    }
  }
  // return "hello";
}

export default EvaluationForm;
