import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function PrimarySkillRelevanceAI({ relevanceResult }) {
  const IncognitoModeStatus = useSelector((state) => state.IncognitoMode?.IncognitoUser || false);

  function checkYear(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (year > currentYear) {
      const labelSuggestion = (
        <Typography
          sx={{
            color: "#232323",
            textAlign: "center",
          }}
          variant="h6"
        >
          <span
            style={{
              // fontSize: "20px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#26801E",
            }}
          >
            • Trending{" "}
          </span>{" "}
          : You are in demand, but keep upgrading
        </Typography>
      );
      return {
        label: `Technical skill trending and in demand until ${year}`,
        barPrimaryColor: "green",
        barSecondaryColor: "#A5D6A7",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearForCurrentSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontSize: "20px",
            color: "#232323",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "darkorange",
            }}
          >
            • Current
          </span>{" "}
          <spna>: Your tech is current, but it is getting outdated fast</spna>
        </Typography>
      );
      return {
        label: `Skill in demand until ${year}`,
        barPrimaryColor: "darkorange",
        barSecondaryColor: "#ffcc80",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontSize: "20px",
            color: "#232323",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#FF9D09",
            }}
          >
            • Getting outdated
          </span>{" "}
          <spna>: Upskill soon to stay ahead in the tech industry</spna>
        </Typography>
      );
      return {
        label: `Technical skill getting outdated fast ${year}`,
        barPrimaryColor: "#ffc107",
        barSecondaryColor: "#fff59d",
        labelSuggestion,
      };
    } else if (year <= currentYear - minYearDiff) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontSize: "24px",
            color: "#232323",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "red",
            }}
          >
            • Obsolete
          </span>{" "}
          : Please learn new age Tech/Management skills now
        </Typography>
      );
      return {
        label: `Technical skill obselete ${year}`,
        barPrimaryColor: "red",
        barSecondaryColor: "#FFCDD2",
        labelSuggestion,
      };
    }
  }
  function getTopSkill(skillArray) {
    let maxExpiryYear = -Infinity;
    let maxExpiryYearObject = null;

    // Iterate through the array
    for (const obj of skillArray) {
      if (obj.expiryYear > maxExpiryYear) {
        maxExpiryYear = obj.expiryYear;
        maxExpiryYearObject = obj;
      }
    }
    const topSkillSuggestion = checkYear(maxExpiryYearObject?.expiryYear);

    return {
      maxExpiryYear,
      labelSuggestion: topSkillSuggestion?.labelSuggestion,
      maxExpiryYearObject,
    };
  }
  return (
    <React.Fragment>
      {/* <Grid container spacing={{ xs: 2, md: 3 }}> */}
      <Box
        sx={{
          border: "0.5px solid #26801E",
          borderRadius: "5px",
          background: IncognitoModeStatus ? "#c1c1c1" : "#EDFFF0",
          p: 3,
          my: 2,
        }}
      >
        <Typography
          color="primary"
          sx={{
            textAlign: "center",
            fontWeight: 600,
            mb: 1,
          }}
          variant="h6"
        >
          Your top skill's{" "}
          <span style={{ fontWeight: 700, color: "rgb(23 195 61)" }}>
            {relevanceResult[0]?.name}
          </span>{" "}
          validity is until
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "262655",
            // mb:1,
            fontSize: "35px",
            fontWeight: 700,
          }}
        >
          {getTopSkill(relevanceResult)?.maxExpiryYear}
        </Typography>
        <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
          (approximately)
        </Typography>
        {getTopSkill(relevanceResult)?.labelSuggestion}
      </Box>
      {/* </Grid> */}
    </React.Fragment>
  );
}

export default PrimarySkillRelevanceAI;
