import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

function ReassessTechValue() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const valueFluctuations = [
    { text: "New job / New type of company", img: "01.png" },
    { text: "Inflation / Recession", img: "02.png" },
    { text: "Years of experience changed", img: "03.png" },
    { text: "Changed Market Conditions", img: "04.png" },
    { text: "Added expertise Certifications", img: "05.png" },
    { text: "Business Model Disruption", img: "06.png" },
    { text: "New technology / New domain / New responsibility", img: "07.png" },
    { text: "Tech Obsolescence / Stagnation", img: "08.png" },
    {
      text: "Leadership Management role",
      img: "09.png",
    },
    {
      text: "Learnt to design complex systems",
      img: "10.png",
    },
    { text: "Acquired new / latest / HOT skills", img: "11.png" },
    {
      text: "Started using productivity tools / AI tools",
      img: "12.png",
    },
    {
      text: "Moved location / New city",
      img: "13.png",
    },
    {
      text: "Colleagues endorsed my expertise",
      img: "14.png",
    },
    {
      text: "Contract to Full time",
      img: "15.png",
    },
  ];

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid
          sx={{
            // border: "1.5px solid #E9E9E9",
            // boxShadow: "0px 0px 4px 0px #CDCAC073",
            // borderRadius: "20px",
            background: "#fff",
            boxSizing: "inherit",
            mt: { xs: 12, sm: 12, md: 14, lg: 14 },
            mb: { xs: "20px", sm: "20px", md: "20px", lg: "30px" },
            mx: {
              xs: "20px",
              sm: "50px",
              md: "50px",
              lg: "120px",
            },
          }}
          className="gradient-border-mask"
        >
          <Box>
            <Box textAlign="center">
              <Typography
                sx={{
                  fontSize: `${isMobile ? "15px !important" : "24px"}`,
                  fontWeight: "500",
                  backgroundImage:
                    "linear-gradient(to right, #0BA2FF, #E0011C, #FFC218, #2BB344)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Your value fluctuates like stock each day.
              </Typography>
              <Typography
                sx={{
                  fontSize: `${isMobile ? "15px !important" : "24px"}`,
                  fontWeight: "500",
                  backgroundImage:
                    "linear-gradient(to right, #0BA2FF, #E0011C, #FFC218, #2BB344)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                People will/should return under following circumstances.
              </Typography>
              <Typography
                sx={{
                  fontSize: `${isMobile ? "15px !important" : "24px"}`,
                  fontWeight: "500",
                  backgroundImage:
                    "linear-gradient(to right, #0BA2FF, #E0011C, #FFC218, #2BB344)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Stay Ahead Assess TechValue every 2-3 months.
              </Typography>
            </Box>

            <Grid container justifyContent="center">
              <img
                src="../../assets/images/reassess-techvalue/reassess_techvalue_animation.gif"
                alt="Value fluctuation graph"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          spacing={1.5}
          // sx={{ mx: "10px !important" }}
          textAlign={{ xs: "center", md: "center", lg: "center" }}
          // alignItems="center"
          sx={{
            // mt: { xs: 12, sm: 12, md: 14, lg: 14 },
            mb: { xs: "20px", sm: "20px", md: "20px", lg: "30px" },
            mx: {
              xs: "3px",
              sm: "50px",
              md: "50px",
              lg: "120px",
            },
          }}
        >
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              // border: "2.5px solid #2BB344",
              borderRadius: "12px",
            }}
          >
            {valueFluctuations?.map((item, index) => (
              <Grid item xs={6} lg={2} md={6} key={index}>
                <Box
                  sx={{
                    padding: { xs: "8px !important", lg: "8px !important" },
                  }}
                >
                  <Box
                    sx={{
                      padding: "0px !important",
                      display: "flex",
                      flexDirection: "column !important",
                      border: "2px solid transparent",
                      borderRadius: "26px",
                      // Apply gradient border color

                      alignItems: "center",
                      minHeight: "176px",
                      justifyContent: "center",
                    }}
                    className="gradient-border-mask"
                  >
                    <Box sx={{ p: 2 }}>
                      <img
                        src={`../../assets/images/reassess-techvalue/${item.img}`}
                        style={{ maxWidth: "55px", height: "100%" }}
                        alt={item.text}
                        loading="lazy"
                      />
                    </Box>
                    <Box sx={{ p: "5px" }}>
                      <Typography
                        sx={{
                          color: "#232323",
                          fontSize: `${isMobile ? "14px !important" : "16px"}`,
                          maxWidth: "175px",
                        }}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ReassessTechValue;
