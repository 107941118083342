import React, { useRef } from "react";
import { Box, DialogContent, Dialog, Typography, IconButton, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import html2pdf from "html2pdf.js";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const coverLetterStyles = {
  root: {
    padding: "0px",
  },

  heading: {
    fontSize: 22,
    fontWeight: 600,
    margin: 0,
    fontFamily: "Poppins, sans-serif",
  },
  subheading: {
    fontSize: 14,
    fontWeight: 400,
    color: "#5c5c5c",
    margin: 0,
    fontFamily: "Poppins, sans-serif",
    marginBottom: 20,
  },
  paragraph: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    fontFamily: "Poppins, sans-serif",
    marginBottom: 20,
    width: "100%",
    border: "0px",
    minHeight: "inherit",
    height: "100%",
    outline: "0px !important",
    background: "transparent",
    outline: "none !important",
    "&:focus-visible": {
      outline: "none !important",
    },
  },
};

const CoverLetter = ({ open, setOpen, data }) => {
  const coverLetterRef = useRef(null);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBackdropClick = () => {
    // console.log("Backdrop clicked!");
    setOpen(true);

    // You can add custom logic here if needed
  };
  const handleClick = () => {
    toast.success("Copied");
  };
  // function for generate pdf from html

  const handleDownloadPDF = () => {
    const content = coverLetterRef.current;

    if (content) {
      const pdfOptions = {
        margin: 10,
        filename: "cover_letter.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf()
        .from(content)
        .set(pdfOptions)
        .outputPdf((pdf) => {
          const blob = new Blob([pdf], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "cover_letter.pdf";
          link.click();
        });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        // maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        disableEscapeKeyDown
        scroll="body"
        sx={{ width: "100%" }}
        PaperProps={{
          sx: { maxWidth: "650px", width: "100%", overflow: "visible", position: "relative" },
        }}
      >
        <DialogContent style={coverLetterStyles.root}>
          <Box
            ref={coverLetterRef}
            sx={{
              // width: "100%",
              margin: "0 auto",
              background:
                'url("../../assets/images/cover-letter-top-img.png"), url("../../assets/images/cover-letter-bottom-img.png")',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center, bottom center",
              backgroundSize: "contain",
              py: { xs: "5rem", sm: "6rem", lg: "7rem" },
              minHeight: "380px",
              height: "100%",
            }}
          >
            {/* <Box
              onClick={() => setOpen(false)}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <HighlightOffIcon />
            </Box> */}
            <IconButton
              onClick={() => setOpen(false)}
              size="small"
              sx={{
                position: "absolute",
                top: "-12px",
                right: "-12px",
                zIndex: 99,
                background: "#FFF",
                "&:hover": {
                  background: "#FFF",
                },
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
            <Box
              sx={{
                // padding: { xs: "1rem 0.5rem", sm: "3rem 2rem", sm: "3rem 2rem", lg: "3rem 2rem" },
                // maxWidth: 550,
                // width: "100%",
                px: { xs: "1rem", sm: "2rem", lg: "3rem" },
                // overflowY: "auto",
                minHeight: "inherit",
                height: "100%",
                // maxHeight: "530px",
                // zIndex: -1,
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}
              >
                <Box>
                  <Typography variant="h2" style={coverLetterStyles.heading}>
                    {data?.userData?.name || data?.name || data?.userForm?.name}
                  </Typography>
                  <Typography variant="subtitle" style={coverLetterStyles.subheading}>
                    {data?.userData?.role || data?.role || data?.userForm?.role}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: { xs: "absolute", md: "relative" },
                    left: { xs: "220px", md: "-30px" },
                    top: { xs: "45px", md: "20px" },
                  }}
                >
                  <CopyToClipboard text={data?.coverLetter}>
                    <Box sx={{ display: "flex", alignItems: "flex-start" }} onClick={handleClick}>
                      {" "}
                      <Typography sx={{ fontSize: "14px" }}>Copy</Typography>
                      <ContentCopyOutlinedIcon
                        // onClick={handleClick}
                        sx={{
                          cursor: "pointer",
                          // pb: 1,
                          pl: "4px",
                          fontSize: "18px",
                          color: "inherit",
                          // position: "absolute",
                          // zIndex: 1,
                          // top: { xs: "80px", lg: "84px" },
                          // right: { xs: "30px", lg: "54px" },
                        }}
                      />
                    </Box>
                  </CopyToClipboard>
                </Box>
              </Box>

              <textarea
                style={coverLetterStyles.paragraph}
                value={data?.coverLetter}
                className="cover-textarea"
                resize="none"
              />
            </Box>
            {/* <Button onClick={handleDownloadPDF} variant="contained" color="primary">
              Download PDF
            </Button> */}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Trigger the dialog with a button or any other user action */}
      {/* <button onClick={handleOpen}>Open Cover Letter</button> */}
    </>
  );
};

export default CoverLetter;
