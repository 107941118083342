import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import apis from "../utils/apis";
import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Autocomplete,
  Button,
  Chip,
  useMediaQuery,
  List,
  ListItem,
  LinearProgress,
  ListItemText,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
// import SwiperSlider from "../components/common/Swiper";
import { getOptions } from "../components/stepper_form/stepper.helper";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import axios from "axios";
import _ from "lodash";
// import Chart from "react-apexcharts";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenAI from "openai";
import { DataGrid } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Helmet } from "react-helmet";

function getFirstWord(inputString) {
  const words = inputString.split(" ");
  if (words.length > 0) {
    return words[0];
  } else {
    return ""; // Return an empty string if there are no words in the input
  }
}

const rows = [
  {
    name: "React",
    popularity: 8,
    learningEase: "Moderate",
  },
];

function TechRelevance() {
  const methods = useForm({ criteriaMode: "all" });
  const [UserName, setUserName] = useState("");
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [relevanceResult, setRelevanceResult] = useState("");
  const [formdata, setFormdata] = useState("");
  const [chatGptButton, setChatGptButton] = useState(false);
  const [checkSkill, setCheckSkill] = useState("");
  const initialData = { json: "", message: "" };
  const [chatGPTData, setChatGPTData] = useState(initialData);
  const [tableRows, setTableRows] = useState("");
  const [topskillDetails, setTopSkillDetails] = useState("");
  const staticRandomEncodeDecodeString = useFeatureFlag(
    "staticRandomEncodeDecodeString"
  );
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const Chat_GPT_Boost = useFeatureFlag("Chat_GPT_Boost");
  const poweredByChatGPT = useFeatureFlag("poweredByChatGPT");
  const [newtechnology, setNewtechnology] = useState(false);
  function checkSkillType(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    if (year >= currentYear) {
      return `Skill trending and in demand until ${year}`;
    } else if (year >= currentYear - minYearDiff) {
      return `Skill getting outdated fast ${year}`;
    } else if (year < currentYear - minYearDiff) {
      return `Skill obselete ${year}`;
    }
  }

  const openai = new OpenAI({
    apiKey: "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", // defaults to process.env["OPENAI_API_KEY"]
    dangerouslyAllowBrowser: true,
  });

  const messages = [
    "Hello, how can I assist you?",
    "I can provide information and answer questions.",
    "Just type your query, and I will do my best to help!",
  ];

  function checkYear(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    if (year >= currentYear) {
      const labelSuggestion = (
        <Typography variant="body1">
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "green",
            }}
          >
            Trending{" "}
          </span>{" "}
          : You are in demand, but keep upgrading
        </Typography>
      );
      return {
        label: `Technical skill trending and in demand until ${year}`,
        barPrimaryColor: "green",
        barSecondaryColor: "#A5D6A7",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearDiff) {
      const labelSuggestion = (
        <Typography variant="body1">
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "#ffc107",
            }}
          >
            • Getting outdated
          </span>{" "}
          <spna>: Upskill soon to stay ahead in the tech industry</spna>
        </Typography>
      );
      return {
        label: `Technical skill getting outdated fast ${year}`,
        barPrimaryColor: "#ffc107",
        barSecondaryColor: "#fff59d",
        labelSuggestion,
      };
    } else if (year < currentYear - minYearDiff) {
      const labelSuggestion = (
        <Typography variant="body1">
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "red",
            }}
          >
            • Obsolete
          </span>{" "}
          : Please learn new age skills now
        </Typography>
      );
      return {
        label: `Technical skill obselete ${year}`,
        barPrimaryColor: "red",
        barSecondaryColor: "#FFCDD2",
        labelSuggestion,
      };
    }
  }

  function getTopSkill(skillArray) {
    let maxExpiryYear = -Infinity;
    let maxExpiryYearObject = null;

    // Iterate through the array
    for (const obj of skillArray) {
      if (obj.expiryYear > maxExpiryYear) {
        maxExpiryYear = obj.expiryYear;
        maxExpiryYearObject = obj;
      }
    }
    const topSkillSuggestion = checkYear(maxExpiryYearObject?.expiryYear);

    return {
      maxExpiryYear,
      labelSuggestion: topSkillSuggestion?.labelSuggestion,
      maxExpiryYearObject,
    };
  }

  // Data Encryption function
  function DataEncryption(json) {
    const jsonString = JSON.stringify(json);
    const customStringJson =
      secretEncodeDecodeString + jsonString + secretEncodeDecodeString;
    const newCustomStringJson = customStringJson.replace(
      /"/g,
      staticRandomEncodeDecodeString
    );
    const encodedString = btoa(newCustomStringJson);
    return encodedString;
  }
  function DataDecryption(jsonString) {
    const decodedString = atob(jsonString);
    const getOldStringOne = decodedString.replace(
      new RegExp(secretEncodeDecodeString, "g"),
      ""
    );
    const str = staticRandomEncodeDecodeString;
    const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedString, "g");
    const getOldStringTwo = getOldStringOne.replace(regex, '"');
    const json = JSON.parse(getOldStringTwo);
    return json;
  }

  const onSubmit = (data) => {
    setLoadingButton(true);
    setUserName(data?.name);
    setFormdata(data);
    setTableRows("");
    setCheckSkill("");
    setTopSkillDetails("");
    // console.log("formdata", data);
    try {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/get-relevanceResult",
          DataEncryption(data),
          {
            options: {
              type: "json",
            },
          }
        )
        .then((object) => {
          const data = DataDecryption(object.data);
          setRelevanceResult(data);
          // console.log(data, "data");
          setLoadingButton(false);

          // methods.reset("");
          // setValue("skills", "");
        })
        .catch((error) => {
          if (
            error?.response?.status === 500 &&
            error?.response?.data?.message === "Email verification failed"
          ) {
            methods.setError("email", {
              type: "validation",
              message: "Email not valid !!",
            });
            setRelevanceResult("");
          }

          setLoadingButton(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingButton(true);
    }
  };

  const handleSendClick = async (name) => {
    setTableRows("");
    setCheckSkill(name);

    const skill = getTopSkill(relevanceResult?.topPrimarySkill);
    const content = ` make a response for ${name} in jSON format and  how popular is it now (assign it a populairty rating on scale of 1 to 10 based on worldwide usage) in the world?,  how many percentage uses in current industry, What latest / futuristic technologies are for ${name}
    in  composite value of ascending order of ease of learning and descending order of payscales?
    Make it in a json format with popularity rating scale of 1-10 and potential increase in salary in % terms ,make sure all content in json format and remove explanation and make sure object have percentageUsage, popularityRating, latestTechnologies key for object and latestTechnologies object have name, easeOfLearning,potentialSalaryIncreasePercentage keys
    `;
    try {
      setChatGptButton(true);

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/get-chatgpt-response",
          DataEncryption({ content: content }),
          {
            options: {
              type: "json",
            },
          }
        )
        .then((object) => {
          setChatGptButton(false);

          const data = DataDecryption(object.data);
          let gptdata = JSON.parse(data?.result);
          setChatGPTData(gptdata);
          // console.log(gptdata, "backend-data");

          // methods.reset("");
          // setValue("skills", "");
        })
        .catch((error) => {
          setChatGptButton(false);
        });
    } catch (error) {
      setChatGptButton(false);

      console.log("error", error);
    }
    // try {
    //   setChatGptButton(true);
    //   setCheckSkill(name);
    //   // Mock API response (replace with actual API call)
    //   const completion = await openai.chat.completions.create({
    //     messages: [
    //       {
    //         role: "user",
    //         content: `
    //         make a response for ${name} in jSON format and  how popular is it now (assign it a populairty rating on scale of 1 to 10 based on worldwide usage) in the world?,  how many percentage uses in current industry, What latest / futuristic technologies are for ${name}
    //         in  composite value of ascending order of ease of learning and descending order of payscales?
    //         Make it in a json format with popularity rating scale of 1-10 and potential increase in salary in % terms ,make sure all content in json format and remove explanation and make sure object have percentageUsage, popularityRating, latestTechnologies key for object and latestTechnologies object have name, easeOfLearning,potentialSalaryIncreasePercentage keys
    //         `,
    //       },
    //     ],
    //     model: "gpt-3.5-turbo",
    //   });
    //   setChatGptButton(false);

    //   console.log("Response", completion.choices[0]?.message?.content);
    //   setChatGPTData(JSON.parse(completion.choices[0]?.message?.content));
    //   // setChatGPTData({
    //   //   ...chatGPTData,
    //   //   json: completion.choices[0]?.message?.content,
    //   //   message: completion.choices[0]?.message?.content,
    //   // });
    //   // setTableRows(data);
    //   // setResponseText(response.reply);
    // } catch (error) {
    //   setChatGptButton(false);
    //   console.error("Error calling GPT-3.5 API:", error);
    // }
  };

  const getTopSkillDetails = async () => {
    setTopSkillDetails("");
    const skill = getTopSkill(relevanceResult?.topPrimarySkill);
    try {
      setNewtechnology(true);
      // Mock API response (replace with actual API call)
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "user",
            content: `            
            make a response for  ${skill?.maxExpiryYearObject?.name} in jSON format and  how popular is it now (assign it a populairty rating on scale of 1 to 10 based on worldwide usage) in the world?,  how many percentage uses in current industry, which task commonly perform using this skill. make sure all content in json format and remove explanation and make sure object have percentageUsage,popularityRating,commonTasks key for object  `,
          },
        ],
        model: "gpt-3.5-turbo",
      });
      setNewtechnology(false);
      const data = JSON.parse(completion.choices[0]?.message?.content);
      // console.log("Response", JSON.parse(completion.choices[0]?.message?.content));
      setTopSkillDetails(data);
      // setResponseText(response.reply);
    } catch (error) {
      setNewtechnology(false);
      console.error("Error calling GPT-3.5 API:", error);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, [relevanceResult, tableRows]);
  const isMobile = useMediaQuery("(max-width:600px)");

  function TechRelevanceForm() {
    const {
      control,
      setValue,
      getValues,
      formState: { errors },
    } = useFormContext();
    const [open, setOpen] = useState(false); // if dropdown open?
    const [autoValue, setAutoValue] = useState(getValues("primarySkill") || []); // if dropdown open?
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noOptions, setNoOptions] = useState(false);

    const filter = createFilterOptions({
      stringify: (option) => option.name,
    });
    const isMobile = useMediaQuery("(max-width:600px)");
    const pageTitle =
      "Professionals Tech Innovation Software India | TrueSelfy Tech Relevance";
    const pageDescription =
      "Unlock the Future with TrueSelfy's Tech Innovation in India! Explore Top Tech Software for Professionals. Dive into Tech Relevance Today!";
    // useEffect(() => {
    //   setError("email", {
    //     type: "validation",
    //     message: "Email not valid !!",
    //   });
    // }, [setEmailError]);

    useEffect(() => {
      setValue("primarySkill", autoValue);
    }, [autoValue]);
    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
        </Helmet>
        <Box sx={{ width: `${isMobile ? "100%" : "70%"}` }} py={1}>
          <Typography sx={{ fontSize: "24px" }}>Name</Typography>
          <Controller
            control={control}
            name={"name"}
            sx={{
              "& .Mui-focused": {
                color: "#0391E8 !important",
              },
            }}
            render={({ field }) => (
              <TextField
                id="name"
                sx={{
                  // borderRadius: "50px",
                  // "& .MuiOutlinedInput-root": {
                  //   borderRadius: "50px !important",
                  // },
                  "&.MuiOutlinedInput-root": {
                    borderColor: "#0391E8 !important",
                  },
                }}
                {...field}
                fullWidth
                required
                label={"Enter your Name"}
                variant="outlined"
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <img
                //         src={"../../assets/images/forward-icon.svg"}
                //         alt="icon"
                //       />
                //     </InputAdornment>
                //   ),
                // }}
              />
            )}
          />
        </Box>
        <Box sx={{ width: `${isMobile ? "100%" : "70%"}` }} py={1}>
          <Typography sx={{ fontSize: "24px" }}>E-mail</Typography>
          <Controller
            control={control}
            name={"email"}
            rules={{
              required: "Email Address is required",
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message:
                  "Oh no, that doesn't look like a valid email! Please double-check and try again.",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors?.email ? true : false}
                // sx={{
                //   borderRadius: "50px",
                //   "& .MuiOutlinedInput-root": {
                //     borderRadius: "50px !important",
                //   },
                // }}
                id="email"
                label={"Enter your Email"}
                fullWidth
                required
                type="text"
                helperText={
                  <>
                    {errors?.email && errors?.email.message}
                    {errors?.email && errors.type === "validation"
                      ? errors?.email?.message
                      : ""}
                  </>
                }

                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <img
                //         src={"../../assets/images/forward-icon.svg"}
                //         alt="icon"
                //       />
                //     </InputAdornment>
                //   ),
                // }}
              />
            )}
          />
        </Box>
        <Box sx={{ width: `${isMobile ? "100%" : "70%"}` }} py={1}>
          <Typography sx={{ fontSize: "24px" }}>Skills</Typography>
          <Controller
            name={"primarySkill"}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                noOptionsText={
                  noOptions ? "No options found" : "Start typing to get options"
                }
                {...field}
                open={open}
                onOpen={() => {
                  setNoOptions(false);
                  setOpen(true);
                  setData([]);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                loading={loading}
                multiple
                autoComplete={true}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                value={autoValue}
                onChange={(event, newValue) => {
                  if (
                    newValue.slice(-1)[0] &&
                    newValue.slice(-1)[0].inputValue != undefined
                  ) {
                    setAutoValue([
                      ...autoValue,
                      {
                        name: newValue.slice(-1)[0].inputValue,
                        other: true,
                      },
                    ]);
                  } else {
                    setAutoValue(newValue);
                  }
                  setData([]);
                }}
                filterSelectedOptions={true}
                onInputChange={(event, value) => {
                  setNoOptions(true);
                  // setLoading(true);
                  const trimmedValue = value?.trim();
                  if (trimmedValue) {
                    const encodedSkillName = Array.from(trimmedValue)
                      .map((char) => {
                        const isSpecialSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(
                          char
                        );
                        return isSpecialSymbol
                          ? encodeURIComponent(char)
                          : char;
                      })
                      .join("");
                    const endpoint = `/get-relevanceSkill?skills=${encodedSkillName}`;
                    // console.log("data", data);
                    getSkillOptions(setData, endpoint, setLoading);
                  } else {
                    setLoading(false);
                  }
                }}
                id="free-solo-dialog-demo"
                options={data}
                getOptionLabel={(option) => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterOptions={(options, params) => {
                  params.inputValue = params.inputValue.trim();
                  const filtered = filter(options, params);
                  return filtered;
                }}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.name, inputValue.trim());
                  const parts = parse(option.name, matches);

                  return (
                    <li {...props} key={option.name}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              color: part.highlight ? "red" : "inherit",
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // autoFocus={question?.autoFocus}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    error={errors?.primarySkill ? true : false}
                    helperText={
                      <>{errors?.primarySkill && "Skills cannot be empty"}</>
                    }
                    label={"Skills"}
                  />
                )}

                // sx={{
                //   "& .MuiOutlinedInput-root": {
                //     borderRadius: "50px !important",
                //   },
                // }}
                // />
              />
            )}
          />
        </Box>
      </React.Fragment>
    );
  }

  var cancelToken = null;
  function getSkillOptions(setData, endpoint, setLoading) {
    setData([]);
    setLoading(true);
    if (cancelToken) {
      // console.log("cancelToken", cancelToken);
      cancelToken.cancel("Previous request cancelled");
    }
    try {
      cancelToken = axios.CancelToken.source();
      axios
        .get(process.env.REACT_APP_API_BASE_URL + endpoint, {
          cancelToken: cancelToken.token,
        })
        .then((object) => {
          if (object?.status === 201) {
            var data = _.remove(DataDecryption(object.data), function (n) {
              return n.name !== "Other";
            });
            setData(data);
          } else {
            setData([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // Handle other errors here
            console.error("Error:", error);
          }
        });
    } catch (error) {
      // Handle any unexpected errors here
      console.error("Error:", error);
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        justifyContent={{
          xs: "center",
          sm: "center",
          md: "center",
          lg: "left",
        }}
      >
        <Grid item>
          <Box p={3}>
            <a href="/">
              <img
                src={"../../assets/images/TrueSelfy-logo.svg"}
                alt="true-selfy"
                style={{
                  maxWidth: `${isMobile ? "80px" : ""}`,
                }}
                loading="lazy"
              />
            </a>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={"flex-start"}
        p={{
          xs: "25px",
          sm: "30px 50px",
          md: "30px 50px",
          lg: "30px 70px 30px 130px",
        }}
        justifyContent={"center"}
      >
        <Grid item lg={6} order={{ xs: 2, sm: 2, lg: 1 }}>
          <Box>
            {/* techrelevance title */}
            <Typography
              variant="h1"
              sx={{
                fontWeight: "600",
                color: "#0391E8",
                fontSize: `${isMobile ? "40px !important" : "60px !important"}`,
                lineHeight: 1.2,
                textAlign: `${isMobile ? "center" : ""}`,
              }}
              pb={2}
            >
              Check your TechRelevance
            </Typography>
            {/* techrelevance description */}
            <Typography
              variant="body1"
              sx={{
                fontSize: `${isMobile ? "16px !important" : "22px !important"}`,
                lineHeigth: `${isMobile ? "1.5" : "36px"}`,
                textAlign: "justify",
                color: "#262655",
                pt: "12px",
              }}
            >
              Technology's relevance lies in its ability to solve problems,
              improve lives, and enhance efficiency. Its validity is proven
              through constant advancements, meeting evolving needs. However,
              technology faces obsolescence as newer, more efficient solutions
              emerge.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: `${isMobile ? "16px !important" : "22px !important"}`,
                lineHeigth: `${isMobile ? "1.5" : "36px"}`,
                textAlign: "justify",
                color: "#262655",
                pt: "12px",
              }}
            >
              Daily, it undergoes rapid change, shaping our world. Innovations
              render once-vital tech obsolete, urging adaptability. The
              accelerated pace forces individuals and industries to embrace
              continuous learning, stay informed, and foster a culture of
              innovation.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: `${isMobile ? "16px !important" : "22px !important"}`,
                lineHeigth: `${isMobile ? "1.5" : "36px"}`,
                textAlign: "justify",
                color: "#262655",
                pt: "12px",
              }}
            >
              In this relentless cycle, what's cutting-edge today may be
              antiquated tomorrow, emphasizing the importance of staying ahead
              in the technological landscape. Adaptability becomes the key to
              thriving in this ever-changing realm.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: `${isMobile ? "16px !important" : "22px !important"}`,
                lineHeigth: `${isMobile ? "1.5" : "36px"}`,
                textAlign: "justify",
                color: "#262655",
                pt: "12px",
              }}
            >
              Find out how long your skills will stay relevant in this
              ever-changing landscape?
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: `${isMobile ? "16px !important" : "22px !important"}`,
                lineHeigth: `${isMobile ? "1.5" : "36px"}`,
                textAlign: "justify",
                color: "#0391E8 !important",
                pt: "12px",
              }}
            >
              Plan your career based on the recommendations {poweredByChatGPT}
            </Typography>
            <Box pt={2}>
              <React.Fragment>
                {/* techrelevance form */}
                <FormProvider {...methods}>
                  <form
                    id="tech-relevance"
                    onSubmit={methods.handleSubmit(onSubmit)}
                  >
                    <TechRelevanceForm />
                    <Box pt={4}>
                      <LoadingButton
                        type="submit"
                        form="tech-relevance"
                        variant="contained"
                        sx={{
                          background: "#0391E8",
                          "&:hover": {
                            background: "#0391E8 !important",
                          },
                        }}
                        loading={loadingButton}
                      >
                        {" "}
                        Check TechRelevance
                      </LoadingButton>
                    </Box>
                  </form>
                </FormProvider>
              </React.Fragment>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} order={{ xs: 1, sm: 1, lg: 2 }}>
          <img
            width={"100%"}
            src={"../../assets/images/techrelevance-home.svg"}
            alt="tech-relevance"
            loading="lazy"
          />
        </Grid>
      </Grid>
      {/* user name container*/}

      {relevanceResult?.length !== 0 &&
      relevanceResult !== undefined &&
      !loadingButton ? (
        <>
          <Grid
            container
            alignItems={"center"}
            p={{
              xs: "10px",
              sm: "30px 50px",
              md: "30px 50px",
              lg: "0px 120px",
            }}
          >
            <Grid item xs={12}>
              <Box sx={{ height: "34px" }}>
                <Typography
                  variant="h2"
                  sx={{ fontWeight: "500", color: "#0391E8" }}
                  textAlign={"center"}
                >
                  {UserName.length !== 0
                    ? `${getFirstWord(UserName)}'s TechRelevance`
                    : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            p={{
              xs: "30px",
              sm: "30px 50px",
              md: "30px 50px",
              lg: "30px 120px",
            }}
          >
            <Box
              sx={{
                border: "2px solid #0391E8",
                width: "100%",
                borderRadius: "10px",
              }}
              display={"flex"}
              flexWrap={"wrap"}
              alignItems={"center"}
              justifyContent={"center"}
              p={3}
            >
              <Grid item xs={12} lg={5}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  // justifyContent={"center"}
                  // pb={2}
                  // pl={2}
                >
                  <Box>
                    <img
                      src={"../../assets/images/skill-score.svg"}
                      alt="score"
                      loading="lazy"
                    />
                  </Box>
                  <Box>
                    <Typography pl={2} variant="h3" sx={{ fontWeight: "600" }}>
                      Skill score
                    </Typography>
                  </Box>
                </Box>
                <Box
                // px={{ xs: 3, md: 3, lg: 3 }}
                >
                  <ul style={{ padding: "10px 0px" }}>
                    {relevanceResult?.topPrimarySkill?.map((item, index) => (
                      <li
                        key={index}
                        style={{ fontSize: "22px", padding: "4px" }}
                      >
                        <span>{item?.name}&nbsp;</span>
                        {Chat_GPT_Boost && (
                          <>
                            {" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span style={{ fontSize: "14px", padding: "5px" }}>
                              <Button
                                size="small"
                                sx={{
                                  fontSize: "14px",
                                  color: "white",
                                  background: "#0391E8",
                                  "&:hover": {
                                    background: "#0391E8 !important",
                                  },
                                }}
                                // loading={chatGptButton}
                                onClick={() => handleSendClick(item?.name)}
                              >
                                Get AI Boost
                              </Button>
                            </span>
                          </>
                        )}
                        <br />
                        <span>
                          {item.type === "y"
                            ? checkYear(item?.expiryYear).label
                            : checkSkillType(item?.expiryYear)}
                        </span>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              flexGrow: 1,
                            }}
                          >
                            <LinearProgress
                              sx={{
                                height: 10,
                                borderRadius: 5,
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: checkYear(item?.expiryYear)
                                    .barPrimaryColor,
                                },
                                backgroundColor: checkYear(item?.expiryYear)
                                  .barSecondaryColor,
                              }}
                              variant="determinate"
                              value={
                                item?.score <= 0 ? 20 : Math.round(item?.score)
                              }
                            />
                            <span>
                              {item?.score <= 0
                                ? "20"
                                : Math.round(item?.score)}
                              %
                            </span>
                          </Box>
                          <Box></Box>
                        </Box>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Grid>
              <Grid
                xs={0}
                lg={1}
                md={0}
                display={{ xs: "none", md: "none", lg: "flex" }}
                flexWrap={"wrap"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <div
                  style={{
                    width: "1px",
                    minHeight: "320px",
                    height: "100%",
                    maxHeight: "100%",
                    borderLeft: "2px solid #000088",
                  }}
                >
                  {" "}
                </div>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  mb={2}
                >
                  <Box>
                    <img
                      src={"../../assets/images/estimated-years.svg"}
                      loading="lazy"
                    />
                  </Box>
                  <Box>
                    <Typography pl={2} variant="h5" sx={{ fontWeight: "600" }}>
                      Technical validity until:
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <span
                    style={{
                      fontSize: "55px",
                      color: "#0391E8",
                      fontWeight: "600",
                    }}
                  >
                    {
                      getTopSkill(relevanceResult?.topPrimarySkill)
                        .maxExpiryYear
                    }
                  </span>
                </Box>
                <Box>
                  {
                    getTopSkill(relevanceResult?.topPrimarySkill)
                      ?.labelSuggestion
                  }
                </Box>
                <Box></Box>
              </Grid>
            </Box>
          </Grid>

          {checkSkill !== "" && Chat_GPT_Boost ? (
            <Grid
              alignItems={"center"}
              p={{
                xs: "30px",
                sm: "30px 50px",
                md: "30px 50px",
                lg: "30px 120px",
              }}
            >
              <Box
                sx={{
                  border: "2px solid #0391E8",
                  borderRadius: "10px",
                }}
                display={"flex"}
                flexWrap={"wrap"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                flexDirection={"column"}
                p={3}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  // pb={2}
                  // pl={2}
                >
                  <Box>
                    <img
                      src={"../../assets/images/skill-score.svg"}
                      alt="score"
                      loading="lazy"
                    />
                  </Box>
                  <Box>
                    <Typography pl={2} variant="h3" sx={{ fontWeight: "600" }}>
                      {checkSkill}
                    </Typography>
                  </Box>
                </Box>
                {chatGptButton ? (
                  <Box sx={{ display: "flex", my: 5 }}>
                    <CircularProgress sx={{ color: "#0391E8" }} />
                  </Box>
                ) : (
                  <Grid
                    item
                    // display={"flex"}
                    // alignItems={"center"}
                    // justifyContent={"space-around"}
                    // flexDirection={{ xs: "column", md: "column", lg: "row" }}
                  >
                    <Box>
                      {chatGPTData.length !== 0 && (
                        <List>
                          <ListItem>
                            <ListItemText>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                PercentageUsage in industry :
                              </span>{" "}
                              {chatGPTData?.percentageUsage}
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                PopularityRating in industry :
                              </span>{" "}
                              {chatGPTData?.popularityRating}/10
                            </ListItemText>
                          </ListItem>
                          {/* <ListItem>
                            <span
                              style={{ fontWeight: "bold", maxWidth: "50%", textAlign: "center" }}
                            >
                              {" "}
                              {chatGPTData?.explanation}
                            </span>
                          </ListItem> */}
                        </List>
                      )}
                      {/* <Typography>{chatGPTData?.explanation}</Typography> */}
                    </Box>

                    <div style={{ overflowX: isMobile ? "scroll" : "hidden" }}>
                      <TableContainer
                        sx={{
                          maxWidth: isMobile ? 300 : 650,
                          border: "1px solid #ddd",
                        }}
                        size="small"
                        component={Paper}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Technology/Library</TableCell>
                              <TableCell>EaseofLearning(1-10)</TableCell>
                              <TableCell>PotentialSalaryIncrease (%)</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {chatGPTData?.latestTechnologies?.map(
                              (row, index) => (
                                <TableRow
                                  key={row.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row?.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.easeOfLearning}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.potentialSalaryIncreasePercentage}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                )}
              </Box>
            </Grid>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default TechRelevance;
