import React from "react";
import Header from "../components/Header";
import FooterComponent from "../components/Footer";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Card,
  CardContent,
  Switch,
  CardHeader,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

function Incognito() {

  const isLoggedIn = useSelector((state) => state?.userReducer.isLoggedIn || false);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12} sx={{ mt: { xs: 3, md: 10 } }}>
        <Box
          sx={{
            backgroundColor: "#2c2c2c",
            height: { xs: "100%", lg: "80vh" },
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: { xs: "50px", md: "0px" },
          }}
        >
          {/* Incognito Icon */}
          <Box
            component="img"
            src="../../../../assets/images/incognito.svg" // You can replace this with your actual icon
            alt="Incognito"
            sx={{ width: 100, mb: 2 }}
          />
          <Box
            sx={{
              mx: { xs: 2, md: 0 },
            }}
          >
            {/* Title */}
            <Typography variant="h4" gutterBottom>
              About Incognito Mode
            </Typography>

            {/* Subtitle */}
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "500px", mb: 4 }}
            >
              Incognito Mode is a feature that enhances your privacy by deleting
              your form data immediately after use, ensuring your personal
              information is protected. It also makes your data untraceable, You
              pay full price in Incognito Mode, You also cannot provide
              references for results/what if analysis done in this mode.
              ("Incognito Mode is just the session.")
              {!isLoggedIn && ( 
              <Link
                href="/signin"
                sx={{ marginLeft: 0.5 }}
              >
                Sign In
              </Link>
              )}             
            </Typography>
          </Box>

          {/* Card Content */}
          <Grid container spacing={2} sx={{ mb: 4 }} justifyContent={"center"}>
            {/* First Card */}
            <Grid item xs={12} md={6} lg={3} sx={{ mx: { xs: 2, md: 0 } }}>
              <Card
                sx={{
                  backgroundColor: "#3B3B3C",
                }}
              >
                <CardContent sx={{ color: "#FFFFFF" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ textAlign: "left", pl: 2 }}
                  >
                    Your Privacy, Protected :
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: "unset", mr: "14px" }}>
                        <CircleIcon sx={{ fontSize: "8px", color: "green" }} />
                      </ListItemIcon>
                      <ListItemText primary="Privacy First : Your personal details, like form data, are deleted immediately after use." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: "unset", mr: "14px" }}>
                        <CircleIcon sx={{ fontSize: "8px", color: "green" }} />
                      </ListItemIcon>
                      <ListItemText primary="No Discounts : You’ll pay regular prices since cached deals aren’t available in Incognito Mode." />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>

            {/* Second Card */}
            <Grid item xs={12} md={6} lg={3} sx={{ mx: { xs: 2, md: 0 } }}>
              <Card
                sx={{
                  backgroundColor: "#3B3B3C",
                }}
              >
                <CardContent sx={{ color: "#FFFFFF" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ textAlign: "left", pl: 2 }}
                  >
                    Data Security and Discretion :
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: "unset", mr: "14px" }}>
                        <CircleIcon sx={{ fontSize: "8px", color: "green" }} />
                      </ListItemIcon>
                      <ListItemText primary="No Saved Info : Your login details and form entries won’t be stored for future sessions." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: "unset", mr: "14px" }}>
                        <CircleIcon sx={{ fontSize: "8px", color: "green" }} />
                      </ListItemIcon>
                      <ListItemText primary="Untraceable Actions : Anything you do in Incognito Mode can’t be tracked or referenced later it’s just a temporary session." />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: { xs: 20, md: 4 } }}>
        <FooterComponent />
      </Grid>
    </Grid>
  );
}

export default Incognito;
