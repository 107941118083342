// src/ShinyTypography.js

import React from "react";
import { Typography } from "@mui/material";
import "./ShinyTypography.css";

const ShinyTypography = ({ children }) => {
  return (
    <Typography variant="h1" className="shiny-text">
      {children}
    </Typography>
  );
};

export default ShinyTypography;
