import React from "react";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { useState } from "react";
import Typography from "@mui/material/Typography";

function PizzaPopHover({ message, src, alt }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip
      open={showTooltip}
      title={
        <Typography variant="p" component="p" color={"#fff"} fontSize={"18px"}>
          {message}
        </Typography>
      }
      arrow
      placement="top-start"
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    >
      <img
        onClick={() => setShowTooltip(!showTooltip)}
        className="ts-slice-icon"
        src={src}
        alt={alt}
        loading="lazy"
      />
    </Tooltip>
  );
}

export default PizzaPopHover;
