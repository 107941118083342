//import { ErrorMessage } from "@hookform/error-message";
import { Box, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// import InfoOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useState } from "react";
import TooltipComonent from "../common/Tooltip";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import QuestionOptionIcon from "../common/QuestionOptionIcon";

function EvaluationRadioQuestion({ question, username }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const optionArray = [{ name: "Yes" }, { name: "No" }, { name: "I Dont Know" }];
  const isMobile = useMediaQuery("(max-width:600px)");

  // question.rules.pattern = new RegExp(question.rules.pattern);
  return (
    <motion.div
      initial={{ opacity: 0, y: 0 }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, y: -200 }}
      transition={{ duration: 0.5 }}
    >
      <Grid item xs={12} my={1} sx={{ pl: 1 }}>
        <Typography
          variant="h6"
          // component="h1"
          display="flex"
          alignItems="center"
          sx={{ fontWeight: "500", lineHeight: "24px", py: 1 }}
        >
          {question?.label?.replace(/(their|his\/her)/g, `${username}'s` || "their") ||
            question?.label}
          {question.rules.required && (
            <>
              &nbsp;<p style={{ color: "red" }}>*</p>
            </>
          )}

          {question.question_info != null &&
          question.question_info.replace(/\s/g, "")?.length != 0 ? (
            <TooltipComonent message={question?.question_info} />
          ) : (
            <></>
          )}
        </Typography>

        <Box
          mt={2}
          display={{ xs: "flex" }}
          flexWrap={{ xs: "wrap" }}
          justifyContent={{ xs: "center" }}
        >
          {/* <Typography component="legend">Rating</Typography> */}
          <Controller
            name={question.questionName}
            control={control}
            rules={question.rules}
            render={({ field }) => (
              <>
                {optionArray?.map((value, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      m={{ xs: 0 }}
                      labelPlacement="bottom"
                      control={
                        <Checkbox
                          checked={field.value === value.name}
                          onChange={(e) => field.onChange(e.target.checked ? value.name : 0)}
                          icon={
                            <QuestionOptionIcon
                              path={`../../../../assets/images/evaluation-question-icons/radio/${
                                i + 1
                              }.svg`}
                              alt={`${value.name}`}
                            />
                          }
                          checkedIcon={
                            <QuestionOptionIcon
                              path={`../../../../assets/images/evaluation-question-icons/radio/${
                                i + 1
                              }-checked.svg`}
                              alt={`${value.name}`}
                            />
                          }
                        />
                      }
                      label={`${value.name}`}
                    />
                  );
                })}
              </>
            )}
          />
        </Box>

        {errors[question.questionName]?.type === "required" && (
          <p className={"errorMsg"}>{question.errorMsg}</p>
        )}
        {/* {errors[question.questionName]?.type === "pattern" && (
        <p className={"errorMsg"}>{question?.patternErrorMsg}</p>
      )} */}
      </Grid>
    </motion.div>
  );
}
export default EvaluationRadioQuestion;
