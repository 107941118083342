export const SET_REFERRALS_LIST = "SET_REFERRALS_LIST";
export const UPDATE_REFERALS_LIST = "UPDATE_REFERALS_LIST";
export const setReferralList = (response) => {
  return {
    type: SET_REFERRALS_LIST,
    payload: response,
  };
};

export const updateReferralList = (response) => {
  return {
    type: UPDATE_REFERALS_LIST,
    payload: response,
  };
};
