import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, Modal, Grid } from "@mui/material";
// import Link from "@mui/material/Link";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { getEmail, saveTargetPath } from "../utils/storage";
// import { isLoggedIn } from "../utils/storage";
import Popover from "@mui/material/Popover";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { Link } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserStatus } from "../redux/action/user";
import { Auth } from "aws-amplify";
import { OAuthCtx } from "../auth/AuthContext";
import { setupAuthInterceptor } from "../utils/apis";
import ResponsiveDialog from "./common/ResponsiveDialog";
import UserDropdown from "./common/UserDropdown";
import { Close, Logout } from "@mui/icons-material";

const drawerWidth = 240;

export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPophover, setopenPophover] = React.useState(false);
  const flagValue = useFeatureFlag("logohide");
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const MaintenanceMessage = useFeatureFlag("Maintenance_Break");
  const [openResponsiveDialog, setResponsiveDialog] = React.useState(false);
  const userType = useSelector(
    (state) => state.userReducer.type || "copy-link"
  );
  const demo_user_permission = useFeatureFlag("demo_user_permission");
  const Evaluate_user = useFeatureFlag("Evaluate_user");
  const { setAuthState } = React.useContext(OAuthCtx);
  const { getAuthState } = React.useContext(OAuthCtx);

  const checkUser = async () => {
    try {
      var currentSession = null;
      currentSession = await Auth.currentSession();
      await setAuthState(currentSession);
      setupAuthInterceptor(getAuthState);
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn"),
          userRefId: sessionStorage.getItem("userRefId"),
          uniqueReferredUserId: sessionStorage.getItem("uniqueReferredUserId"),
          type: userType,
        })
      );
    } catch (e) {
      // console.log("checkUser catch e", e);
    }
  };

  React.useEffect(() => {
    if (
      (localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.LastAuthUser`
      ) === null ||
        undefined) &&
      !sessionStorage.getItem("isLoggedIn")
    ) {
      // Local storage is empty
      dispatch(
        setUserStatus({
          isLoggedIn: false,
        })
      );
      // sessionStorage.clear();
      localStorage.clear();
    }
    checkUser();
  }, []);

  const userEmail = getEmail();
  const isLoggedIn = useSelector(
    (state) => state?.userReducer.isLoggedIn || false
  );

  const handleClick = (event) => {
    setAnchorEl(event.target);
    setopenPophover(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setopenPophover(false);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();

  const [openLogout, setOpenLogout] = React.useState(null);

  const handleModal = () => {
    setOpenLogout(true);
    // setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenLogout(false);
    // setAnchorEl(null);
  };

  const handleLogout = async () => {
    sessionStorage.clear();
    navigate("/signin");
    localStorage.clear();
    await Auth.signOut();
    dispatch(setUserStatus({ isLoggedIn: false }));
    sessionStorage.removeItem("isLogin");
    localStorage.removeItem("persist:root");
    setopenPophover(false);
    // console.log("handle logout");

    if (!sessionStorage.getItem("isLogin")) {
      navigate("/signin");
    }
  };
  const handleCalculate = () => {
    if (MaintenanceMessage != "undefined") {
      setResponsiveDialog(true);
    } else {
      saveTargetPath(location.pathname);

      navigate("/skills-calculation");
    }
  };

  // mobile menu
  const drawer = (
    <Box sx={{ color: "#000 !important" }}>
      <Box sx={{ p: 2, textAlign: "center" }}>
        {/* <Link href="/"> */}
        <img
          src={"../../assets/images/TrueSelfy-logo.svg"}
          alt={"TrueSelfy Logo"}
          loading="lazy"
          style={{ maxWidth: "127px", maxHeight: "64px" }}
          onClick={() => {
            navigate("/");
          }}
        />
        {/* </Link> */}
      </Box>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton
            sx={{ textAlign: "center", padding: "0px !important" }}
            variant="contained"
            selected={location.pathname === "/skills-calculation"}
            onClick={() => {
              if (MaintenanceMessage != "undefined") {
                setResponsiveDialog(true);
              } else {
                navigate("/skills-calculation");
              }
            }}
          >
            <ListItemText>{CalculateButtonValue}</ListItemText>
          </ListItemButton>
        </ListItem>

        {Evaluate_user && isLoggedIn && (
          <Box>
            <ListItem>
              <ListItemButton
                sx={{ textAlign: "center", padding: "0px !important" }}
                selected={location.pathname === "/endorse-expertise"}
                variant="contained"
                onClick={() => {
                  if (MaintenanceMessage != "undefined") {
                    setResponsiveDialog(true);
                  } else {
                    navigate("/endorse-expertise");
                  }
                }}
              >
                <ListItemText>Endorse Expertise</ListItemText>
              </ListItemButton>
            </ListItem>
          </Box>
        )}
        <>
          {isLoggedIn && (
            <ListItem>
              <ListItemButton
                sx={{ textAlign: "center", padding: "0px !important" }}
                variant="contained"
                selected={location.pathname === "/referrals"}
                onClick={() => {
                  if (MaintenanceMessage != "undefined") {
                    setResponsiveDialog(true);
                  } else {
                    navigate("/referrals");
                  }
                }}
              >
                <ListItemText>My References</ListItemText>
              </ListItemButton>
            </ListItem>
          )}
        </>
        <>
          {isLoggedIn && (
            <ListItem>
              <ListItemButton
                sx={{ textAlign: "center", padding: "0px !important" }}
                variant="contained"
                selected={location.pathname === "/my-friends"}
                onClick={() => {
                  if (MaintenanceMessage != "undefined") {
                    setResponsiveDialog(true);
                  } else {
                    navigate("/my-friends");
                  }
                }}
              >
                <ListItemText>My Friends</ListItemText>
              </ListItemButton>
            </ListItem>
          )}
        </>
        {/* {demo_user_permission && userEmail === "demo@trueselfy.com" ? ( */}
        <>
          {" "}
          <ListItem>
            <ListItemButton
              sx={{ textAlign: "center", padding: "0px !important" }}
              variant="contained"
              selected={location.pathname === "/pricing"}
              onClick={() => {
                navigate("/pricing");
              }}
            >
              <ListItemText>Pricing</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={{ textAlign: "center", padding: "0px !important" }}
              variant="contained"
              selected={location.pathname === "/refund-policy"}
              onClick={() => {
                navigate("/refund-policy");
              }}
            >
              <ListItemText>Refund Policy</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={{ textAlign: "center", padding: "0px !important" }}
              variant="contained"
              selected={location.pathname === "/contact-us"}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              <ListItemText>Contact Us</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={{ textAlign: "center", padding: "0px !important" }}
              variant="contained"
              selected={location.pathname === "/changelog"}
              onClick={() => {
                navigate("/changelog");
              }}
            >
              <ListItemText>ChangeLog</ListItemText>
            </ListItemButton>
          </ListItem>
        </>
        {/* ) : (
          " "
        )} */}

        {isLoggedIn ? (
          <ListItem>
            <ListItemButton
              sx={{ textAlign: "center", padding: "0px !important" }}
            >
              <ListItemText>
                <Button variant="outlined" onClick={handleModal}>
                  Logout
                </Button>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ) : (
          <>
            <ListItem onClick={() => navigate("/signin")}>
              <ListItemButton sx={{ textAlign: "center", padding: "0px" }}>
                <ListItemText p={0}>
                  <Button
                    p={0}
                    variant="outlined"
                    size="small"
                    onClick={() => navigate("/signin")}
                  >
                    Sign In
                  </Button>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const logohide = flagValue ? (
    ""
  ) : (
    <Box>
      <Link href="/">
        <img
          style={{ maxWidth: "127px", maxHeight: "64px" }}
          src={"../../assets/images/TrueSelfy-logo.svg"}
          alt={"TrueSelfy Logo"}
          loading="lazy"
        />
      </Link>
    </Box>
  );

  return (
    <>
      <CssBaseline />

      {/* desktop menu */}
      <AppBar
        component="nav"
        elevation={0}
        sx={{
          paddingTop: "15px",
          paddingBottom: "15px",
          flexGrow: 1,
          background: "#FFF",
          justifyContent: "space-between",
          position: "fixed",
          top: 0,
          right: 0,
          width: "100%",
          zIndex: 99,
          color: "#000 !important",
          padding: {
            xs: "10px",
            sm: "0px",
            md: "10px",
            lg: "0px 20px 20px",
            xl: "0px 80px 10px",
          },
        }}

        // color="default"
      >
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            // color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
              pl: { xs: "23%", sm: "38%", md: "41%", lg: "0%" },
            }}
          >
            <img
              src={"../../assets/images/TrueSelfy-logo.svg"}
              alt={"TrueSelfy Logo"}
              loading="lazy"
              style={{
                maxWidth: "80px",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
          </Box>

          <Box
            alignItems={"right"}
            sx={{
              flexGrow: 1,
              pt: "15px",
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            {logohide}
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "none", sm: "none", lg: "flex" },
              py: "2px",
            }}
            alignItems={"center"}
          >
            {location.pathname === "/skills-calculation" ? (
              <></>
            ) : (
              <>
                <Button variant="contained" onClick={() => handleCalculate()}>
                  {CalculateButtonValue}
                </Button>
              </>
            )}
            {Evaluate_user && isLoggedIn && (
              <Box>
                <Link
                  component="a"
                  href="/endorse-expertise"
                  sx={{
                    ml: 3,
                    cursor: "pointer",
                    borderBottom: `${
                      location.pathname === "/endorse-expertise"
                        ? "3px solid #2BB344"
                        : "none"
                    }`,
                  }}
                  underline={`${
                    location.pathname === "/endorse-expertise"
                      ? "none"
                      : "always"
                  }`}
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    if (MaintenanceMessage != "undefined") {
                      setResponsiveDialog(true);
                    } else {
                      navigate("/endorse-expertise");
                    }
                  }}
                >
                  Endorse Expertise
                </Link>
              </Box>
            )}
            {/* <>
              <Box>
                {isLoggedIn && (
                  <Link
                    sx={{
                      ml: 3,
                      cursor: "pointer",
                      borderBottom: `${
                        location.pathname === "/referrals" ? "3px solid #2BB344" : "none"
                      }`,
                    }}
                    underline={`${location.pathname === "/referrals" ? "none" : "always"}`}
                    variant="contained"
                    onClick={() => {
                      if (MaintenanceMessage != "undefined") {
                        setResponsiveDialog(true);
                      } else {
                        navigate("/referrals");
                      }
                    }}
                  >
                    My References
                  </Link>
                )}
              </Box>
            </> */}
            {/* {demo_user_permission && userEmail === "demo@trueselfy.com" ? ( */}
            <>
              <Link
                component="a"
                href="/pricing"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  ml: 3,
                  cursor: "pointer",
                  borderBottom: `${
                    location.pathname === "/pricing"
                      ? "3px solid #2BB344"
                      : "none"
                  }`,
                }}
                underline={`${
                  location.pathname === "/pricing" ? "none" : "always"
                }`}
              >
                Pricing
              </Link>

              <Link
                component="a"
                href="/refund-policy"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  ml: 3,
                  cursor: "pointer",
                  borderBottom: `${
                    location.pathname === "/refund-policy"
                      ? "3px solid #2BB344"
                      : "none"
                  }`,
                }}
                underline={`${
                  location.pathname === "/refund-policy" ? "none" : "always"
                }`}
              >
                Refund Policy
              </Link>
            </>
            {/* // ) : ( // "" // )} */}
            {/* {isLoggedIn ? (
              <Box sx={{ px: 2 }}>
                <Button variant="outlined" onClick={handleClick}>
                  Logout
                </Button>
              </Box>
            ) : (
              <></>
            )} */}
          </Box>
          {/* </RouterLink> */}
          <Modal
            open={openLogout}
            // onClose={handleClose}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography id="logout-modal-title" variant="h6" component="h2">
                  Confirm Logout
                </Typography>
                <IconButton onClick={handleCloseModal}>
                  <Close />
                </IconButton>
              </Grid>
              <Typography id="logout-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to logout?
              </Typography>
              <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
                <Button
                  sx={{ borderRadius: "55px" }}
                  onClick={() => {
                    handleLogout();
                    setOpenLogout(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
                <Button
                  sx={{ borderRadius: "55px" }}
                  onClick={handleCloseModal}
                  variant="outlined"
                  color="secondary"
                >
                  No
                </Button>
              </Grid>
            </Box>
          </Modal>
          {/*
          <Popover
            id={openPophover}
            open={openPophover}
            anchorEl={anchorEl}
            onClose={() => !openPophover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ px: 1 }}>
              <Typography variant="h5" textAlign={"center"} sx={{ paddingTop: 2 }}>
                Are you sure ?
              </Typography>
              <Box p={2} display={"flex"} justifyContent={"space-between"}>
                <Button
                  variant="contained"
                  sx={{ padding: "2px", margin: "5px", minWidth: "50px" }}
                  onClick={handleClose}
                  size={"small"}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size={"small"}
                  sx={{ padding: "2px", margin: "5px", minWidth: "50px" }}
                  onClick={handleLogout}
                  color="error"
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Popover> */}

          {!isLoggedIn ? (
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
                ml: "20px",
              }}
            >
              <Button variant="outlined" onClick={() => navigate("/signin")}>
                Sign In
              </Button>
            </Box>
          ) : (
            <UserDropdown
              handleLogout={handleLogout}
              Evaluate_user={Evaluate_user}
              isLoggedIn={isLoggedIn}
            />
          )}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            "& .MuiPaper-root-MuiAppBar-root": {
              zIndex: 9,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {openResponsiveDialog && (
        <ResponsiveDialog
          openResponsiveDialog={openResponsiveDialog}
          setResponsiveDialog={setResponsiveDialog}
          MaintenanceMessage={MaintenanceMessage}
        />
      )}
    </>
  );
}
