import React from "react";
import { useMediaQuery } from "@mui/material";

function QuestionOptionIcon({ path, alt }) {
  // console.log("path", path);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallDevice = useMediaQuery("(max-width:1210px)");

  return (
    <div>
      {isMobile && (
        <img
          style={{
            boxShadow: `-webkit-box-shadow: 5px 5px 154px 6px rgba(0,0,0,1);
-moz-box-shadow: 5px 5px 154px 6px rgba(0,0,0,1);
box-shadow: 5px 5px 154px 6px rgba(0,0,0,1`,
          }}
          src={path}
          alt={alt}
          width="50px"
          height={"100%"}
          loading="lazy"
        />
      )}
      {!isMobile && (
        <img
          style={{
            boxShadow: `-webkit-box-shadow: 5px 5px 154px 6px rgba(0,0,0,1);
-moz-box-shadow: 5px 5px 154px 6px rgba(0,0,0,1);
box-shadow: 5px 5px 154px 6px rgba(0,0,0,1`,
          }}
          src={path}
          alt={alt}
          width="85px"
          height={"100%"}
          loading="lazy"
        />
      )}
    </div>
  );
}

export default QuestionOptionIcon;
