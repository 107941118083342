import { SET_INCOGNITO_MODE } from "../action/IncognitoMode";

const initialState = { IncognitoUser: false };
export default function IncognitoMode(state = initialState, action) {
  switch (action.type) {
    case SET_INCOGNITO_MODE: {
      const { payload } = action;
      return { ...state, IncognitoUser: payload };
    }
    default:
      return state;
  }
}
