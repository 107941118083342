//import { ErrorMessage } from "@hookform/error-message";
import { Grid, TextField, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import TooltipComonent from "../../components/common/Tooltip";
import { motion } from "framer-motion";

function EvaluationTextQuestion({ question, username }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  // question.rules.pattern = new RegExp(question.rules.pattern);
  return (
    // <motion.div
    //   initial={{ opacity: 0, x: -100 }}
    //   whileInView={{ opacity: 1, x: 0 }}
    //   exit={{ opacity: 0, x: 100 }}
    //   transition={{ duration: 0.5 }}
    // >
    <motion.div
      initial={{ opacity: 0, y: 0 }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, y: -200 }}
      transition={{ duration: 0.5 }}
    >
      <Grid item xs={12} my={3} sx={{ pl: 1 }}>
        <Box py={1}>
          <Typography
            variant="h6"
            // component="h1"
            display="flex"
            alignItems="center"
            sx={{ fontWeight: "500", lineHeight: "24px", py: 1 }}
          >
            {question?.label?.replace(/(their|his\/her)/g, `${username}'s` || "their") ||
              question?.label}

            {question.rules.required && (
              <>
                &nbsp;<p style={{ color: "red" }}>*</p>
              </>
            )}
            {question.question_info != null &&
            question.question_info.replace(/\s/g, "")?.length != 0 ? (
              <TooltipComonent message={question?.question_info} />
            ) : (
              <></>
            )}
          </Typography>
        </Box>

        <Controller
          control={control}
          rules={question.rules}
          name={question.questionName}
          render={({ field }) => (
            <TextField
              // autoFocus={question?.autoFocus}
              {...field}
              // onChange={(e, newValue) => {
              //   setValue(question.questionName, e.target.value);
              //   question?.isSuggested &&
              //     setValue(
              //       `suggestedObject[${question.questionName}]`,
              //       e.target.value
              //     );
              // }}
              style={{ width: "100%" }}
              size="small"
              label={question.placeholder}
              variant="outlined"
            />
          )}
        />

        {errors[question.questionName]?.type === "required" && (
          <p className={"errorMsg"}>{question.errorMsg}</p>
        )}
      </Grid>
    </motion.div>
  );
}
export default EvaluationTextQuestion;
