//how many last digit you want convert into zero enter here
export const ROUNDIGIT = 1000;

//CURRENCY SYMBOL FOR AMMOUNT
export const RUPEE = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  maximumSignificantDigits: 4,
});

export const EMAIL_GHOST_TEXT = "Enter your valid, personal email id";
export const EMAIL_ERROR_MSG =
  "Oh no, that doesn't look like a valid email! Please double-check and try again.";
export const OTP_GHOST_TEXT = "Enter the OTP received in email sent to <<email id>>";
export const OTP_ERROR_MSG =
  "Oh no, that doesn't look like a valid OTP! Please double-check and enter the OTP sent to <<email id>>.";

export const SHOW_LINKEDIN_LOGIN_BUTTON = "showLinkedInButton";
export const MAINTENANCE_BREAK = "Maintenance_Break";
export const SHOW_GOOGLE_LOGIN_BUTTON = "showGoogleLoginButton";
