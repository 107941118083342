import { Autocomplete, Box, CircularProgress, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { getOptions, postUserCustomInput } from "../stepper.helper";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import "../../../styles.css";
import React, { useEffect, useRef, useState } from "react";
import TooltipComonent from "../../common/Tooltip";
import { isEqual } from "lodash";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";

function DynamicMultiSelectQuestionComponent({ question }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [open, setOpen] = useState(false); // if dropdown open?
  const [autoValue, setAutoValue] = useState(getValues(question.questionName) || []); // if dropdown open?
  const [data, setData] = useState([]);
  const [loading, setLoading1] = useState(false);
  const saveCustomInput = useFeatureFlag("savecustominput");
  const ref = useRef(null);
  useEffect(() => {
    setValue(question.questionName, autoValue);
    if (saveCustomInput) {
      let storeOtherValues = [];
      autoValue.forEach((obj) => {
        const hasOtherKey = Object.keys(obj).some((key) => key === "other");
        if (hasOtherKey && !storeOtherValues.some((item) => isEqual(item, obj))) {
          storeOtherValues.push(obj);
        }
      });
      if (storeOtherValues.length > 0) {
        postUserCustomInput(question.saveCustomInputUrlEndpoint, storeOtherValues);
      }
    }
  }, [autoValue]);

  const filter = createFilterOptions({
    stringify: (option) => option.name,
  });
  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography
        // variant="h6"
        // component="h1"
        // sx={{ fontWeight: "bold", py: 1 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="stepper-question"
      >
        <Box>
          {question.label}
          {question.rules.required && (
            <>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </>
          )}
        </Box>

        {question.question_info != null &&
        question.question_info.replace(/\s/g, "")?.length != 0 ? (
          <>
            <TooltipComonent message={question?.question_info} />
          </>
        ) : (
          <></>
        )}
      </Typography>

      <Controller
        name={question.questionName}
        control={control}
        rules={question.rules}
        render={({ field }) => (
          <Autocomplete
            noOptionsText="Start typing to get options"
            {...field}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            multiple
            autoComplete={true}
            // isOptionEqualToValue={(option, value) => option === value}
            value={autoValue}
            onChange={(event, newValue) => {
              if (newValue.slice(-1)[0] && newValue.slice(-1)[0].inputValue != undefined) {
                setAutoValue([
                  ...autoValue,
                  {
                    name: newValue.slice(-1)[0].inputValue,
                    other: true,
                  },
                ]);
              } else {
                setAutoValue(newValue);
              }
              setData([]);
            }}
            filterSelectedOptions={true}
            onInputChange={(event, value) => {
              setLoading1(true);
              const trimmedValue = value?.trim();
              if (trimmedValue) {
                const encodedSkillName = Array.from(trimmedValue)
                  .map((char) => {
                    const isSpecialSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(char);
                    return isSpecialSymbol ? encodeURIComponent(char) : char;
                  })
                  .join("");
                const endpoint = `${question?.dynamicUrlEndpoint}=${encodedSkillName}`;
                getOptions(setData, endpoint, setLoading1);
              } else {
                setLoading1(false);
              }
            }}
            id="free-solo-dialog-demo"
            options={data}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.name;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterOptions={(options, params) => {
              params.inputValue = params.inputValue.trim();
              const filtered = filter(options, params);
              const isExisting = options.some(
                (option) => params.inputValue.toLowerCase() === option.name.toLowerCase()
              );
              if (params.inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}" option`,
                });
              }

              return filtered;
            }}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.name, inputValue.trim());
              const parts = parse(option.name, matches);

              return (
                <li {...props} key={option.name}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          color: part.highlight ? "red" : "inherit",
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus={question?.autoFocus}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                label={question.placeholder}
              />
            )}
          />
        )}
      />

      {errors[question.questionName]?.type === "required" && (
        <p className={"errorMsg"}>{question.errorMsg}</p>
      )}
    </Grid>
  );
}
export default DynamicMultiSelectQuestionComponent;
