import React, { useState } from "react";
import { Auth } from "aws-amplify";
import {
  isLoggedIn,
  getAccessToken,
  getIdToken,
  getExpireBy,
  getEmail,
  setLoggedIn,
  setAccessToken,
  setIdToken,
  setExpireBy,
  clearSession,
  setPermissions,
  getPermissions,
  setUserEmail,
} from "../utils/storage";
import { toast } from "react-hot-toast";

const useAuthContext = () => {
  const getAuthState = async () => {
    var currentSession = null;
    try {
      currentSession = await Auth.currentSession();
      // console.log(currentSession);

      var authState = {
        isLoggedIn: currentSession != null,
        id_token: currentSession != null ? currentSession.getIdToken().getJwtToken() : null,
        access_token: currentSession != null ? currentSession.getAccessToken().getJwtToken() : null,
        expire_by: new Date().getTime() + currentSession.getIdToken().getExpiration() * 1000,
        userEmail: currentSession != null ? currentSession.getIdToken().payload.email : null,
      };
      // console.log(authState);

      if (authState.isLoggedIn === false || new Date() > new Date(authState.expire_by)) {
        toast.error("Your Session was expire Please Login again");
        window.location.replace("/signin");
      }
      updateAuthState(authState);
      return authState;
    } catch (error) {
      window.location.replace("/signin");
    }
    // console.log(
    //   "currentSessionnn",
    //   currentSession != null
    //     ? currentSession.getAccessToken().payload.username
    //     : null
    // );
  };
  const setAuthState = async (currentSession) => {
    var authState = {
      isLoggedIn: currentSession != null,
      id_token: currentSession != null ? currentSession.getIdToken().getJwtToken() : null,
      access_token: currentSession != null ? currentSession.getAccessToken().getJwtToken() : null,
      expire_by: new Date().getTime() + currentSession.getIdToken().getExpiration() * 1000,
      userEmail: currentSession != null ? currentSession.getIdToken().payload.email : null,
    };
    // console.log(authState);
    if (authState.isLoggedIn === false || new Date() > new Date(authState.expire_by)) {
      window.location.replace("/signin");
    }
    updateAuthState(authState);
    return authState;
  };
  const logout = async () => {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log("error signing out: ", error);
    } finally {
      window.location.replace("/signin");
    }
  };

  const updateAuthState = (authState) => {
    setLoggedIn(authState.isLoggedIn);
    setAccessToken(authState.access_token);
    setIdToken(authState.id_token);
    setExpireBy(authState.expire_by);
    setUserEmail(authState.userEmail);
  };

  const updatePermissions = (permissions) => {
    setPermissions(permissions);
  };

  const hasValidAccess = (requiredPermission) => {
    var split_permissions = getPermissions().split(",");
    if (
      split_permissions.indexOf(requiredPermission) !== -1 ||
      split_permissions.indexOf("admin") !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  return {
    getAuthState,
    setAuthState,
    updateAuthState,
    updatePermissions,
    hasValidAccess,
  };
};

export const OAuthCtx = React.createContext({});

export default function OAuthProvider({ children }) {
  return <OAuthCtx.Provider value={useAuthContext()}>{children}</OAuthCtx.Provider>;
}
