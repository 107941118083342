import { ErrorMessage } from "@hookform/error-message";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useEffect, useState } from "react";
import ConditionalQuestions from "../stepper_form/question-type-components/ConditionalQuestions";
import TooltipComponent from "../common/Tooltip";
import { motion } from "framer-motion";
// import { useMediaQuery } from "@mui/material";

function EvaluationSelectQuestion({ question, username }) {
  // question.options = ["ajay", "aj", "dev"];
  const {
    control,
    getValues,
    setValue,
    unregister,
    formState: { errors },
  } = useFormContext();

  const [fieldIsConditional, setFieldIsConditional] = useState(false);
  const [callUseEffect, setCallUseEffect] = useState(false);

  const handleConditionalQuestion = () => {
    // console.log("function called");

    callUseEffect ? setCallUseEffect(false) : setCallUseEffect(true);
    setFieldIsConditional(false);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        whileInView={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, y: -200 }}
        transition={{ duration: 0.5 }}
      >
        <Grid item xs={12} md={12} my={3} sx={{ pl: 1 }}>
          <Box py={1}>
            <Typography
              variant="h6"
              // component="h1"
              display="flex"
              alignItems="center"
              sx={{ fontWeight: "500", py: 1 }}
            >
              {question?.label?.replace(/(their|his\/her)/g, `${username}'s` || "their") ||
                question?.label}
              {question.rules.required && (
                <>
                  &nbsp;<p style={{ color: "red" }}>*</p>
                </>
              )}
              {question.question_info != null &&
              question.question_info.replace(/\s/g, "")?.length != 0 ? (
                <>
                  <TooltipComponent message={question?.question_info} />
                </>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
          <Controller
            name={question.questionName}
            control={control}
            rules={question.rules}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                onChange={(event, newValue) => {
                  field.onChange(newValue);
                  newValue === "Other" ? setFieldIsConditional(true) : handleConditionalQuestion();
                }}
                id="size-small-standard"
                options={question.options}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus={question?.autoFocus}
                    size="small"
                    variant="outlined"
                    label={question.placeholder}
                  />
                )}
                // renderOption={(props, option) => (
                //   <li {...props}>
                //     {isMobile && (
                //       <img
                //         src={`../../../../assets/images/evaluation-question-icons/person/${0}.svg`}
                //         alt={option || "img"}
                //         width="65px"
                //         height={"100%"}
                //       />
                //     )}
                //     {!isMobile && (
                //       <img
                //         src={`../../../../assets/images/evaluation-question-icons/person/${0}.svg`}
                //         alt={option || "img"}
                //         width="75px"
                //         height={"100%"}
                //         style={{ paddingRight: "15px" }}
                //       />
                //     )}

                //     {option}
                //   </li>
                // )}
              />
            )}
          />

          {errors[question.questionName]?.type === "required" && (
            <p className={"errorMsg"}>{question.errorMsg}</p>
          )}
        </Grid>
      </motion.div>
    </>
  );
}
export default EvaluationSelectQuestion;
