import React, { useEffect, useState } from "react";
import EvaluationForm from "./EvaluationForm";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";
import EvaluateUser from "../common/EvaluateUser";
import { useTheme } from "@mui/material/styles";
import { deleteEvalutionUser, getEvaluationUser } from "../evaluation/evaluation.helper";
import Loading from "../UI/Loading";
import Header from "../Header";
import Footer from "../Footer";
import EvaluationStepperForm from "./EvaluationStepperForm";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { Stack } from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeviceInfoAndLocation from "../common/DeviceInfoAndLocation";
import { motion } from "framer-motion";
import DeleteIcon from "@mui/icons-material/Delete";
import Popover from "@mui/material/Popover";
import CustomizedSnackbars from "../common/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import { getEmail } from "../../utils/storage";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 4 }} px={{ xs: 2, md: 0, lg: 0 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

function Evaluation() {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [EvalutionLoader, setEvalutionLoader] = useState(true);
  const [evaluationUsers, setEvaluationUsers] = useState();
  const [expandedPanel, setExpandedPanel] = React.useState(null);
  const [evaluateuserLoader, setEvaluationUserLoader] = useState(true);
  const [formPendingAndCompletedList, setFormPendingAndCompletedList] = useState(null);
  const theme = useTheme();
  const Evaluate_user = useFeatureFlag("Evaluate_user");
  const [location, setLocation] = useState();
  const [deviceInfo, setDeviceInfo] = useState();
  const [searchData, setSearchData] = useState(evaluationUsers);
  const [searchResults, setSearchResults] = useState(searchData);
  const [searchTerm, setSearchTerm] = useState("");
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteUserObject, setDeleteUserObject] = useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();
  const uniqueReferredUserId =
    useSelector((state) => state.userReducer?.uniqueReferredUserId) || "";
  const userType = useSelector((state) => state.userReducer?.type) || "";
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);

    ReactGA4.send({
      hitType: "pageview",
      page: "/endorse-expertise",
      title: "visited Endorse",
    });
    ReactGA4.event({
      category: "endorse",
      action: "Endorse viewed",
      label: `user: ${getEmail()}`,
    });
  }, []);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setDeleteUserObject(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpandedPanel(null);
  };

  const deletePopover = Boolean(anchorEl);
  const id = deletePopover ? "delete-user" : undefined;

  useEffect(() => {
    setSearchData(evaluationUsers);
  }, [evaluationUsers]);

  useEffect(() => {
    DeviceInfoAndLocation(setLocation, setDeviceInfo);
  }, []);
  useEffect(() => {
    if (!Evaluate_user) {
      navigate("/");
    }
    // if (sessionStorage.getItem("isReferralUsers") === "false") {
    //   navigate("/");
    // }
    getEvaluationUser(
      setEvaluationUsers,
      setFormPendingAndCompletedList,
      setEvalutionLoader,
      navigate,
      uniqueReferredUserId,
      userType
    );
  }, []);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : panel === expandedPanel ? null : panel);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getAccordionColor = (status) => {
    switch (status) {
      case "success":
        return "#DCFFE2";
      case "pending":
        return "#F8F8F8";

      default:
        return "#fff";
    }
  };

  // search component start here

  const handleSearchChange = (event) => {
    setExpandedPanel(!expandedPanel);
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // Filter the search results based on the new search term
    const filteredResults = searchData?.filter((item) =>
      item?.evaluatedUserInfo?.name?.toLowerCase()?.includes(newSearchTerm.toLowerCase())
    );
    // debugger;
    if (filteredResults?.length === 0 && newSearchTerm !== "") {
      setSearchResults(searchData);
    } else {
      setSearchResults(filteredResults);
      // console.log(searchResults, "searchResults");

      // setEvaluationUsers(searchResults);
    }
  };

  // tabs

  const CustomBadge = (props) => (
    <>
      <span
        style={{
          marginLeft: "5px",
          paddingRight: "6px",
          color: "inherit",
          background: "#E6E6E6",
          padding: "0px 10px",
          fontSize: "inherit",
          borderRadius: "50px",
        }}
      >
        {props.total}
      </span>
    </>
  );
  const handleDeleteUser = () => {
    const updatedUsers = evaluationUsers.filter(
      (item) => item.evaluatedId !== deleteUserObject.evaluatedId
    );
    setEvaluationUsers(updatedUsers);
    deleteEvalutionUser(
      deleteUserObject,
      evaluationUsers,
      setEvaluationUsers,
      setOpenSnackbar,
      setSnackbarMsg,
      setSnackbarSeverity
    );
    setAnchorEl(null);
  };
  // tabs over
  const evaluationTabForm = (item, index) => {
    return (
      <Accordion
        key={index}
        expanded={expandedPanel === index && !deletePopover}
        onChange={handleChangeAccordion(index)}
        sx={{
          borderRadius: "15px",
          "& .MuiAccordionSummary-expandIconWrapper": {
            display: "none",
          },
          "&:before": { display: "none" },
        }}
        elevation={0}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          sx={{
            borderRadius: "15px",
            boxShadow:
              expandedPanel === index
                ? " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                : " 0px 0px 2px 2px rgba(175, 175, 175, 0.25)",
            border: "0px",
            backgroundColor:
              expandedPanel === index
                ? `${theme.palette.primary.evalute}`
                : `${getAccordionColor(item?.formStatus)}`,
            color: expandedPanel === index ? "white" : "black",
            marginBottom: "28px",
            p: "10px 24px",
            height: "100%",
            "& .MuiAccordionSummary-content": {
              margin: 0,
            },
          }}
          display={"flex"}
          alignItems={"center"}
          elevation={0}
          disableGutters
          key={index}
        >
          <EvaluateUser
            key={index}
            user={item}
            isMobile={isMobile}
            expandedPanel={expandedPanel}
            index={index}
            evaluateuserLoader={evaluateuserLoader}
            formPendingAndCompletedList={formPendingAndCompletedList}
          />

          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DeleteIcon
              fontSize={`${isMobile ? "medium" : "large"}`}
              onClick={(event) => handleClick(event, item)}
              style={{ color: "#EF4F38" }}
            />
          </Box>
          <Popover
            id={id}
            open={deletePopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ pt: 2, px: 2, textAlign: "center" }}>Are you sure ?</Typography>
            <Box p={2} display={"flex"} justifyContent={"space-around"}>
              <Button
                key={index}
                variant="contained"
                sx={{ padding: "4px 8px", margin: "0px" }}
                onClick={handleClose}
                size={"small"}
              >
                No
              </Button>
              <Button
                key={index}
                variant="contained"
                size={"small"}
                sx={{ padding: "4px 8px", margin: "0px" }}
                onClick={handleDeleteUser}
                color="error"
              >
                Yes
              </Button>
            </Box>
          </Popover>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            border: `1px solid ${theme.palette.primary.evalute}`,
            borderRadius: "15px",
            boxSizing: "border-box",
            padding: "16px",
            position: "relative",
            marginBottom: "28px",
            "&::after": {
              content: "''",
              position: "absolute",
              height: " 27px",
              width: " 27px",
              top: "-14px",
              left: "47px",
              borderWidth: "1px",
              borderColor: "#2BB344  transparent transparent #2BB344",
              borderStyle: "solid",
              transform: "rotate(45deg)",
              background: `${theme.palette.primary.evalutebackground}`,
            },
            background: `${theme.palette.primary.evalutebackground}`,
          }}
          key={index}
        >
          <EvaluationStepperForm
            item={evaluationUsers}
            resultId={item?.resultId}
            evaluatedId={item?.evaluatedId}
            expandedPanel={expandedPanel}
            location={location}
            deviceInfo={deviceInfo}
            index={index}
            setExpandedPanel={setExpandedPanel}
            setEvaluationUsers={setEvaluationUsers}
            setFormPendingAndCompletedList={setFormPendingAndCompletedList}
            setEvaluationUserLoader={setEvaluationUserLoader}
            setDesignation={setDesignation}
            setCompany={setCompany}
            designation={designation}
            company={company}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  const showEmptyMessage = (label) => {
    return (
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        whileInView={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, y: -200 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          sx={{
            maxWidth: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            // backgroundColor: "#DBDBDB",
            // border: "2px solid grey",
            // borderRadius: "15px",
          }}
          p={{ xs: 5, md: 10, lg: 10 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={`../../../../assets/images/empty-list.svg`}
              alt="empty list"
              loading="lazy"
              style={{
                maxWidth: `${isMobile ? "50%" : "100%"}`,
              }}
            />
            <Typography
              sx={{
                textTransform: "capitalize",
                color: "#232323",
                textAlign: "center",
              }}
              variant="h2"
            >
              {label}
            </Typography>
            <Typography pt={4} textAlign={"center"}>
              Why don’t you calculate your TechWorth through Trueselfy?
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              // display={
              //   location.pathname === "/skills-calculation" ? false : true
              // }
              sx={{ px: `${isMobile ? "10px" : "25px"}` }}
              variant="contained"
              onClick={() => navigate("/skills-calculation")}
            >
              {CalculateButtonValue}
            </Button>
          </Box>
        </Box>
      </motion.div>
    );
  };
  return (
    <React.Fragment>
      <Grid container>
        {EvalutionLoader ? (
          <Loading />
        ) : (
          <>
            <Grid item xs={12} sx={{ mt: 12 }}>
              <Header />
            </Grid>
            <Grid
              item
              xs={12}
              mx={{ xs: 0, sm: 0, md: 3, lg: 18 }}
              px={{ xs: 0 }}
              my={{ xs: 0, sm: 3, md: 3, lg: 3 }}
              sx={{ minHeight: { xs: "calc(100vh - 220px)", md: "calc(100vh - 240px)" } }}
            >
              <Stack
                direction={{
                  xs: "column",
                  md: "row",
                  sm: "column",
                  lg: "row",
                  xl: "row",
                }}
                alignItems={"center"}
                spacing={5}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                    "& .MuiTab-root": {
                      fontWeight: "400",
                      lineHeight: "28px",
                      fontSize: `${isMobile ? "14px" : "24px"}`,
                      textTransform: "capitalize !important",
                      borderBottom: "3px solid #E6E6E6",
                    },
                    "& .Mui-selected.MuiTab-root": {
                      textTransform: "capitalize",
                    },
                    padding: `${isMobile ? "0px !important" : "8px important"}`,
                  }}
                >
                  <Tab
                    label="All"
                    disableRipple
                    icon={<CustomBadge total={evaluationUsers?.length || 0} />}
                    iconPosition="end"
                    {...a11yProps(0)}
                  />
                  <Tab
                    disableRipple
                    label="Pending"
                    icon={
                      <CustomBadge
                        total={
                          evaluationUsers?.filter((item) => item.formStatus === "pending")
                            ?.length || 0
                        }
                      />
                    }
                    iconPosition="end"
                    {...a11yProps(1)}
                  />
                  <Tab
                    disableRipple
                    label="Completed"
                    icon={
                      <CustomBadge
                        total={
                          evaluationUsers?.filter((item) => item.formStatus === "success")
                            ?.length || 0
                        }
                      />
                    }
                    iconPosition="end"
                    {...a11yProps(2)}
                  />
                </Tabs>
                <TextField
                  // label="TextField"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  type={"text"}
                  placeholder="Search by Name.."
                  sx={{
                    maxWidth: "348px",
                    width: "100%",
                    borderRadius: "12px !important",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    "& .MuiOutlinedInput-input ": {
                      p: "10px 11px",
                    },
                    "& .MuiOutlinedInput-root ": {
                      borderRadius: "12px !important",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon SX={{ color: "#727272" }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Stack>
              <CustomTabPanel value={value} index={0}>
                {searchResults !== undefined && searchResults?.length !== 0
                  ? searchResults?.map((item, index) => evaluationTabForm(item, index))
                  : evaluationUsers?.length !== 0 && evaluationUsers !== undefined
                  ? evaluationUsers?.map((item, index) => evaluationTabForm(item, index))
                  : showEmptyMessage("No Items")}
                {/* {console.log(searchResults, "searchResultsmd1")} */}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {searchResults !== undefined &&
                searchResults?.filter((item) => item.formStatus === "pending")?.length !== 0
                  ? searchResults
                      ?.filter((item) => item.formStatus === "pending")
                      ?.map((item, index) => evaluationTabForm(item, index))
                  : evaluationUsers?.filter((item) => item.formStatus === "pending").length !== 0 &&
                    evaluationUsers !== undefined
                  ? evaluationUsers
                      ?.filter((item) => item.formStatus === "pending")
                      ?.map((item, index) => evaluationTabForm(item, index))
                  : showEmptyMessage("No Pending Item")}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {searchResults !== undefined &&
                searchResults?.filter((item) => item.formStatus === "success")?.length !== 0
                  ? searchResults
                      .filter((item) => item.formStatus === "success")
                      ?.map((item, index) => evaluationTabForm(item, index))
                  : evaluationUsers?.filter((item) => item.formStatus === "success").length !== 0 &&
                    evaluationUsers !== undefined
                  ? evaluationUsers
                      ?.filter((item) => item.formStatus === "success")
                      ?.map((item, index) => evaluationTabForm(item, index))
                  : showEmptyMessage("No Completed Item")}
              </CustomTabPanel>

              {/* </Paper> */}

              {/* </Box> */}
            </Grid>
            <Grid item xs={12}>
              <Footer />
            </Grid>
            {/* </Grid> */}
          </>
        )}
      </Grid>
      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMsg={snackbarMsg}
          snackbarSeverity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}

export default Evaluation;
