import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  FormLabel,
  Button,
  ButtonGroup,
  Grid,
  Avatar,
  Paper,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Chip,
  TableRow,
  TableCell,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  TablePagination,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Add, Delete, DeleteOutline } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ShareIcon from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import ShareOnSocial from "react-share-on-social";
import { useFormContext, Controller, useForm } from "react-hook-form";
import { getEmail } from "../utils/storage";
import validator from "validator";
import {
  deleteReferralUser,
  getReferralsReferralPage,
} from "../components/stepper_form/stepper.helper";
import * as levenshtein from "damerau-levenshtein";
import { useNavigate, Link } from "react-router-dom";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { useDispatch } from "react-redux";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Loading from "../components/UI/Loading";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import { WhatsappShareButton } from "react-share";
import ReferencesTable from "../components/common/ReferencesTable";
import Joyride from "react-joyride";
import zIndex from "@mui/material/styles/zIndex";

// import { WhatsappIcon, FacebookIcon } from "react-share";

// import favicon from "../../public/assets/images/fire-icon.svg";

const ReferralPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isMedium = useMediaQuery("(max-width:1200px)");
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const [ReferralsField, setReferralsField] = useState([
    { name: "", email: "", emailValid: "blank" },
  ]);
  const [emailError, setEmailError] = useState("");
  const userEmail = getEmail();
  const referralsList = useSelector((state) => state.ReferralsReducer.referrals) || [];
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const Referral_Link_Description_write_up = useFeatureFlag("Referral_Link_Description_write_up");
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [run, setRun] = useState(false);
  const shareViaWhatsApp = useFeatureFlag("show_share_via_WhatsApp_references_tracking");

  // get referrals when page is load
  useEffect(() => {
    const joyRide = setTimeout(() => {
      setRun(true);
    }, 3000);
    getReferralsReferralPage(setLoading, dispatch);
  }, []);

  // handle change for email and name
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...ReferralsField];

    list[index][name] = value;
    setReferralsField(list);

    // setReferrals(ReferralsField);
  };

  // App tour steps and content

  const JoyrideSteps = [
    {
      target: "body",
      placement: "center",
      content: (
        <>
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="h4" gutterBottom>
              🌟 Welcome to the Provide References Section! 🌟
            </Typography>
            <Typography variant="body1" paragraph>
              Share the app link with colleagues and friends to endorse your expertise, boosting
              your authenticity! Earn a peer-reviewed badge and stand out! 📣👥🏅
            </Typography>
          </Box>
        </>
      ),
    },
    {
      target: ".provide-references",
      placement: "bottom",
      content: (
        <Box>
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="h4" gutterBottom>
              🌟 Earn Your Peer-Reviewed Badge! 🌟
            </Typography>
            <Typography variant="body1" paragraph>
              Get endorsed by 2 or more colleagues and friends to receive the prestigious
              peer-reviewed badge. See the difference in your result page badge section! 🎖️👥✨
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      target: ".peer-reviewed-badge",
      placement: isMedium ? "bottom" : "left",
      content: (
        <Box>
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h6">
              {" "}
              see the difference of badge into result before endorse and after endorse{" "}
            </Typography>
          </Box>
          <Box>
            <Box>
              <img
                style={{ maxWidth: "350px" }}
                alt="self-attested"
                src={"/assets/images/before-after-badge.png"}
                loading="lazy"
              />
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      target: ".share-link-box",
      placement: "bottom",
      content: (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h" gutterBottom>
            🌟 Share Your Expertise Easily! 🌟
          </Typography>
          <Typography variant="body1" paragraph>
            Copy or share your unique link via WhatsApp, Facebook, and other social media platforms
            to invite endorsements from colleagues and friends effortlessly! 📋🚀
          </Typography>
        </Box>
      ),
    },
  ];

  const [domains, setDomains] = useState([
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
    "jspinfotech.com",
  ]);

  // get status according to reference status
  function getReferralsStatus(status) {
    switch (status) {
      case "success":
        return (
          <Chip size="small" sx={{ background: "#B3DCB6", color: "#64A166" }} label="Endorsed" />
        );
      case "notsignup":
        return (
          <Chip
            size="small"
            sx={{ background: "#CFD0D3", color: "#696B70" }}
            label="Not logged in"
          />
        );

      case "pending":
        return (
          <Chip size="small" sx={{ background: "#FFEAB6", color: "#DBAC3D" }} label="Pending" />
        );

      case "deleted":
        return (
          <Chip size="small" sx={{ background: "#F2CFD1", color: "#C94A51" }} label="Deleted" />
        );

      default:
        return (
          <Chip
            size="small"
            sx={{ background: "#CFD0D3", color: "#696B70" }}
            label="Not logged in"
          />
        );
        break;
    }
  }

  // get email with user status for referrals
  function getUserStatusForMobile(status, email, index) {
    switch (status) {
      case "success":
        return <Chip size="small" sx={{ background: "#B3DCB6", color: "#64A166" }} label={email} />;
      case "notsignup":
        return <Chip size="small" sx={{ background: "#CFD0D3", color: "#696B70" }} label={email} />;

      case "pending":
        return <Chip size="small" sx={{ background: "#FFEAB6", color: "#DBAC3D" }} label={email} />;

      case "deleted":
        return <Chip size="small" sx={{ background: "#F2CFD1", color: "#C94A51" }} label={email} />;

      default:
        return <Chip size="small" sx={{ background: "#CFD0D3", color: "#696B70" }} label={email} />;
        break;
    }
  }

  //  email comparison function
  function compareEmails(userEmail, emailArray) {
    const threshold = 2; // Set a threshold for similarity

    for (let i = 0; i < emailArray.length; i++) {
      const email = emailArray[i];
      const distanceValue = levenshtein(userEmail, email).steps;
      if (distanceValue <= threshold) {
        return true; // User email is similar to at least one email in the array
      }
    }
    return false; // User email is not similar to any email in the array
  }

  // email validation
  const handleChangeEmail = async (index, event) => {
    var { name, value } = event.target;
    const list = [...ReferralsField];
    list[index][name] = value.toLocaleLowerCase();
    var idx1 = list[index].email.indexOf("@");
    // var idx2 = list[index].email.index;

    if (idx1 > -1) {
      var splitStr = value.split("@");
      var sub = splitStr[1].split(".");

      if (domains.indexOf(splitStr[1]) < 0) {
        list[index].emailValid = false;
        setEmailError("Uh ho! That's an invalid email. Please enter a valid email id.");
      } else {
        if (validator.isEmail(value) && ReferralsField[index].email != userEmail) {
          list[index].emailValid = true;
        } else {
          list[index].emailValid = false;
          setEmailError("Uh ho! That's an invalid email. Please enter a valid email id.");
        }
      }
    } else {
      list[index].emailValid = false;
    }
    if (ReferralsField[index].email === userEmail) {
      setEmailError("Come on! Please enter a friend's email as a referral, not your own!");
    }
    var emails = referralsList?.map(function (item) {
      return item.email;
    });
    const entredEmails = ReferralsField?.slice(0, -1)?.map(function (item) {
      return item.email;
    });
    entredEmails.push(userEmail);
    // console.log("withuserEmail", withuserEmail);
    let similarEmail = compareEmails(ReferralsField[index].email, entredEmails);

    // console.log(withuserEmail, "entredEmails");

    // console.log(similarEmail, "similar");
    if (similarEmail) {
      list[index].emailValid = false;
      setEmailError("similar email entred. Please check ");
    }
    const founddup = entredEmails?.find((ele) => ele === value);
    if (founddup == value) {
      list[index].emailValid = false;
      setEmailError("email already entred. Please check ");
    }
    // check email are already referred or not
    const found = emails.find((ele) => ele === value);
    if (found == value) {
      list[index].emailValid = false;
      setEmailError("email already referred. Please check referral list");
    }
    if (ReferralsField[index].email?.length <= 0) {
      list[index].emailValid = true;
      setEmailError("Uh ho! That's an invalid email. Please enter a valid email id.");
    }
    setReferralsField(list);
    // console.log("ReferralsField Email", list[index].emailValid);
  };

  // add more referrals function
  const addReferralsField = () => {
    if (ReferralsField[ReferralsField?.length - 1].name.length !== 0) {
      if (
        ReferralsField[ReferralsField?.length - 1].email.length !== 0 &&
        ReferralsField[ReferralsField?.length - 1].emailValid
      ) {
        setReferralsField([...ReferralsField, { name: "", email: "" }]);
      } else {
        toast.error("Enter a valid Email");
      }
    } else {
      toast.error("Enter a Name");
    }
  };

  // remove referrals function
  const removeReferralsField = (index) => {
    const rows = [...ReferralsField];
    // console.log("rows");
    if (rows?.length > 1) {
      rows.splice(index, 1);
      setReferralsField(rows);
    }
  };

  // referral id generation
  const userRefId = useSelector((state) => state.userReducer.userRefId || "");
  const baseURL =
    process.env.REACT_APP_ENV === "dev"
      ? "https://master.d3hqz1cwjn0bmv.amplifyapp.com"
      : "https://techworth.trueselfy.com";
  const uniqueLink = `${baseURL}/signin?ref=${userRefId}&link=/endorse-expertise`;
  const WhatsAppUniqueLink = `${baseURL}/signin?ref=${userRefId}&link-type=whatsapp&link=/endorse-expertise`;
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(uniqueLink);
    toast.success("Copied");
  };

  // handlers for sharing links
  const handleShareWhatsApp = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(`https://wa.me/?text=${encodedLink}`);
  };

  const handleShareFacebook = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`);
  };

  const handleShareLinkedIn = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}`);
  };

  // speed dial icons and actions
  // const actions = [
  //   { icon: <FileCopyOutlinedIcon />, name: "Copy", onClick: handleCopyToClipboard },
  //   { icon: <WhatsAppIcon />, name: "WhatsApp", onClick: handleShareWhatsApp },
  //   { icon: <FacebookIcon />, name: "Facebook", onClick: handleShareFacebook },
  //   { icon: <LinkedInIcon />, name: "LinkedIn", onClick: handleShareLinkedIn },
  // ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Header />
          <Joyride
            steps={JoyrideSteps}
            run={run}
            showSkipButton
            continuous
            disableCloseOnEsc
            disableOverlayClose
            disableScrolling
          />
          <Grid container sx={{ mt: 8 }}>
            <Grid item xs={12} lg={5} xl={6}>
              <Box
                sx={{
                  maxWidth: "829px",
                  height: "auto",
                  overflow: "hidden",
                  display: "block",
                  margin: "0px auto",
                  width: "100%",
                }}
              >
                <img
                  src="../../assets/images/refer-img.svg"
                  style={{
                    width: "100%",
                  }}
                  alt="refer-img"
                  loading="lazy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={7} xl={6}>
              <Box sx={{ p: 3, textAlign: { xs: "center", md: "center", lg: "left" } }}>
                <Box>
                  <Box className={"provide-references"}>
                    <Typography
                      sx={{
                        fontSize: "40px",
                        fontWeight: 600,
                        textAlign: { xs: "center", md: "center", lg: "left" },
                        mb: 2,
                      }}
                      color="primary"
                    >
                      Provide References !
                    </Typography>
                    <Box>
                      <Typography
                        variant="h5"
                        component={"h5"}
                        textAlign={"justify"}
                        sx={{
                          lineHeight: `${isMobile ? "30px" : "inherit"}`,
                          wordSpacing: isMobile ? "-1px" : "1px",
                        }}
                      >
                        Let your colleagues endorse your expertise, thereby increasing your
                        authenticity, earn a
                        <span style={{ color: "#26801E", fontWeight: "bold" }}>
                          {" "}
                          “Peer reviewed”{" "}
                        </span>
                        badge and increase chances to earn more based on value adds you bring to the
                        table.
                        {/* {/ <TooltipComponent message={domains} /> /} */}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={"peer-reviewed-badge"}
                  >
                    <img
                      src={`../../../assets/images/peerreviewed.svg`}
                      width={isMobile ? "110px" : "138px"}
                      height={"100%"}
                      alt="self-attested"
                      loading="lazy"
                      // className="self-attested-logo"
                    />
                  </Box>
                </Box>
                <Box className={"share-link-box"}>
                  <Typography sx={{ fontSize: "18px", fontWeight: 600, mt: "25px" }}>
                    Share link
                  </Typography>
                  <Typography sx={{ color: "#888", mb: "12px" }}>
                    Share this link so they can add the necessary details to endorse your expertise.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: { xs: "wrap", md: "nowrap", lg: "nowrap", xl: "nowrap" },
                      gap: { xs: 2, xl: 2 },
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 5,
                    }}
                  >
                    <TextField
                      fullWidth
                      type="text"
                      value={uniqueLink}
                      // size="small"
                      InputProps={{
                        readOnly: "true",
                        sx: { color: "#8D8D99" },
                      }}
                    />

                    <Box sx={{ display: "flex", gap: { xs: 2, xl: 2 }, alignItems: "center" }}>
                      <Button variant="outlined" color="primary" onClick={handleCopyToClipboard}>
                        {" "}
                        Copy
                      </Button>
                      {/* <ShareOnSocial
                      textToShare={Referral_Link_Description_write_up}
                      link={uniqueLink}
                      linkTitle="Endorse My Expertise"
                      linkMetaDesc="Endorse My Expertise"
                      linkFavicon={
                        "https://master.d3hqz1cwjn0bmv.amplifyapp.com/assets/images/peerreviewed.svg"
                      }
                      noReferer
                    >
                      <Button variant="contained" color="primary">
                        Share1{" "}
                      </Button>
                    </ShareOnSocial> */}
                      <RWebShare
                        data={{
                          text: Referral_Link_Description_write_up,
                          url: uniqueLink,
                          title:
                            "Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise",
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <Button variant="contained" color="primary">
                          Share
                        </Button>
                      </RWebShare>{" "}
                    </Box>
                  </Box>
                </Box>
                {shareViaWhatsApp && (
                  <Box>
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      or Share via whatsapp &nbsp;
                      <WhatsappShareButton
                        url={WhatsAppUniqueLink}
                        quote={
                          "Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise"
                        }
                        hashtag={"#trueselfy"}
                        description={"aiueo"}
                      >
                        <WhatsAppIcon size="large" />
                      </WhatsappShareButton>
                    </Typography>
                  </Box>
                )}
                {/* <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              textAlign: { xs: "center", md: "left" },
              mb: 2,
            }}
          >
            Add people
          </Typography> */}
                {/* <Grid
            container
            spacing={{ xs: 0.5, sm: 2 }}
            columns={13}
            sx={{
              display: "flex",
              alignItems: { xs: "baseline", sm: "self-end" },
              justifyContent: "center",
              mb: "33px",
            }}
          >
            {ReferralsField.map((item, index) => {
              return (
                <>
                  <Grid
                    container
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ pt: 2 }}
                    key={index}
                    spacing={2}
                  >
                    <Grid
                      item
                      key={index}
                      alignItems="center"
                      justifyContent="center"
                      display={"flex"}
                      xs={1}
                    >
                      <Typography fontWeight={"bold"}>{index + 1}</Typography>
                    </Grid>

                    <Grid item xs={10}>
                      <Grid container spacing={{ xs: 1, sm: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            onChange={(event) => handleChange(index, event)}
                            label="Name"
                            placeholder="Enter Name"
                            multiline
                            name="name"
                            key={index}
                            value={item.name}
                            size="small"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            control={control}
                            rules={{
                              validate: () => {
                                return (
                                  ReferralsField[index].emailValid &&
                                  (ReferralsField[index].email.toLocaleLowerCase() != userEmail
                                    ? (ReferralsField[index].emailValid = true)
                                    : (ReferralsField[index].emailValid = false))
                                );
                              },
                            }}
                            name="email"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                onChange={(event) => {
                                  handleChangeEmail(index, event);
                                }}
                                variant="outlined"
                                label="Email"
                                value={item.email}
                                placeholder="Enter Email"
                                required={ReferralsField[index].name?.length > 0 ? true : false}
                                type="email"
                                error={ReferralsField[index]?.emailValid === false}
                                helperText={
                                  ReferralsField[index]?.emailValid === false ? emailError : ""
                                }
                                InputProps={{
                                  endAdornment: (
                                    <React.Fragment>
                                      {loading ? (
                                        <CircularProgress key={index} color="inherit" size={20} />
                                      ) : (
                                        ""
                                      )}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      alignItems="center"
                      justifyContent="center"
                      display={"flex"}
                      xs={1}
                    >
                      <IconButton
                        aria-label="delete"
                        size="large"
                        disableTouchRipple
                        variant="outlined"
                        color="error"
                        onClick={() => removeReferralsField(index)}
                      >
                        <DeleteOutlineRoundedIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              );
            })}
            <Grid item xs={12} sm={1}>
              <IconButton
                color="primary"
                variant="outlined"
                sx={{
                  border: "2px solid #26801E",
                  fontSize: "28px",
                  display: "block",
                  width: "45px",
                  height: "45px",
                  mx: "auto",
                  mb: 1,
                }}
                onClick={() => addReferralsField()}
              >
                <Add />
              </IconButton>
            </Grid>
          </Grid> */}

                <>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      mb: { xs: 1, md: 2 },
                    }}
                  >
                    Previously Provided References
                  </Typography>

                  {isMobile && (
                    <Box
                      sx={{
                        pb: 2.5,
                      }}
                    >
                      <Chip
                        size="small"
                        sx={{ background: "#B3DCB6", color: "#64A166", marginRight: "5px" }}
                        label={`Endorsed (${
                          referralsList.filter((item) => item?.status === "success")?.length
                        })`}
                      />
                      <Chip
                        size="small"
                        sx={{ background: "#CFD0D3", color: "#696B70", marginRight: "5px" }}
                        label={`Not logged in (${
                          referralsList.filter((item) => item?.status === "notsignup")?.length
                        })`}
                      />
                      <Chip
                        size="small"
                        sx={{ background: "#FFEAB6", color: "#DBAC3D" }}
                        label={`Pending (${
                          referralsList.filter((item) => item?.status === "pending")?.length
                        })`}
                      />
                      <Chip
                        size="small"
                        sx={{ background: "#F2CFD1", color: "#C94A51" }}
                        label={`Deleted (${
                          referralsList.filter((item) => item?.status === "deleted")?.length
                        })`}
                      />
                    </Box>
                  )}

                  {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
                      <CircularProgress />
                    </Box>
                  ) : referralsList?.length === 0 || undefined ? (
                    <Box
                      sx={{
                        maxWidth: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        // backgroundColor: "#DBDBDB",
                        // border: "2px solid grey",
                        // borderRadius: "15px",
                      }}
                      p={{ xs: 5, md: 10, lg: 10 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={`../../../../assets/images/empty-list.svg`}
                          alt="empty list"
                          style={{
                            maxWidth: `${isMobile ? "50%" : "100%"}`,
                          }}
                          loading="lazy"
                        />
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            color: "#232323",
                            textAlign: "center",
                          }}
                          variant="h2"
                        >
                          No references
                        </Typography>
                        <Typography pt={4} textAlign={"center"}>
                          Why don’t you calculate your TechWorth through Trueselfy?
                        </Typography>
                      </Box>
                      <Box pt={3}>
                        <Button
                          // display={
                          //   location.pathname === "/skills-calculation" ? false : true
                          // }
                          sx={{ px: `${isMobile ? "10px" : "25px"}` }}
                          variant="contained"
                          onClick={() => navigate("/skills-calculation")}
                        >
                          {CalculateButtonValue}
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <ReferencesTable referralsList={referralsList} />
                  )}
                </>

                {/* </Box> */}
              </Box>
            </Grid>
          </Grid>
          <Footer />
        </Box>
      )}
      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMsg={snackbarMsg}
          snackbarSeverity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
};

export default ReferralPage;
