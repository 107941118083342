import { SET_LOADING } from "../action/loadingRequest";

export default function LoadingReducer(state = false, action) {
  switch (action.type) {
    case SET_LOADING: {
      state = action.payload;
      return { state };
    }
    default:
      return { ...state };
  }
}
