import { SET_USER_LOGGED_IN } from "../action/user";

export default function userReducer(state = "", action) {
  switch (action.type) {
    case SET_USER_LOGGED_IN: {
      const data = action.payload;
      state = data;
      return { ...state };
    }
    default:
      return state;
  }
}
