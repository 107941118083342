import {SET_SUPPORT_EMAIL} from "../action/support"

export default function supportReducer(state = {},action){
    switch(action.type) {
        case SET_SUPPORT_EMAIL:{            
            state.emails = action.payload            
            return {...state}
        }
        default:
            return state
    }
}