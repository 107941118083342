import {
  SET_USER_FINAL_RESULT,
  SET_RESULT_ID,
  SET_PRIMARY_SKILL_RELEVANCE,
} from "../action/result";
import { SET_USER_PREVIOUS_RESULT } from "../action/setPreviousResult";

const initialState = {}; // Define initial state

export default function ResultReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRIMARY_SKILL_RELEVANCE: {
      const { payload } = action;
      return {
        ...state,
        skill_relevance: payload,
      };
    }
    case SET_USER_FINAL_RESULT: {
      const { payload } = action;
      return {
        ...state,
        result: payload,
      };
    }
    case SET_USER_PREVIOUS_RESULT: {
      const { payload } = action;
      return {
        ...state,
        previousResult: payload,
      };
    }
    case SET_RESULT_ID: {
      const { payload } = action;
      return {
        ...state,
        resultId: payload,
      };
    }
    default:
      return state;
  }
}
