// get status according to reference status
import { Chip } from "@mui/material";
export function getReferralsStatus(status) {
  switch (status) {
    case "success":
      return (
        <Chip size="small" sx={{ background: "#CCDBCD", color: "#688A69" }} label="Endorsed" />
      );
    case "notsignup":
      return (
        <Chip size="small" sx={{ background: "#F2CFD1", color: "#C94A51" }} label="Not logged in" />
      );

    case "pending":
      return <Chip size="small" sx={{ background: "#FFE199", color: "#A67B15" }} label="Pending" />;

    case "deleted":
      return <Chip size="small" sx={{ background: "#F2CFD1", color: "#C94A51" }} label="Deleted" />;

    default:
      return (
        <Chip size="small" sx={{ background: "#a29df2", color: "#625afa" }} label="Not logged in" />
      );
      break;
  }
}
