import { combineReducers } from "redux";
import ResultReducer from "../reducer/result";
import OldFormReducer from "../reducer/oldForm";
import StepperReducer from "../reducer/stepperReducer";
import QuestionJsonReducer from "../reducer/qustionObjectReducer";
import userReducer from "./user";
import ReferralsReducer from "./referrals";
import LoadingReducer from "./loading";
import SupportReducer from "./support";
import IncognitoMode from "./IncognitoMode";

export default combineReducers({
  ResultReducer,
  LoadingReducer,
  OldFormReducer,
  QuestionJsonReducer,
  StepperReducer,
  userReducer,
  ReferralsReducer,
  SupportReducer,
  IncognitoMode,
});
