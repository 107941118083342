import { SET_QUESTION_OBJECT, SET_SUB_SKILLS } from "../action/questionsObject";

const initialState = {
  questionObject: {},
  subSkills: [],
};
export default function QuestionJsonReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SUB_SKILLS:
      return {
        ...state,
        subSkills: action.payload,
      };
    default:
      return state;
  }
}
