import React from "react";
import { Checkbox } from "@mui/material";
import QuestionOptionIcon from "./QuestionOptionIcon";
import { motion } from "framer-motion";
import { useMediaQuery, Skeleton } from "@mui/material";

function getImagepath(question) {
  switch (question?.stepId) {
    case "Information":
      return "emojis";
    case "step_3":
      return "fly";
    case "Passion":
      return "vehical";
    case "Knowledge":
      return "animals";
    case "Business":
      return "vehical";
    case "Soft Skills":
      return "fly";
    case "Learning":
      return "stars";
    case "Team":
      return "animals";
    case "EQ":
      return "fly";
    default:
      return "emojis";
  }
}

function CheckBoxAccordingQuestionType({
  field,
  value,
  question,
  index,
  setSelectedans,
  setValue,
  getValues,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      {isMobile ? (
        <motion.div
          initial={{ x: 0, y: 0, scale: 1, rotate: 0 }}
          whileTap={{ x: 0, y: 0, scale: 2.5, rotate: 0 }}
          exit={{ x: 0, y: 0, scale: 1, rotate: 0 }}
        >
          {/* <motion.div
        initial={{ scale: 1 }}
        whileTap={{ scale: 1.2 }}
        exit={{ scale: 1 }}
      > */}
          {/* <motion.div
        initial={{ rotate: 0 }}
        whileTap={{ rotate: 360 }}
        exit={{ rotate: 0 }}
      > */}

          <Checkbox
            checked={field.value === value.name}
            onChange={(e) => {
              field.onChange(e.target.checked ? value.name : "");
              if (question?.skillName) {
                const currentPrimarySkills = getValues("primarySkill") || [];
                const existingSkillIndex = currentPrimarySkills.findIndex(
                  (skill) => skill.name === question.skillName
                );

                if (existingSkillIndex !== -1) {
                  currentPrimarySkills[existingSkillIndex].selectedAns =
                    value.name;
                } else {
                  currentPrimarySkills.push({
                    name: question.skillName,
                    selectedAns: value.name,
                  });
                }

                setValue("primarySkill", currentPrimarySkills);
              }
              if (e.target.checked) {
                setSelectedans(value.name);
              } else {
                setSelectedans("");
              }
            }}
            disableRipple
            icon={
              <QuestionOptionIcon
                path={`../../../../assets/images/evaluation-question-icons/${getImagepath(
                  question
                )}/${index + 1}.svg`}
                alt={`${value?.shortName}`}
              />
            }
            checkedIcon={
              <QuestionOptionIcon
                path={`../../../../assets/images/evaluation-question-icons/${getImagepath(
                  question
                )}/${index + 1}-checked.svg`}
                alt={`${value?.shortName}`}
              />
            }
          />
        </motion.div>
      ) : (
        <motion.div
          // initial={{ x: 0, y: 0, scale: 1, rotate: 0 }}
          // whileTap={{ x: 0, y: 0, scale: 2.5, rotate: 0 }}
          // exit={{ x: 0, y: 0, scale: 1, rotate: 0 }}
          whileHover={{ scale: 1.5 }}
        >
          {/* <motion.div
        initial={{ scale: 1 }}
        whileTap={{ scale: 1.2 }}
        exit={{ scale: 1 }}
      > */}
          {/* <motion.div
        initial={{ rotate: 0 }}
        whileTap={{ rotate: 360 }}
        exit={{ rotate: 0 }}
      > */}

          <Checkbox
            checked={field.value === value.name}
            onChange={(e) => {
              field.onChange(e.target.checked ? value.name : "");
              if (question?.skillName) {
                const currentPrimarySkills = getValues("primarySkill") || [];
                const existingSkillIndex = currentPrimarySkills.findIndex(
                  (skill) => skill.name === question.skillName
                );

                if (existingSkillIndex !== -1) {
                  currentPrimarySkills[existingSkillIndex].selectedAns =
                    value.name;
                } else {
                  currentPrimarySkills.push({
                    name: question.skillName,
                    selectedAns: value.name,
                  });
                }

                setValue("primarySkill", currentPrimarySkills);
              }
              if (e.target.checked) {
                setSelectedans(value.name);
              } else {
                setSelectedans("");
              }
            }}
            disableRipple
            icon={
              <QuestionOptionIcon
                path={`../../../../assets/images/evaluation-question-icons/${getImagepath(
                  question
                )}/${index + 1}.svg`}
                alt={`${value?.shortName}`}
              />
            }
            checkedIcon={
              <QuestionOptionIcon
                path={`../../../../assets/images/evaluation-question-icons/${getImagepath(
                  question
                )}/${index + 1}-checked.svg`}
                alt={`${value?.shortName}`}
              />
            }
          />
        </motion.div>
      )}
    </>
  );
}

export default CheckBoxAccordingQuestionType;
