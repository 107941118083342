import React from "react";

function DataDecryption(
  jsonString,
  staticRandomEncodeDecodeString,
  secretEncodeDecodeString
) {
  const decodedString = atob(jsonString);
  const getOldStringOne = decodedString.replace(
    new RegExp(secretEncodeDecodeString, "g"),
    ""
  );
  const str = staticRandomEncodeDecodeString;
  const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(escapedString, "g");
  const getOldStringTwo = getOldStringOne.replace(regex, '"');
  const json = JSON.parse(getOldStringTwo);
  return json;
}

export default DataDecryption;
