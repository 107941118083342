//import { ErrorMessage } from "@hookform/error-message";
import { Grid, TextField, FormHelperText } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// import InfoOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../../../styles.css";
import { useEffect, useState } from "react";
import TooltipComonent from "../../common/Tooltip";

function TextQuestionComponent({ question }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  question.rules.pattern = new RegExp(question.rules.pattern);
  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography
        // variant="h6"
        // component="h1"
        display="flex"
        alignItems="center"
        justifyContent={
          question.question_info != null && question.question_info.replace(/\s/g, "")?.length != 0
            ? `space-between`
            : `flex-start`
        }
        // sx={{fontSize:'20px', fontWeight: "medium", lineHeight: "24px", }}
        className="stepper-question"
      >
        {question.label}
        {question.rules.required && (
          <>
            &nbsp;<p style={{ color: "red" }}>*</p>
          </>
        )}

        {question.question_info != null &&
        question.question_info.replace(/\s/g, "")?.length != 0 ? (
          <TooltipComonent message={question?.question_info} />
        ) : (
          <></>
        )}
      </Typography>

      <Controller
        control={control}
        rules={question.rules}
        name={question.questionName}
        render={({ field }) => (
          <TextField
            autoFocus={question?.autoFocus}
            {...field}
            onChange={(e, newValue) => {
              setValue(question.questionName, e.target.value);
              question?.isSuggested &&
                setValue(`suggestedObject[${question.questionName}]`, e.target.value);
              // console.log(
              //   "getCerti",
              //   getValues(`suggestedObject[${question.questionName}]`)
              // );
            }}
            style={{ width: "100%" }}
            // size="small"
            label={question.placeholder}
            variant="outlined"
          />
        )}
      />

      {errors[question.questionName]?.type === "required" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "pattern" && (
        // <p className={"errorMsg"}>{question?.patternErrorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question?.patternErrorMsg}
        </FormHelperText>
      )}
      {/* <ErrorMessage message={"First name is required"} name={`${questionName}`} as={"text"} /> */}
    </Grid>
  );
}
export default TextQuestionComponent;
