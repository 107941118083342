import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Chip,
  DialogActions,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { getCoverLeter } from "../components/stepper_form/stepper.helper";
import { CloseRounded } from "@mui/icons-material";
import { Link } from "@mui/material";
import { KeyboardArrowRight, Circle, ArrowRight } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReactSpeedometer from "react-d3-speedometer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import confetti from "canvas-confetti";
import { getEmail } from "../utils/storage";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { submitSalaryFeedback } from "../components/stepper_form/stepper.helper";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { getReferralsStatus } from "../components/common/getReferralsStatus";
import { setUserPreviousResult } from "../redux/action/setPreviousResult";
import { getPreviousResult } from "../components/stepper_form/stepper.helper";
import Loading from "../components/UI/Loading";
import CoverLetter from "../components/common/CoverLetter";
import "./UpdatedResult.css";
import { isInteger } from "lodash";

function TechResultV3() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDesktop = useMediaQuery("(max-width:1400px)");
  const resultData = useSelector((state) => state?.ResultReducer?.previousResult);
  const userForm = useSelector(
    (state) => state?.OldFormReducer || state?.ResultReducer?.previousResult?.userData
  );
  const [getTrueEdge, setTrueEdge] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [openEndorse, setOpenEndorse] = useState(false);
  const [openTrueEdge, setOpenTrueEdge] = useState(false);
  const [loadingCoverletter, setLoadingCoverletter] = useState(false);
  const [openSalaryFeedback, setOpenSalaryFeedback] = useState(false);
  const [salaryFeedback, setSalaryFeedback] = useState("");
  const [salaryComment, setSalaryComment] = useState("");
  const [indeedUrl, setIndeedUrl] = useState(resultData?.results?.countryCode?.indeedUrl);
  const [contractSalarySelect, setContractSalarySelect] = useState(false);
  const [jobLocation, setJobLocation] = useState();
  const [openPopup, setOpenPopUp] = useState(false);
  const [monthlySalary, setMonthlySalary] = useState();

  const handleSalaryFeedback = (event) => {
    setSalaryFeedback(event.target.value);
    window.scrollTo({
      top: isMobile ? 3200 : 1600,
      behavior: "smooth",
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenPopup = () => setOpenPopUp(true);
  const handleClosePopup = () => {
    setTrueEdge(true);
    setOpenPopUp(false);
  };
  const handleCloseTrueEdge = () => setTrueEdge(false);
  const handleCloseEndorse = () => setOpenEndorse(false);
  const handleOpenTrueEdge = () => {
    setOpenTrueEdge(true);
    setText("");
  };
  const User_Cover_letter = useFeatureFlag("User_Cover_letter");
  const [text, setText] = useState("");
  const techWorth = isInteger(resultData?.techWorth)
    ? resultData?.techWorth?.toFixed(2)
    : resultData?.techWorth;

  // smart salary
  const [smartSalary, setSmartSalary] = useState(resultData?.convertedMarketSalary);

  const referralslist = useSelector((state) => state?.ReferralsReducer?.referrals) || [];

  const [selectedValue, setSelectedValue] = useState("full-time-salary");

  const allSkills = resultData?.primarySkill?.concat(resultData?.secondarySkill)?.map((item) => {
    return item.name;
  });

  const handleClick = () => {
    toast.success("Copied");
  };

  useEffect(() => {
    if (openSalaryFeedback) {
      window.scrollTo({
        top: isMobile ? 2600 : 1200,
        behavior: "smooth",
      });
    }
  }, [openSalaryFeedback]);
  function convertCurrency() {
    const { marketSalary, countryCode } = resultData?.results;

    const currency = new Intl.NumberFormat(countryCode?.languageCode, {
      style: "currency",
      currency: countryCode?.code,
      maximumSignificantDigits: 4,
    });
    setMonthlySalary(currency?.format(marketSalary));
  }
  useEffect(() => {
    extractCityState();
    convertCurrency();
    if (resultData?.coverLetter === "") {
      setTimeout(handleOpenPopup, 5000);
    }
  }, []);
  function filterSalaryPartitions(value, index, array) {
    const isNotFirstOrLast = index > 0 && index < array.length - 1;
    const label = isNotFirstOrLast ? `${value}` : index === 0 ? `<${value}` : `>${value}`;

    return label;
  }

  function extractCityState() {
    let locationString = userForm?.location;
    // Find the index of the last comma
    let lastIndex = locationString?.lastIndexOf(",");
    // Extract the substring up to the last comma
    let result = locationString?.substring(0, lastIndex);
    setJobLocation(result);
  }

  useEffect(() => {
    var scalar = 10;
    // Dollar shape
    const dollar = confetti.shapeFromText({
      text: "💲",
      scalar,
    });
    // Rupee shape
    const rupee = confetti.shapeFromText({
      text: resultData?.currencySymbol,
      scalar,
    });

    // Euro shape
    const euro = confetti.shapeFromText({
      text: "€",
      scalar,
    });

    const defaults = {
      // spread: 270,
      // gravity: 1,
      // decay: 1,
      // // startVelocity: 10,
      // scalar,
      // origin: { y: -0.1 },
      // startVelocity: -30,
      scalar: 8,
      spread: 180,
      origin: { y: -0.1 },
      startVelocity: -70,
      colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
    };

    // Add confetti with custom shapes
    // confetti({
    //   ...defaults,
    //   shapes: [dollar],
    //   particleCount: 150,
    //   flat: true,
    // });
    // confetti({
    //   ...defaults,
    //   shapes: [rupee],
    //   particleCount: 150,
    //   flat: true,
    // });
    // confetti({
    //   scalar: 10,
    //   spread: 360,
    //   ticks: 70,
    //   gravity: 2,
    //   decay: 1,
    //   startVelocity: 10,
    //   shapes: [rupee],
    //   colors: ["#008000"],
    //   particleCount: 25,
    //   flat: true,
    // });

    // confetti({
    //   ...defaults,

    //   shapes: [rupee],
    //   colors: ["#ff0000", "#ff6347", "#dc143c"], // Red colors for the rupee
    // });

    // confetti({
    //   ...defaults,
    //   shapes: [euro],
    //   colors: ["#0000cd", "#1e90ff", "#4682b4"], // Blue colors for the euro
    // });
  }, []);
  // const SalaryData = resultData?.salaryPartition?.map((item, index) => {
  //   return {
  //     ...item,
  //     id: index,
  //     iconSrc: `/assets/images/feedback/${index}}.png`,
  //     checkedSrc: `/assets/images/feedback/${index}}.png`,
  //     label: item,
  //   };
  // });

  const salaryData = [
    {
      id: 0,
      iconSrc: "/assets/images/1-3lac.png",
      checkedSrc: "/assets/images/1-3lac.png",
      label: "1-3 Lacs",
      value: "-30%",
    },
    {
      id: 1,
      iconSrc: "/assets/images/3-5lac.png",
      checkedSrc: "/assets/images/3-5lac.png",
      label: "3-5 Lacs",
      value: "-20%",
    },
    {
      id: 2,
      iconSrc: "/assets/images/5-10lac.png",
      checkedSrc: "/assets/images/5-10lac.png",
      label: "5-10 Lacs",
      value: "-10%",
    },
    {
      id: 3,
      iconSrc: "/assets/images/10-15lac.png",
      checkedSrc: "/assets/images/10-15lac.png",
      label: "10-15 Lacs",
      value: "-5%",
    },
    {
      id: 4,
      iconSrc: "/assets/images/15-20lac.png",
      checkedSrc: "/assets/images/15-20lac.png",
      label: "15-20 Lacs",
      value: "10%",
    },
    {
      id: 5,
      iconSrc: "/assets/images/20-25lac.png",
      checkedSrc: "/assets/images/20-25lac.png",
      label: "20-25 Lacs",
      value: "20%",
    },
    {
      id: 6,
      iconSrc: "/assets/images/25-30lac.png",
      checkedSrc: "/assets/images/25-30lac.png",
      label: "25-30 Lacs",
      value: "30%",
    },
  ];

  // cover letter code
  function removeEmptyKeysAndObjects(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          obj[key] === null ||
          obj[key] === undefined ||
          obj[key] === "" ||
          obj[key] === "No" ||
          key === "userlocation" ||
          key === "resultTime" ||
          key === "lastStepper" ||
          key === "deviceinfo" ||
          key === "location" ||
          key === "typeOfCompany"
        ) {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          if (Array.isArray(obj[key])) {
            if (obj[key].length === 0) {
              delete obj[key];
            } else {
              obj[key].forEach((item) => {
                if (item && typeof item === "object" && "type" in item) {
                  delete item.type;
                }
              });
            }
          } else {
            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
    }
    return obj;
  }

  const handleChangeSalaryType = (event) => {
    const { value } = event.target;
    setContractSalarySelect(value === "contract-salary");
    setSelectedValue(value);
  };

  const handleChange = (event) => {
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    // Get the current value of the text area.
    const currentValue = event.target.value;

    // Get the text that the user is typing.
    const newText = event.target.value.substring(selectionStart, selectionEnd);

    // Replace the selected text with the new text.
    const newValue = currentValue.replace(
      currentValue.substring(selectionStart, selectionEnd),
      newText
    );
    setText(newValue);
  };

  function DataComponent() {
    return (
      <div>
        <textarea
          className="typing-textarea"
          value={resultData?.coverLetter}
          onChange={handleChange}
        />
      </div>
    );
  }

  const getBiolater = async () => {
    // console.log("removeObjects", removeEmptyKeysAndObjects(userForm));
    handleOpenTrueEdge();
    submitSalaryFeedback(resultData?._id, salaryFeedback, salaryComment);
    const techValue = resultData?.convertedTechValue;
    let userEmail = getEmail();
    let resultId = resultData?._id;
    const userFormData = { ...userForm };
    let userData = removeEmptyKeysAndObjects(userFormData);
    let userObject = {
      userData,
      techValue,
      userEmail,
      resultId,
    };

    getCoverLeter(userObject, setText, setLoadingCoverletter, dispatch);
  };

  const RadioIcon = ({ src }) => {
    return (
      <Box
        sx={{
          width: { xs: "70px", md: "80px", lg: "100px" },
          height: { xs: "70px", md: "80px", lg: "100px" },
          borderRadius: "50%",
          border: "2px solid transparent",
          overflow: "hidden",
          "& >img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={src} alt="radio-img" />
      </Box>
    );
  };

  const CheckedRadioIcon = ({ src }) => {
    return (
      <Box
        sx={{
          width: { xs: "80px", md: "80px", lg: "115px" },
          height: { xs: "80px", md: "80px", lg: "115px" },
          borderRadius: "50%",
          border: "2px solid #000",
          boxShadow: "0px 0px 10px 5px rgba(205, 202, 192,0.7)",
          overflow: "hidden",
          "& >img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={src} alt="checked-radio-img" />
      </Box>
    );
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Header />
          <Box sx={{ px: { xs: 3, sm: 3, md: 6, lg: 10 }, py: 3, mt: { xs: 8, lg: 14 } }}>
            <Grid container spacing={{ xs: 4, md: 5, lg: 10 }}>
              <Grid item xs={12}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", lg: "space-between" },
                    alignItems: "center",
                    flexWrap: "wrap",
                    mb: "25px",
                  }}
                  container
                >
                  <Grid
                    item
                    xs={12}
                    lg={5}
                    sx={{ display: "flex", justifyContent: { xs: "center", lg: "left" } }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "25px", lg: "40px" },
                        fontWeight: 600,
                        textAlign: { xs: "center", lg: "left" },
                        lineHeight: "40px",
                      }}
                      color="primary"
                    >
                      {`${resultData?.name?.trim()}'s TechWorth`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={7}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { xs: "center", lg: "center" },
                      alignItems: "center",
                      flexDirection: { xs: "column", md: "column", lg: "row" },
                    }}
                  >
                    {" "}
                    <Typography
                      sx={{
                        fontSize: { xs: "20px", lg: "24px" },
                        textAlign: { xs: "center", lg: "center" },
                      }}
                    >
                      {" "}
                      <span style={{ color: "#2BB344" }}> Result Id: </span>
                      {resultData?._id}
                    </Typography>{" "}
                    <Typography
                      sx={{
                        fontSize: { xs: "20px", lg: "24px" },
                        textAlign: { xs: "center", lg: "left" },

                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <LocationOnIcon
                        sx={{ fontSize: "inherit", color: "#2BB344", marginRight: "-2px" }}
                      />
                      <span style={{ color: "#2BB344" }}> Work location:&nbsp;</span>
                      {resultData?.userData?.location}
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 4, md: 5, lg: 5, xl: 10 }}>
                  <Grid item xs={12} md={6} lg={5}>
                    <Box
                      sx={{
                        border: "1.5px solid #0D9B21",
                        background: "#E6FFEB",
                        borderRadius: "20px",
                        py: 1,
                        px: { xs: "15px", sm: 3 },
                        mb: 3,
                      }}
                    >
                      <Box>
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            lineHeight: "30px",
                            fontSize: { sm: "22px", xl: "22px" },
                            fontWeight: 500,
                            textAlign: { xs: "center", sm: "left" },
                          }}
                        >
                          Smart salary ( TechValue based )
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: { xs: "wrap", sm: "nowrap" },
                          gap: 3,
                          alignItems: "center",
                          justifyContent: { xs: "center", sm: "space-between" },
                        }}
                      >
                        <Box component="div">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: { xs: "wrap", xl: "nowrap" },
                              gap: 1,
                              width: "100%",
                              alignItems: "flex-start",
                              justifyContent: { xs: "center", sm: "flex-start" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Radio
                                value="full-time-salary"
                                name="radio-buttons"
                                checked={selectedValue === "full-time-salary"}
                                onChange={handleChangeSalaryType}
                                inputProps={{ "aria-label": "A" }}
                                sx={{ p: 0, pr: 1 }}
                              />
                              <Box component="div">
                                <Typography component="p" variant="p" sx={{ color: "#636363" }}>
                                  Full-time salary
                                </Typography>
                                <Typography variant="caption">(per annum)</Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Radio
                                value="contract-salary"
                                name="radio-buttons"
                                checked={selectedValue === "contract-salary"}
                                onChange={handleChangeSalaryType}
                                inputProps={{ "aria-label": "B" }}
                                sx={{ p: 0, pr: 1 }}
                              />
                              <Box component="div">
                                <Typography component="p" variant="p" sx={{ color: "#636363" }}>
                                  Contract salary{" "}
                                </Typography>
                                <Typography variant="caption">(Rate/hour)</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box>
                            {selectedValue === "contract-salary" ? (
                              <Typography
                                variant="h3"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: { md: "22px", xl: "26px" },
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {resultData?.results?.contractSalary}
                                <span style={{ fontSize: "12px" }}>(Rate/hour)</span>
                              </Typography>
                            ) : (
                              <Typography
                                variant="h3"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: { md: "22px", xl: "26px", textAlign: "center" },
                                }}
                              >
                                {resultData?.results?.convertedMarketSalary}
                              </Typography>
                            )}
                          </Box>
                          <Box sx={{ width: 60, height: 60 }}>
                            <img src="/assets/images/wallet.png" alt="wallet" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                      <Grid item xs={12} sm={6} md={12} lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "1.5px solid #FFC218",
                            borderRadius: "20px",
                            background: "#FFF3D1",
                            height: "100%",
                            boxSizing: "inherit",
                            py: 2,
                            px: 3,
                          }}
                        >
                          <Box component="div">
                            <Typography variant="h6" sx={{ fontSize: "22px", mb: 1 }}>
                              TechValue
                            </Typography>
                            <Typography variant="h2" sx={{ fontWeight: 600 }}>
                              {resultData?.results?.convertedTechValue}
                            </Typography>
                          </Box>
                          <Box sx={{ width: 60, height: 60 }}>
                            <img src="/assets/images/yellow-star.png" alt="yellow-star" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={6}>
                        <Box
                          sx={{
                            border: "1.5px solid #0BA2FF",
                            borderRadius: "20px",
                            background: "#E7F6FF",
                            height: "100%",
                            boxSizing: "inherit",
                            py: 2,
                            px: 3,
                          }}
                        >
                          <Typography sx={{ textAlign: "center" }}>
                            Find jobs based on <br /> your profile
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Link
                              sx={{
                                maxWidth: "130px",
                                width: "100%",
                                display: "inline-flex",
                                gap: "4px",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: 500,
                                color: "#2BB344",
                                border: "2px solid #2BB344",
                                p: "8px 12px",
                                borderRadius: "24px",
                                textDecoration: "none",
                              }}
                              target="_blank"
                              href={`https://www.linkedin.com/jobs/search/?currentJobId=3788215852&f_TPR=r604800&keywords=${resultData?.primarySkill[0]["name"]}&location=${jobLocation}&origin=JOB_SEARCH_PAGE_JOB_FILTER`}
                            >
                              <img
                                src="../../../assets/images/linkedin-square.png"
                                alt="linkedin"
                                width="20px"
                              />
                              Linkedin
                              <KeyboardArrowRight />{" "}
                            </Link>{" "}
                            <Link
                              sx={{
                                maxWidth: "130px",
                                width: "100%",
                                display: "inline-flex",
                                gap: "4px",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: 500,
                                color: "#2BB344",
                                border: "2px solid #2BB344",
                                p: "8px 12px",
                                borderRadius: "24px",
                                textDecoration: "none",
                              }}
                              target="_blank"
                              href={`${indeedUrl}/jobs?q=${resultData?.primarySkill[0]["name"]}-${monthlySalary}&l=${jobLocation}`}
                            >
                              <img
                                src="../../../assets/images/indeed.png"
                                alt="linkedin"
                                width="20px"
                              />
                              Indeed
                              <KeyboardArrowRight />{" "}
                            </Link>{" "}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        border: "1.5px solid #D3D3D3",
                        boxShadow: "0px 0px 4px 0px #CDCAC073",
                        p: "17px 20px 35px",
                        borderRadius: "20px",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src="/assets/images/green-diamond.png"
                          style={{ width: "60px", height: "60px", marginRight: "14px" }}
                          alt="techworth"
                        />
                        <Typography color="primary" variant="h3" sx={{ fontWeight: 600 }}>
                          TechWorth
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" },
                          gap: 3,
                          justifyContent: { xs: "center", sm: "center" },
                          alignItems: "center",
                          mb: "10px",
                        }}
                      >
                        <Box className={"speedometer-div"}>
                          <ReactSpeedometer
                            segments={6}
                            customSegmentLabels={[
                              {
                                text: "<1",
                                position: "INSIDE",
                                color: "#fff",
                              },
                              {
                                text: "1-2",
                                position: "INSIDE",
                                color: "#fff",
                              },
                              {
                                text: "2-3",
                                position: "INSIDE",
                                color: "#fff",
                              },
                              {
                                text: "3-4",
                                position: "INSIDE",
                                color: "#fff",
                              },
                              {
                                text: "4-5",
                                position: "INSIDE",
                                color: "#fff",
                              },
                              {
                                text: "5+",
                                position: "INSIDE",
                                color: "#fff",
                              },
                            ]}
                            segmentColors={[
                              "rgba(215, 38, 38, 1)",
                              "rgba(255, 169, 2, 1)",
                              "rgba(43, 179, 68, 1)",
                              "rgba(13, 155, 33, 1)",
                              "rgba(255, 135, 26, 1)",
                              "rgba(215, 38, 38, 1)",
                            ]}
                            value={techWorth}
                            needleHeightRatio={0.8}
                            valueTextFontSize={"20px"}
                            valueTextFontWeight={700}
                            textColor={"#000"}
                            maxValue={6}
                            minValue={0}
                            needleTransition="easeElastic"
                            customSegmentStops={[0, 1, 2, 3, 4, 5, 6]}
                            height={isDesktop ? 160 : 210}
                            width={isDesktop ? 250 : 350}
                            ringWidth={40}
                            needleColor="#000"
                            labelFontSize="12px"
                            // needleHeightRatio={0.8}
                          />
                        </Box>

                        {/* <Box
                      sx={{
                        maxWidth: "107px",
                        minWidth: "90px",
                        width: "100%",
                        height: "auto",
                        overflow: "hidden",
                        "& >img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src="/assets/images/SELF-ATTESTED.svg" alt="self-attested" />
                    </Box> */}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          height: "auto",
                          overflow: "hidden",
                          "& >img": {
                            width: "100%",
                            height: "100%",
                          },
                          pb: "10px",
                        }}
                      >
                        <img src="/assets/images/scale.svg" alt="scale" />
                      </Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "center", fontSize: "18px", lineHeight: "26px" }}
                      >
                        This is an indicative figure based on your given answers.
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          textAlign: "center",
                          fontSize: "18px",
                          lineHeight: "26px",
                          mb: "10px",
                        }}
                      >
                        Ideally, your TechWorth must be at least 2 to 4, if you are to be considered
                        productive and useful in your organization.
                      </Typography>
                      <Box
                        sx={{
                          maxWidth: "556px",
                          width: "100%",
                          height: "auto",
                          overflow: "hidden",
                          margin: "0px auto",
                          "& >img": {
                            width: "100%",
                            height: "100%",
                          },
                        }}
                      >
                        <img src="/assets/images/equation-image.png" alt="techworth-equation" />
                      </Box>

                      <Box sx={{ display: "flex", justifyContent: "center", mt: "35px" }}>
                        <Button
                          type="button"
                          // size="large"
                          variant="outlined"
                          color="primary"
                          target="_blank"
                          href="./#techworth"
                          // sx={{ fontWeight: 400, borderRadius: "55px" }}
                          endIcon={<KeyboardArrowRight sx={{ fontSize: "24px !important" }} />}
                        >
                          TechWorth
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={7}>
                    <Box
                      sx={{
                        border: "1.5px solid #D3D3D3",
                        boxShadow: "0px 0px 4px 0px #CDCAC073",
                        p: { xs: "20px", lg: "28px 32px" },
                        borderRadius: "20px",
                        display: "flex",
                        flexWrap: { xs: "wrap", lg: "no-wrap" },
                        gap: 2,
                        justifyContent: {
                          xs: "flex-start",
                          sm: "flex-start",
                          md: "flex-start",
                          lg: "space-evenly",
                        },
                        mb: 6,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <Box>
                            <img
                              src="/assets/images/person-star.png"
                              style={{ width: "60px", height: "60px" }}
                              alt="techworth"
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h3"
                              sx={{
                                fontWeight: 600,
                                fontSize: { xs: "19px", lg: "1.3rem" },
                                pl: { xs: 1, lg: 2 },
                              }}
                            >
                              {isNaN(resultData?.userData?.experience)
                                ? `${resultData?.userData?.experience} with`
                                : `${resultData?.userData?.experience} Years experience with`}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ pl: 3 }}>
                          <List>
                            {resultData?.primarySkill?.map((item, index) => (
                              <ListItem disablePadding sx={{ fontSize: "22px" }} key={index}>
                                <ListItemIcon sx={{ minWidth: "unset", mr: "12px" }}>
                                  <Circle sx={{ fontSize: "10px", color: "#000" }} />
                                </ListItemIcon>
                                <ListItemText sx={{ lineHeight: "35px" }}>
                                  <Typography
                                    sx={{ fontSize: { xs: "16px", md: "20px", lg: "24px" } }}
                                  >
                                    {item?.name}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Box>
                      {resultData?.AllTools?.length !== 0 && (
                        <Box sx={{ display: "flex", gap: 3 }}>
                          <Box>
                            <img
                              src="/assets/images/tools.png"
                              style={{ width: "60px", height: "60px" }}
                              alt="techworth"
                            />
                          </Box>
                          <Box>
                            <Typography variant="h3" sx={{ fontWeight: 600, pt: 1.5 }}>
                              All tools
                            </Typography>
                            <List>
                              {resultData?.AllTools?.map((item, index) => (
                                <ListItem disablePadding>
                                  <ListItemIcon sx={{ minWidth: "unset", mr: "12px" }} key={index}>
                                    <Circle sx={{ fontSize: "10px", color: "#000" }} />
                                  </ListItemIcon>
                                  <ListItemText sx={{ lineHeight: "35px", fontSize: "22px" }}>
                                    <Typography
                                      sx={{ fontSize: { xs: "16px", md: "20px", lg: "24px" } }}
                                    >
                                      {item?.name}
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    {resultData?.coverLetter !== "" ? (
                      <Box
                        sx={{
                          border: "1.5px solid #0D9B21",
                          background: "#E6FFEB",
                          borderRadius: "20px",
                          p: 5,
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: { xs: 1, md: 3, xl: 6 },
                            flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" },
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              maxWidth: "108px",
                              width: "100%",
                              height: "auto",
                              overflow: "hidden",
                              "& >img": { width: "100%", height: "100%" },
                            }}
                          >
                            <img src="/assets/images/trueedge-mail.png" alt="trueedge" />
                          </Box>
                          <Box>
                            <Typography
                              variant="h2"
                              sx={{
                                fontWeight: 600,
                                mb: "18px",
                                textAlign: { xs: "center", md: "left" },
                              }}
                              color="primary"
                            >
                              TrueEdge
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                color: "#636363",
                              }}
                            >
                              {/* You are Unique. */}
                              Your Previous Generated Unique Cover Letter
                            </Typography>
                          </Box>
                          <Button
                            type="button"
                            size="large"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setTrueEdge(true);
                            }}
                            sx={{ fontWeight: 400, borderRadius: "55px" }}
                            endIcon={<KeyboardArrowRight sx={{ fontSize: "24px !important" }} />}
                          >
                            View
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          border: "1.5px solid #0D9B21",
                          background: "#E6FFEB",
                          borderRadius: "20px",
                          p: 5,
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: { xs: 1, md: 3, xl: 6 },
                            flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" },
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              maxWidth: "108px",
                              width: "100%",
                              height: "auto",
                              overflow: "hidden",
                              "& >img": { width: "100%", height: "100%" },
                            }}
                          >
                            <img src="/assets/images/trueedge-mail.png" alt="trueedge" />
                          </Box>
                          <Box>
                            <Typography
                              variant="h2"
                              sx={{
                                fontWeight: 600,
                                mb: "18px",
                                textAlign: { xs: "center", md: "left" },
                              }}
                              color="primary"
                            >
                              TrueEdge
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                color: "#636363",
                              }}
                            >
                              You are Unique.
                              <br /> Your Unique Cover Letter to stand out from the crowd (chatgpt
                              enabled)
                            </Typography>
                          </Box>
                          <Button
                            type="button"
                            // size="large"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setOpenSalaryFeedback(true);
                              // getBiolater();
                            }}
                            // sx={{ fontWeight: 400, borderRadius: "55px" }}
                            endIcon={<KeyboardArrowRight sx={{ fontSize: "24px !important" }} />}
                          >
                            Proceed
                          </Button>
                        </Box>
                      </Box>
                    )}
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                      <Grid item xs={12} sm={6} md={12} lg={6}>
                        <Box
                          sx={{
                            border: "1.5px solid #D3D3D3",
                            borderRadius: "20px",
                            height: "100%",
                            boxSizing: "inherit",
                            p: { xs: 2, lg: 4 },
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box
                            sx={{
                              maxWidth: { xs: "80px", sm: "108px", md: "80px", lg: "108px" },
                              width: "100%",
                              height: "auto",
                              overflow: "hidden",
                              "& >img": { width: "100%", height: "100%" },
                              display: "block",
                              m: "0px auto",
                              mb: { xs: "30px", lg: "52px" },
                            }}
                          >
                            <img src="/assets/images/no-of-references.png" alt="no-of-references" />
                          </Box>
                          <Typography
                            variant="h1"
                            sx={{ textAlign: "center", fontWeight: 600, mb: "20px" }}
                          >
                            {referralslist?.length}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{ textAlign: "center", color: "#636363", mb: "20px" }}
                          >
                            No. of references provided
                          </Typography>
                          <Box sx={{ textAlign: "center" }}>
                            <Link
                              component="button"
                              sx={{
                                display: "inline-flex",
                                gap: 1,
                                alignItems: "center",
                                fontSize: "24px",
                                fontWeight: 500,
                                color: "#0D9B21",
                                textDecorationColor: "inherit",
                              }}
                              onClick={() => {
                                setOpen(true);
                              }}
                            >
                              View list
                              <Box
                                component="span"
                                sx={{
                                  width: "22px",
                                  height: "22px",
                                  display: "grid",
                                  placeContent: "center",
                                  background: "#0D9B21",
                                  borderRadius: "12px",
                                  color: "#fff",
                                  fontSize: "10px",
                                }}
                              >
                                {" "}
                                <KeyboardArrowRight />{" "}
                              </Box>
                            </Link>{" "}
                          </Box>
                        </Box>
                      </Grid>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={800}
                      >
                        <DialogTitle
                          id="alert-dialog-title"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            // borderBottom: "1px solid #EBEBEB",
                          }}
                        >
                          <Typography
                            variant="h4"
                            textAlign={"center"}
                            sx={{ fontWeight: 500, lineHeight: 1.8 }}
                          >
                            Your References list
                          </Typography>
                          <IconButton onClick={handleClose}>
                            <CloseRounded />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <TableContainer component={Paper} elevation={0}>
                              <Table
                                sx={{
                                  size: { xs: "small", sm: "medium" },
                                  minWidth: 250,
                                  width: "100%",
                                  maxWidth: 690,
                                  border: "1px solid #D3D3D3",
                                  borderCollapse: "separate",
                                  overflow: "scroll",
                                  borderRadius: "15px !important",
                                  "& .MuiTableCell-head": {
                                    fontSize: { xs: "15px", sm: "18px" },
                                    fontWeight: 500,
                                  },
                                  "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root":
                                    {
                                      borderBottom: "0px",
                                    },
                                  "& .MuiTableCell-root h5": {
                                    fontSize: { xs: "14px", sm: "18px" },
                                    fontWeight: 400,
                                  },
                                  "& .MuiTableCell-root ": {
                                    borderRight: "1px solid #D3D3D3",
                                    padding: { xs: "8px !important", sm: "13px !important" },
                                    textAlign: "center",
                                  },
                                  "& .MuiTableCell-root:last-child ": {
                                    borderRight: "0px",
                                    textAlign: "center",
                                  },
                                }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No.</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {referralslist?.map((row, index) => (
                                    <TableRow
                                      key={index}
                                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                      <TableCell>
                                        <Typography>{index + 1}</Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          varaint="h5"
                                          component="h5"
                                          sx={{ color: "#777777", textAlign: "left" }}
                                        >
                                          {row?.name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          varaint="h5"
                                          component="h5"
                                          sx={{ color: "#777777", textAlign: "left" }}
                                        >
                                          {row.email}
                                        </Typography>
                                      </TableCell>
                                      <TableCell> {getReferralsStatus(row?.status)}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </DialogContentText>
                        </DialogContent>
                        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
                      </Dialog>

                      <Grid item xs={12} sm={6} md={12} lg={6}>
                        <Box
                          sx={{
                            border: "1.5px solid #D3D3D3",
                            borderRadius: "20px",
                            height: "100%",
                            boxSizing: "inherit",
                            p: { xs: 2, lg: 4 },
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          {resultData?.evaluationTested ? (
                            <>
                              <Box
                                sx={{
                                  maxWidth: { xs: "80px", sm: "108px", md: "80px", lg: "108px" },
                                  width: "100%",
                                  height: "auto",
                                  overflow: "hidden",
                                  "& >img": { width: "100%", height: "100%" },
                                  display: "block",
                                  m: "0px auto",
                                  mb: { xs: "30px", lg: "52px" },
                                }}
                              >
                                <img src="/assets/images/peerreviewed.png" alt="peerreviewed" />
                              </Box>
                              <Typography
                                variant="h1"
                                sx={{ textAlign: "center", fontWeight: 600, mb: "20px" }}
                              >
                                {
                                  referralslist?.filter((item) => item?.status === "success")
                                    ?.length
                                }
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{ textAlign: "center", color: "#636363", mb: "20px" }}
                              >
                                No. of references endorsed
                              </Typography>
                              <Box sx={{ textAlign: "center" }}>
                                <Link
                                  component="button"
                                  sx={{
                                    display: "inline-flex",
                                    gap: 1,
                                    alignItems: "center",
                                    fontSize: "24px",
                                    fontWeight: 500,
                                    color: "#0D9B21",
                                    textDecorationColor: "inherit",
                                  }}
                                  onClick={() => {
                                    setOpenEndorse(true);
                                    console.info("I'm a button.");
                                  }}
                                >
                                  View list
                                  <Box
                                    component="span"
                                    sx={{
                                      width: "22px",
                                      height: "22px",
                                      display: "grid",
                                      placeContent: "center",
                                      background: "#0D9B21",
                                      borderRadius: "12px",
                                      color: "#fff",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {" "}
                                    <KeyboardArrowRight />{" "}
                                  </Box>
                                </Link>{" "}
                              </Box>
                              <Dialog
                                open={openEndorse}
                                onClose={handleCloseEndorse}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth={800}
                              >
                                <DialogTitle
                                  id="alert-dialog-title"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    // borderBottom: "1px solid #EBEBEB",
                                  }}
                                >
                                  <Typography
                                    variant="h4"
                                    textAlign={"center"}
                                    sx={{ fontWeight: 500, lineHeight: 1.8 }}
                                  >
                                    Your endorsed References list
                                  </Typography>
                                  <IconButton onClick={handleCloseEndorse}>
                                    <CloseRounded />
                                  </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    <TableContainer component={Paper} elevation={0}>
                                      <Table
                                        sx={{
                                          size: { xs: "small", sm: "medium" },
                                          minWidth: 250,
                                          width: "100%",
                                          maxWidth: 690,
                                          border: "1px solid #D3D3D3",
                                          borderCollapse: "separate",
                                          overflow: "scroll",
                                          borderRadius: "15px !important",
                                          "& .MuiTableCell-head": {
                                            fontSize: { xs: "15px", sm: "18px" },
                                            fontWeight: 500,
                                          },
                                          "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root":
                                            {
                                              borderBottom: "0px",
                                            },
                                          "& .MuiTableCell-root h5": {
                                            fontSize: { xs: "14px", sm: "18px" },
                                            fontWeight: 400,
                                          },
                                          "& .MuiTableCell-root ": {
                                            borderRight: "1px solid #D3D3D3",
                                            padding: {
                                              xs: "8px !important",
                                              sm: "13px !important",
                                            },
                                            textAlign: "center",
                                          },
                                          "& .MuiTableCell-root:last-child ": {
                                            borderRight: "0px",
                                            textAlign: "center",
                                          },
                                        }}
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>No.</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Status</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {referralslist
                                            ?.filter((item) => item?.status === "success")
                                            ?.map((row, index) => (
                                              <TableRow
                                                key={index}
                                                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                              >
                                                <TableCell>
                                                  <Typography>{index + 1}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                  <Typography
                                                    varaint="h5"
                                                    component="h5"
                                                    sx={{ color: "#777777", textAlign: "left  " }}
                                                  >
                                                    {row?.name}
                                                  </Typography>
                                                </TableCell>
                                                <TableCell>
                                                  <Typography
                                                    varaint="h5"
                                                    component="h5"
                                                    sx={{ color: "#777777", textAlign: "left  " }}
                                                  >
                                                    {row.email}
                                                  </Typography>
                                                </TableCell>
                                                <TableCell>
                                                  {" "}
                                                  {getReferralsStatus(row?.status)}
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </DialogContentText>
                                </DialogContent>
                                {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
                              </Dialog>
                            </>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  maxWidth: { xs: "80px", sm: "108px", md: "80px", lg: "108px" },
                                  width: "100%",
                                  height: "auto",
                                  overflow: "hidden",
                                  "& >img": { width: "100%", height: "100%" },
                                  display: "block",
                                  m: "0px auto",
                                }}
                              >
                                <img src="/assets/images/SELF-ATTESTED.svg" alt="peerreviewed" />
                              </Box>
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {openSalaryFeedback ? (
                <Grid item xs={12} id="salary-feedback">
                  <Typography
                    variant="h5"
                    display="block"
                    sx={{ color: "#636363", textAlign: "center", mb: 3 }}
                  >
                    Parameters changes so, Comeback after few days and check again!
                  </Typography>
                  <Typography
                    variant="h2"
                    display="block"
                    color="primary"
                    sx={{ fontSize: "40px", textAlign: "center", fontWeight: 600, mb: "42px" }}
                  >
                    Provide your feedback and Get your TrueEdge{" "}
                  </Typography>
                  <Box
                    sx={{
                      border: "1.5px solid #D3D3D3",
                      borderRadius: "20px",
                      p: { xs: 2, md: 3, lg: 6 },
                    }}
                  >
                    <Typography
                      variant="h2"
                      display="block"
                      sx={{ fontSize: "40px", textAlign: "center", fontWeight: 600, mb: "42px" }}
                    >
                      My Current salary is
                    </Typography>
                    <Typography
                      variant="h5"
                      display="block"
                      sx={{ color: "#636363", textAlign: "center", mb: 3 }}
                    >
                      Your input is valuable in helping us better understand your <br /> current
                      earnings and tailor our service accordingly.{" "}
                    </Typography>
                    <RadioGroup
                      sx={{
                        display: { xs: "flex", lg: "flex" },
                        flexDirection: { xs: "row", lg: "row" },
                        alignItems: "flex-start",
                        justifyContent: { xs: "center", lg: "center" },
                        flexWrap: { xs: "wrap", lg: "wrap" },
                        "& .MuiFormControlLabel-root": {
                          // flex: 1,
                        },
                        "& .MuiFormControlLabel-label": {
                          fontSize: "inherit",
                          fontWeight: "inherit",
                          textAlign: "center",
                        },
                      }}
                      value={salaryFeedback}
                      onChange={handleSalaryFeedback}
                    >
                      {contractSalarySelect
                        ? resultData?.results?.contractSalaryPartition?.map(
                            (value, index, array) => {
                              return (
                                <FormControlLabel
                                  key={index} // Assuming 'value' is a unique identifier
                                  value={filterSalaryPartitions(value, index, array)}
                                  label={filterSalaryPartitions(value, index, array)}
                                  sx={{
                                    "& .MuiFormControlLabel-label": {
                                      fontSize: { xs: "16px", md: "20px", lg: "22px" },
                                      fontWeight: 600,
                                    },
                                  }}
                                  labelPlacement="bottom"
                                  control={
                                    <Radio
                                      icon={<RadioIcon src={salaryData[index]?.iconSrc} />} // Use optional chaining to avoid potential errors
                                      checkedIcon={
                                        <CheckedRadioIcon src={salaryData[index]?.checkedSrc} />
                                      } // Use optional chaining to avoid potential errors
                                    />
                                  }
                                />
                              );
                            }
                          )
                        : resultData?.results?.salaryPartition?.map((value, index, array) => {
                            return (
                              <FormControlLabel
                                key={index} // Assuming 'value' is a unique identifier
                                value={filterSalaryPartitions(value, index, array)}
                                label={filterSalaryPartitions(value, index, array)}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: { xs: "16px", md: "20px", lg: "22px" },
                                    fontWeight: 600,
                                  },
                                }}
                                labelPlacement="bottom"
                                control={
                                  <Radio
                                    icon={<RadioIcon src={salaryData[index]?.iconSrc} />} // Use optional chaining to avoid potential errors
                                    checkedIcon={
                                      <CheckedRadioIcon src={salaryData[index]?.checkedSrc} />
                                    } // Use optional chaining to avoid potential errors
                                  />
                                }
                              />
                            );
                          })}
                    </RadioGroup>

                    <TextField
                      type="text"
                      multiline
                      minRows={3}
                      placeholder="Add a comment..."
                      fullWidth
                      value={salaryComment}
                      onChange={(e) => setSalaryComment(e.target.value)}
                      sx={{ mb: "40px", mt: "50px" }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ display: "block", m: "0px auto" }}
                      onClick={() => {
                        getBiolater();
                      }}
                    >
                      {" "}
                      Get your TrueEdge
                    </Button>
                  </Box>
                </Grid>
              ) : (
                ""
              )}
              {/* <Dialog open={getTrueEdge} onClose={handleCloseTrueEdge}>
                <DialogTitle
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography sx={{ textAlign: "center", fontWeight: 700, p: { xs: 1, md: 1 } }}>
                      {resultData?.name?.trim()}'s True Edge
                    </Typography>
                  </Box>
                  <Box
                    onClick={handleCloseTrueEdge}
                    sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                  >
                    <HighlightOffIcon />
                  </Box>
                </DialogTitle>

                <DialogContent p={{ xs: 0.5, md: 1 }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CopyToClipboard text={resultData?.coverLetter}>
                      <ContentCopyOutlinedIcon
                        onClick={handleClick}
                        sx={{
                          cursor: "pointer",
                          pb: 1,
                          position: "absolute",
                          zIndex: 1,
                          top: { xs: "80px", lg: "84px" },
                          right: { xs: "30px", lg: "54px" },
                        }}
                      />
                    </CopyToClipboard>
                  </Box>
                  <DataComponent />
                </DialogContent>
              </Dialog> */}
              {resultData?.coverLetter !== "" ? (
                <CoverLetter setOpen={setTrueEdge} open={getTrueEdge} data={resultData} />
              ) : (
                <CoverLetter
                  setOpen={setOpenTrueEdge}
                  open={openTrueEdge}
                  data={{ userForm, coverLetter: text }}
                />
              )}
            </Grid>
            <Dialog open={openPopup} onClose={handleClosePopup}>
              <DialogContent sx={{ px: { xs: 3, lg: 10 }, pt: 10, pb: 2 }}>
                <IconButton
                  onClick={() => setOpenPopUp(false)}
                  size="small"
                  sx={{
                    pt: 2,
                    pr: 2,
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    zIndex: 99,
                    background: "#FFF",
                    "&:hover": {
                      background: "#FFF",
                    },
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
                <Box
                  sx={{
                    position: "relative",
                    height: "230px",
                    overflow: "hidden",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={"../../assets/images/true-edge-open.gif"}
                    alt="true edge image"
                    style={{
                      height: "100%",
                      width: "100%",
                      maxWidth: "230px",
                      maxHeight: "230px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Typography variant="h2" sx={{ fontWeight: 700, textAlign: "center", pb: 2 }}>
                  Get Your TrueEdge
                </Typography>
                <Typography
                  display="block"
                  sx={{ fontSize: "18px", textAlign: "center", fontWeight: 600, pb: 1 }}
                >
                  Provide your feedback and Get your TrueEdge{" "}
                </Typography>
                <Typography sx={{ textAlign: "center" }}>
                  You are Unique. Your Unique Cover Letter to stand out from the crowd (chatGPT
                  enabled)
                </Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", paddingTop: "8px" }}>
                <Button
                  type="button"
                  // size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenSalaryFeedback(true);
                    setTrueEdge(true);
                    setOpenPopUp(false);
                  }}
                  sx={{ mb: 3 }}
                  endIcon={<KeyboardArrowRight sx={{ fontSize: "24px !important" }} />}
                >
                  Proceed
                </Button>
              </DialogActions>
            </Dialog>
            <Box sx={{ textAlign: "center", mt: "64px" }}>
              {" "}
              <Button
                className="btn"
                variant="outlined"
                endIcon={<HomeOutlinedIcon />}
                // onClick={() => {
                //   navigate("/");
                // }}
                href={"/"}
              >
                Back to Home
              </Button>
            </Box>
          </Box>
          <Footer />
        </React.Fragment>
      )}
    </>
  );
}

export default TechResultV3;
