//import { ErrorMessage } from "@hookform/error-message";
import { Box, Checkbox, FormControlLabel, Grid, TextField, useMediaQuery } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// import InfoOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useState } from "react";
import TooltipComonent from "../common/Tooltip";
import CheckBoxAccordingSteps from "../common/CheckBoxAccordingSteps";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import { motion } from "framer-motion";

function EvaluationRadioQuestion({ question, username }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  const formdata = getValues();
  // console.log("formdata", formdata);
  const [selectedans, setSelectedans] = useState(formdata[question?.questionName] || "");
  const theme = useTheme();
  // const classes = useStyles();

  // question.rules.pattern = new RegExp(question.rules.pattern);
  return (
    <motion.div
      initial={{ opacity: 0, y: 0 }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, y: -200 }}
      transition={{ duration: 0.5 }}
    >
      <Grid item xs={12} my={1} sx={{ pl: 1 }} textAlign={"center"}>
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={{ xs: "left", md: "center", lg: "center" }}
        >
          <Typography
            variant="h6"
            display="flex"
            alignItems="center"
            flexDirection={"row"}
            sx={{ fontWeight: "500", lineHeight: "24px", py: 1 }}
          >
            {question?.label?.replace(/(their|his\/her)/g, `${username}'s` || "their") ||
              question?.label}
            {question.rules.required && (
              <>
                &nbsp;<p style={{ color: "red" }}>*</p>
              </>
            )}

            {question.question_info != null &&
            question.question_info.replace(/\s/g, "")?.length != 0 ? (
              <TooltipComonent message={question?.question_info} />
            ) : (
              <></>
            )}
          </Typography>
        </Box>
        <Typography
          py={0.5}
          mb={{ xs: 0.5, md: 0, lg: 0 }}
          variant="h6"
          textAlign={{ xs: "left", md: "center", lg: "center" }}
          sx={{
            color: `${theme.palette.primary.evalute}`,
            height: "40px",
          }}
        >
          {selectedans}

          {errors[question.questionName]?.type === "required" && (
            <Typography
              variant="h6"
              sx={{
                color: "#F2250E",
                borderRadius: "10px",
                p: "0px 10px",
              }}
              display="inline-block"
            >
              {question.errorMsg}
            </Typography>
          )}
        </Typography>
        <Box
          pt={{ xs: 2, md: 2, lg: 0.5 }}
          display={{ xs: "inline", md: "inline", lg: "flex", xl: "flex" }}
        >
          <Controller
            name={question.questionName}
            control={control}
            rules={question.rules}
            render={({ field }) => (
              <>
                {question.options.map((value, i) => {
                  return (
                    <FormControlLabel
                      sx={{
                        textAlign: "center",
                        margin: { xs: 0, md: 0 },
                        marginRight: { xs: 1 },
                        flex: 1,
                        "& .MuiFormControlLabel-label": {
                          fontSize: `${isMobile ? "12px" : "16px"}`,
                        },
                      }}
                      key={i}
                      labelPlacement="bottom"
                      control={
                        <CheckBoxAccordingSteps
                          field={field}
                          value={value}
                          question={question}
                          index={i}
                          setSelectedans={setSelectedans}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      }
                      label={`${value.shortName}`}
                    />
                  );
                })}
              </>
            )}
          />
        </Box>

        {/* {errors[question.questionName]?.type === "pattern" && (
        <p className={"errorMsg"}>{question?.patternErrorMsg}</p>
      )} */}
      </Grid>
    </motion.div>
  );
}
export default EvaluationRadioQuestion;
