import { SET_STEPPER_OBJECT } from "../action/stepperAction";

export default function StepperReducer(state = "", action) {
  switch (action.type) {
    case SET_STEPPER_OBJECT: {
      const data = action.payload;
      state = data;
      return { state };
    }

    default:
      return state;
  }
}
